import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";

import { login, logout, user, setPermission } from "../features/auth/slice";
import api from "../app/api";
import useAlert from "./useAlert";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { keys } from "../config/keys";
// Initialize Firebase with your config
const firebaseConfig = {
  apiKey: keys.API_KEY,
  authDomain: keys.AUTH_DOMAIN,
  projectId: keys.PROJECT_ID,
  storageBucket: keys.STORAGE_BUCKET,
  messagingSenderId: keys.MESSAGING_SENDER_ID,
  appId: keys.APP_ID,
  serverKey: keys.serverKey,
};

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const { showAlert } = useAlert();
  const state = useSelector((state) => state.auth);
  let app;
  const retriveToken = (email) => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {

        }
      });
      const messaging = getMessaging(app);
      try {
        getToken(messaging, { vapidKey: keys.vapidKey })
          .then(async (currentToken) => {
            console.log(currentToken)
            if (currentToken) {
              await fetch(`${keys.BASE_LOCAL_URL}/auth/updateToken`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: email,
                  fcm_token: currentToken,
                }),
              })
                .then((res) => res.json())
                .then((response) => {

                });
            } else {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  console.log("Notification permission granted.");
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if ("Notification" in window) {
      app = initializeApp(firebaseConfig);
    }
  }, []);

  const isAdmin = state?.user?.role == "admin" || state?.user?.role == "companyAdmin" || state?.user?.role == "fellowAdmin";
  const isCompanyAdmin = state?.user?.role == "companyAdmin";
  const isFellowAdmin = state?.user?.role == "fellowAdmin";
  const isCompany = state?.user?.role == "company";
  const isEmployee = state?.user?.role == "employee";
  const isManager = state?.user?.position == "Manager";
  const isGuest = state?.user?.position == "Guest";
  const isSupervisor = state?.user?.position == "Supervisor";
  // remove token
  // dispatch(logout())
  const location = useLocation()
  const onLogin = async (email, password) => {
    //('in login')
    setLoading(true);
    try {
      const res = await api.post("/auth/login", { email, password });
      if (res.status == 200) {
        retriveToken(email);
      }
      localStorage.setItem("_id", res?.data?.user?._id);
      localStorage.setItem("companyID", res?.data?.user?.companyID?._id);
      // localStorage.setItem("_klIDbSoIaBU", res?.data?.user?.subscriptionID?._id);
      dispatch(login(res.data));
      let redirectPath = new URLSearchParams(location.search).get("redirect") || "/dashboard";
      navigate(redirectPath);
      setLoading(false);
      showAlert("Login successful", "success");
      retriveToken();
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const onSignup = async (data, cb, recaptchaRef, addedBy) => {
    setLoading(true);
    //(data)
    try {
      const res = await api.post("/auth/signup", {...data,addedBy});
      setLoading(false);
      if (cb) {
        cb();
        showAlert(res.data.message, "success");
      } else {
        navigate("/confirmation");
      }
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
        recaptchaRef?.current.reset();
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const onLogout = async () => {
    try {
      setLoading(true);
      dispatch(logout());
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const onUser = async () => {
    setLoading(true);
    try {
      const response = await api.get("/auth/user");
      dispatch(user(response.data));
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const onUpdate = async (data) => {
    setLoading(true);
    try {
      const response = await api.put("/auth/update", data);
      dispatch(user(response.data));
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const onReset = async (data, hide) => {
    setLoading(true);
    try {
      const res = await api.put("/auth/reset", data);
      setLoading(false);
      showAlert("Password Reset Successful", "success");
      hide();
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const onForgot = async (data) => {
    setLoading(true);
    try {
      const res = await api.post("/auth/forgot", data);
      setLoading(false);
      showAlert(res?.data?.message, "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };

  const onPermission = (data) => dispatch(setPermission(data));

  return {
    user: state.user,
    token: state.token,
    permissions: state.permissions,
    isCompanyAdmin,
    isFellowAdmin,
    isAdmin,
    isCompany,
    isEmployee,
    isManager,
    isSupervisor,
    isGuest,
    loading,
    onLogin,
    onSignup,
    onLogout,
    onUser,
    onUpdate,
    onReset,
    onForgot,
    onPermission,
    retriveToken,
  };
};
