import React, { useState } from 'react'

import { useSearchParams } from 'react-router-dom'
import MessageHeader from './MessageHeader'
import useBroadcast from '../../hooks/useBroadcast'
import FormField from '../../components/FormField'

const Messages = ({ pageState }) => {
    const { broadcasts } = useBroadcast()
    const [query] = useSearchParams();
    const chatID = query.get('broadcast')
    return (
        <div className='col-md-8'>
            <div className="card" style={{ height: '100%' }}>
                {!chatID && <MessageHeader pageState={pageState} />}
                {broadcasts.map((item) => {
                    if (item._id == chatID) {
                        return <MessageHeader chat={item} pageState={pageState} />
                    }
                })}
                {/* List */}
                <div className='d-flex align-items-end' style={{
                    overflow: 'auto',
                    overflowX: 'hidden',
                    height: "70vh",
                    flexDirection: 'column-reverse'
                }}>

                    <div className='d-flex flex-column w-100 gap-2 ps-4 pe-4 pt-2 pb-2'>
                        {
                            broadcasts?.map((item, index) => {
                                if (item._id == chatID) {
                                    return (
                                        <>
                                            <FormField item={{
                                                label: 'Title',
                                                value: item.title,
                                            }} disabled={true} />
                                            <br />
                                            <label>Description</label>
                                            <textarea
                                                className="form-control"
                                                type='text'
                                                value={item.message}
                                                disabled={true}
                                                rows={5}
                                            />
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                {/* Input */}
            </div>
        </div>
    )
}

export default Messages