import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";


import useAuth from '../../hooks/useAuth';
import useNature from '../../hooks/useNature';

import FormField from "../../components/FormField";
import SubmitButton from "../../components/SubmitButton";

const CompanyModal = ({ show, handleClose, fetchCompaniesWithPagination}) => {

  const { natures, fetchNatures } = useNature();
  const { loading, onSignup } = useAuth();
  const { isCompanyAdmin } = useAuth()
  const [data, setData] = useState({
    email: '',
    name: '',
    firstname: '',
    lastname: '',
    nature: natures[0],
    position: '',
    adminName: '',
    adminEmail: '',
    employees: 0,
    website: '',
    telephone1: '',
    telephone2: '',
    branches: 0,
    address: '',
    status: true
  });

  // constants
  const fields = [
    {
      value: data.firstname,
      setValue: (value) => setData({ ...data, firstname: value }),
      label: 'First Name',
      required: true,
      type: 'text',
      className: 'col-md-6'
    },
    {
      value: data.lastname,
      setValue: (value) => setData({ ...data, lastname: value }),
      label: 'Last Name',
      required: true,
      type: 'text',
      className: 'col-md-6'
    },
    {
      value: data.email,
      setValue: (value) => setData({ ...data, email: value }),
      label: 'Email Address',
      required: true,
      type: 'email',
      className: 'col-md-12'
    },
    {
      value: data.password,
      setValue: (value) => setData({ ...data, password: value }),
      label: 'Password',
      required: true,
      type: 'password',
      className: 'col-md-12'
    },
    {
      value: data.telephone1,
      setValue: (value) => setData({ ...data, telephone1: value }),
      label: 'Phone Number',
      type: 'phone',
      required: true,
      className: 'col-md-12'
    },
    {
      value: data.name,
      setValue: (value) => setData({ ...data, name: value }),
      label: 'Company Name',
      required: true,
      type: 'text',
      className: 'col-md-6'
    },
    {
      value: data.nature?.name,
      setValue: (value) => setData({ ...data, nature: value }),
      label: 'Industry',
      placeholder: 'Select industry...',
      required: true,
      type: 'dropdown',
      options: natures,
      className: 'col-md-6',
    },
  ]

  // Functions
  const onSubmit = (e) => {
    e.preventDefault();
    onSignup(data, handleClose, null, isCompanyAdmin ? localStorage.getItem("_id") : null).then(()=>fetchCompaniesWithPagination());
    
  }

  useEffect(() => {
    fetchNatures({ hide: ['Default'] });
  }, [show])

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Create Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row g-3">
          {fields?.map((item, index) => (
            <FormField item={item} key={index} />
          ))}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          title={"Add Company"}
          loading={loading}
          onClick={onSubmit}
          color={"primary"}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CompanyModal;
