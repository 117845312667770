import { createSlice } from "@reduxjs/toolkit";

const companyAdminSlice = createSlice({
  name: "companyAdmins",
  initialState: {
    companyAdmins: []
  },
  reducers: {
    setCompanyAdmin: (state, action) => {
      state.companyAdmins = action.payload;
    },
    updateCompanyAdmin: (state, action) => {
      state.companyAdmins = state.companyAdmins.map(item => item._id == action.payload._id ? action.payload : item);
    },
    deleteCompanyAdmin: (state, action) => {
      state.companyAdmins = state.companyAdmins.filter(item => item._id != action.payload._id);
      state.companyAdmins = state.companyAdmins.map(item => item.name == 'Default' ? { ...item, checklists: action.payload.checklists + item.checklists } : item);
    },
    addCompanyAdmin: (state, action) => {
      state.companyAdmins = [...state.companyAdmins, { ...action.payload, checklists: 0 }];
    },
  },
});

export const { setCompanyAdmin, updateCompanyAdmin, deleteCompanyAdmin, addCompanyAdmin } = companyAdminSlice.actions;

export default companyAdminSlice.reducer;
