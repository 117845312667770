import { useState } from "react"
import useAlert from "./useAlert"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import api from "../app/api"
import useAuth from "./useAuth"
import { DeleteAlert } from "../components/Swal Alert"
import { addPaymentMethod, setPaymentMethod,deletePaymentMethodSlice } from "../features/paymentMethod/slice"
import { useDispatch } from "react-redux"

export default ()=>{
    const [loading, setloading] = useState(false)
    const [showPaymentModel, setShowPaymentModel] = useState(false)
    const { showAlert } = useAlert()
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const stripe = useStripe();
    const elements = useElements();
    const [stripeLoading, setStripeLoading] = useState(false)
    const { user } = useAuth()
    const getPaymentMethods = async () => {
        setloading(true);
        try {
            let response = await api.get(
                `/stripe/getPaymentMethods`,
            );

            console.log(response)
            if (response.data?.status) {
                dispatch(setPaymentMethod(response.data?.paymentMethods));
                // setPaymentMethods(response.data?.paymentMethods)
            }
        } catch (error) {
        } finally {
            setloading(false);
        }
    };
    const deletePaymentMethod = async (id) => {
        if (!(await DeleteAlert())) return;
        setloading('delete');
        try {
            let response = await api.post(
                `/stripe/deletePaymentMethod`,
                {
                    paymentMethodId: id
                }
            );
            if (response.data.status) {
                showAlert("Card deleted successfully", "success")
                dispatch(deletePaymentMethodSlice({id}));
            } else {
                showAlert(response.data.message, "danger")
            }
        } catch (error) {
        } finally {
            setloading(false);
        }
    };
    const onAddCard = async (e) => {
        e.preventDefault()
        if (!stripe || !elements) {
            setStripeLoading(false)
            return;
        }
        setStripeLoading(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (error) {
            setError(error.message);
            console.error("Error:", error);
        } else {
            let customer = user.customerID
            if (!user.customerID) {
                const response = await api.post("/stripe/create-customer", {
                    email: user.email,
                    paymentMethodId: paymentMethod.id,
                    userID: user._id,
                });
                if (!response.data?.status) {
                    setError(response.data?.message);
                    return;
                }
                customer = response.data?.result?.stripeCustomerId;
            }
            const response = await api.post("/stripe/addPaymentMethods", {
                paymentMethodId: paymentMethod.id,
                customerID: customer
            });
            if (response.data.status) {
                setStripeLoading(false)
                showAlert("Card added successfully", "success")
                setShowPaymentModel(false)
                dispatch(addPaymentMethod(response.data.result));
                // setPaymentMethods(prevState => {
                //     let newState = [...prevState]
                //     newState.push(response.data.result)
                //     return newState
                // })
            } else {
                setError(response.data.message)
                setStripeLoading(false)
            }
        }
    }
    return {
        loading, 
        setloading,
        showPaymentModel,
        setShowPaymentModel,
        error,
        setError,
        stripeLoading,
        setStripeLoading,
        // paymentMethods,
        // setPaymentMethods,
        getPaymentMethods,
        deletePaymentMethod,
        onAddCard
    }
}