import React from 'react'

import Loader from '../../components/Loader'

const SubmitButton = ({ title, onClick, loading, disabled }) => {
  return (
    <button type="submit" onClick={onClick} disabled={disabled} className="btn btn-primary w-100">
      <Loader loading={loading} color={'white'}>
        {title}
      </Loader>
    </button>
  )
}

export default SubmitButton