import { useAutoAnimate } from "@formkit/auto-animate/react";
import React from "react";

import {
    ddmmyyyy,
    hhmm
} from "../../config/functions";

import useAuth from "../../hooks/useAuth";

import { useContext } from "react";
import FormField from "../../components/FormField";
import { frequency } from "../../config/constants";
import { RiskAssesmentContext } from "../../hooks/riskAssesmentContext";

const RiskAssesmentDetails = ({ data, setData }) => {
  const { categories } = useContext(RiskAssesmentContext);
  const { isCompany, user } = useAuth();
  const [animationParent] = useAutoAnimate();
  //(data)
  const company = isCompany ? user : data?.companyID;
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ].filter((day) => (!company ? true : company?.schedule?.includes(day)));

  const setTimes = (times) => {
    const arr = times?.map((item) => {
      const date = new Date(item);
      const dateTime = new Date(2000, 0, 1);
      dateTime.setHours(date.getHours(), date.getMinutes(), 0, 0);
      return dateTime.getTime();
    });

    setData({
      ...data,
      notificationFrequency: {
        ...data.notificationFrequency,
        times: arr,
      },
    });
  };

  const titleField = {
    value: data?.title,
    setValue: (value) => setData({ ...data, title: value }),
    placeholder: "Enter Risk Assessment title",
    type: "text",
    id: "riskAssesmentTitle",
  };
  const categoryField = {
    type: "dropdown",
    options: categories,
    placeholder: "Select risk assesment category",
    value: data?.categoryID,
    setValue: (value) => setData({ ...data, categoryID: value }),
    id: "riskAssesmentCategory",
  };
  const riskAssesmentFrequencyFields = [
    {
      value: data?.riskAssesmentFrequency,
      setValue: (value) => setData({ ...data, riskAssesmentFrequency: value }),
      type: "radio",
      options: [...frequency, "None"],
    },
    data?.riskAssesmentFrequency != "Weekly"
      ? null
      : {
          placeholder: "Select Day",
          value: data?.notificationFrequency?.day,
          type: "dropdown",
          options: days,
          setValue: (value) => {
            setData({
              ...data,
              notificationFrequency: {
                ...data.notificationFrequency,
                day: value,
              },
            });
          },
        },
    data?.riskAssesmentFrequency != "Monthly"
      ? null
      : {
          placeholder: "Select Day",
          value: data?.notificationFrequency?.day,
          type: "dropdown",
          options: [...new Array(31)].map((item, index) => `${index + 1}`),
          setValue: (value) => {
            setData({
              ...data,
              notificationFrequency: {
                ...data.notificationFrequency,
                day: value,
              },
            });
          },
        },
    data?.riskAssesmentFrequency != "Annual"
      ? null
      : {
          value: ddmmyyyy(data?.notificationFrequency?.date),
          setValue: (value) => {
            const date = new Date(value);
            setData({
              ...data,
              notificationFrequency: {
                ...data.notificationFrequency,
                date: date.getTime(),
              },
            });
          },
          type: "date",
          id: "riskAssesmentTitle",
          placeholder: "Select frequency date",
        },
  ].filter((item) => item);

  const times = data?.notificationFrequency?.times;
  const notificationFrequencyFields = times?.map((time, index) => ({
    value: hhmm(time),
    setValue: (value) => {
      const date = new Date();
      date.setHours(parseInt(value.split(":")[0]));
      date.setMinutes(parseInt(value.split(":")[1]));
      const newTime = date.getTime();
      const newArray = times?.map((item, i) => (i == index ? newTime : item));
      setTimes(newArray);
    },
    type: "time",
    id: `riskAssesmentTime${index}`,
    placeholder: "Select frequency time",
    min: hhmm(company?.workStartTime),
    max: hhmm(company?.workEndTime),
  }));

  return (
    <div className="row">
      {/* Title */}
      <div className="col-md-6 col-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Add Risk Assessment Title</h5>
            <FormField item={titleField} />
            <h5 className="card-title">Category</h5>
            <FormField item={categoryField} />
          </div>
        </div>
      </div>
      {/* Risk Assesment Frequency */}
      <div className="col-md-6 col-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Add Risk Assessment Frequency</h5>
            {riskAssesmentFrequencyFields?.map((item, index) => (
              <FormField item={item} key={index} />
            ))}
          </div>
        </div>
      </div>
      {/* Notification Frequency */}
      {data?.riskAssesmentFrequency == "None" ? null : (
        <div className="col-md-12 col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="card-title">Set Notification Times</h5>
              </div>
              <div className="d-flex flex-column gap-3">
                <ul ref={animationParent} className="ps-0">
                  {notificationFrequencyFields?.map((item, index) => (
                    <li
                      className="d-flex gap-3 mb-3 align-items-center"
                      key={index}
                    >
                      <FormField item={item} />
                      {index == 0 ? null : (
                        <i
                          onClick={() =>
                            setTimes(times?.filter((it, i) => index != i))
                          }
                          className="bi bi-trash3 text-danger"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </li>
                  ))}
                </ul>
                <span
                  className="text-primary text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setTimes([...times, Date.now() + times?.length * 3600000])
                  }
                >
                  <i className="bi bi-plus-circle"></i> Add More
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RiskAssesmentDetails;
