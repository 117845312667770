import { createSlice } from "@reduxjs/toolkit";

const riskAssesmentSlice = createSlice({
    name: "riskAssesment",
    initialState: {
        collection: []
    },
    reducers: {
        setRiskAssesment: (state, action) => {
            state.collection = action.payload;
        },
        updateRiskAssesment: (state, action) => {
            state.collection = state.collection.map(item => item._id == action.payload._id ? action.payload : item);
        },
        deleteRiskAssesment: (state, action) => {
            state.collection = state.collection.filter(item => item._id != action.payload._id);
        },
        addRiskAssesment: (state, action) => {
            state.collection = [...state.collection, action.payload];
        },
    },
});

export const { setRiskAssesment, updateRiskAssesment, deleteRiskAssesment, addRiskAssesment } = riskAssesmentSlice.actions;

export default riskAssesmentSlice.reducer;

