import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Screen from '../../components/Screen'
import FormField from '../../components/FormField';
import SubmitButton from '../../components/SubmitButton';
import ItemList from './ItemList';
import useCompanies from '../../hooks/useCompanies';
import useBroadcast from '../../hooks/useBroadcast';
import useAuth from '../../hooks/useAuth';
import api from '../../app/api';
import useAlert from '../../hooks/useAlert';

const BroadcastDetails = () => {
    const { isAdmin } = useAuth()
    const { loading, setLoading, companies, branches, fetchBranches, fetchCompanies } = useCompanies();
    const {showAlert} = useAlert()
    const {
        addLoading,
        data,
        setData,
        newBroadcast,
        fetchBroadcasts
    } = useBroadcast();
    const [search] = useSearchParams();
    const id = search.get('id');
    const viewOnly = search.get('viewOnly');
    const title = search.get('title');
    const message = search.get('description');
    const [company, setCompany] = useState();
    const [comapniesForFilter, setComapniesForFilter] = useState([])
    const [branchesForFilter, setBranchesForFilter] = useState([])
    const disabled = id;
    const [filter, setfilter] = useState({
        company: "",
        branch: ""
    })
    const fetchCompaniesForFilter = async () => {
        setLoading(true);
        try {
            let response = await api.get(`/company`);
            if (response.data.length < 1) {

            } else {
                setComapniesForFilter(response.data)
            }
            setLoading(false);
        } catch (err) {
            const message = err.response
                ? err.response.data?.message
                    ? err.response.data?.message
                    : err.response.data
                : err;
            setLoading(false);
            showAlert(message, "danger");
            setLoading(false);
        }
    };
      // Branches
  const fetchBranchesForFilter = async (companyID) => {
    setLoading(true);
    try {
        console.log(companyID)
      const response = await api.get(`/company/branch/${companyID}`);
      console.log(response)
      setBranchesForFilter(response.data);
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
    //   showAlert(message, "danger");
    }
  };
    useEffect(() => {
        if (viewOnly) {
            setData({ ...data, message: message, title: title })
        }
    }, [data])
    useEffect(() => {
        if (!viewOnly) {
            fetchBranches(filter.company ? filter.company : company?._id, filter.branch)
        }
    }, [data, filter.branch])
    useEffect(() => {
        if (!viewOnly) {
            fetchCompanies({ limit: 0, company: filter.company })
        }
    }, [filter.company])
    
    useEffect(() => {
        fetchCompaniesForFilter()
    }, [])
    useEffect(() => {
        fetchBranchesForFilter(filter.company)
    }, [filter.company])
    
    useEffect(() => {
        if (!viewOnly) {
            if (id) fetchBroadcasts({ id })
        }
    }, [])
    return (
        <Screen flow={[
            { label: 'Broadcast Notifications', to: '/broadcast' },
            { label: 'Home', to: '/' },
            { label: 'Broadcast', to: '/broadcast' },
            { label: 'Details', to: '/broadcast-details' }
        ]}
        >
            {/* Notification Message */}
            <div className="col-md-12 col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Message</h5>

                        <FormField item={{
                            label: 'Title',
                            value: data.title,
                            setValue: (value) => setData({ ...data, title: value })
                        }} disabled={disabled} />
                        <br />
                        <textarea
                            className="form-control"
                            type='text'
                            value={data.message}
                            onChange={(value) => setData({ ...data, message: value.target.value })}
                            disabled={disabled}
                            rows={5}
                        />

                        {(!viewOnly) && <SubmitButton
                            title={'Send'}
                            color={'primary mt-2'}
                            onClick={disabled ? null : (e) => {
                                e.preventDefault()
                                newBroadcast()
                            }}
                            loading={addLoading}
                            loaderColor={'white'}
                            disabled={disabled}
                        />}
                    </div>
                </div>
            </div>
            {/* List of Reciepnts */}
            {isAdmin && <div style={{flexDirection: "row-reverse",}} className='d-flex gap-2 row-reverse mb-4'>
                {filter.company && <div className='col-sm-2 col-2'>
                    <select
                        className="form-select"
                        onChange={(e) => { setfilter({...filter, branch:e.target.value})}}
                        value={filter.branch}
                        aria-label="Default select example"
                    >
                        <option value="" selected={filter.branch == "" || !filter.branch}>
                            Select Site
                        </option>
                        {branchesForFilter?.map((item, index) => (
                            <option
                                key={index}
                                value={item._id}
                                selected={item._id == filter?.branch}
                                index={index}
                            >
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>}
                <div className='col-sm-2 col-2'>
                    <select
                        className="form-select"
                        onChange={(e) => { setfilter({ company: e.target.value, branch:"" }) }}
                        aria-label="Default select example"
                    >
                        <option value="">Select Company</option>
                        {comapniesForFilter?.map((item, index) => (
                            <option
                                key={index}
                                value={item._id}
                                selected={item._id == filter?.company}
                                index={index}
                            >
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>}
            {isAdmin && <div className="col-md-12 col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Recipients</h5>
                        <div className='d-flex'>
                            <ItemList
                                all={data.all}
                                title={'Companies'}
                                data={companies}
                                selected={data.companies}
                                setSelected={(items) => {
                                    //(items)

                                    fetchBranches(company?._id)
                                    setData({
                                        ...data,
                                        companies: items?.map(comp => ({
                                            ...comp,
                                            all: true,
                                            branches: [],
                                            company: comp._id
                                        }))
                                    })
                                }}
                                setAll={(value) => setData({ ...data, all: value })}
                                setMore={setCompany}
                                disabled={disabled}
                            />
                            <ItemList
                                title={`Branches${company ? ` / ${company?.name}` : ''}`}
                                data={branches}
                                all={data.companies?.find(comp => comp._id == company?._id)?.all}
                                selected={data.companies?.find(item => item._id == company?._id)?.branches}
                                allDisabled={!company || data.all}
                                disabled={disabled}
                                loading={loading}

                                setAll={(value) => setData({
                                    ...data,
                                    companies: data.companies?.map(comp => (comp._id != company?._id ? comp : {
                                        ...comp,
                                        all: value
                                    }))
                                })}
                                setSelected={(items) => setData({
                                    ...data,
                                    companies: data.companies?.map(comp => (comp._id != company?._id ? comp : {
                                        ...comp,
                                        branches: items?.map(branch => ({
                                            ...branch,
                                            branch: branch._id
                                        }))
                                    }))
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>}
        </Screen>
    )
}

export default BroadcastDetails