import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import SlideShowModal from "./SlideShowModal";
import { keys } from "../../config/keys";

const Bubble = ({ item, isNextMe }) => {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  let handleShow = (index) => setShowModal(index);
  const handleClose = () => setShowModal(false);

  const isUs = user?._id == item.sender?._id;
  const date = new Date(item.createdAt);

  const time = date.toLocaleTimeString("en-GB", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });

  const statusIcon =
    item.status == "Sent" ? (
      <i className="bi bi-check2"></i>
    ) : item.status == "Recieved" ? (
      <i className="bi bi-check2-all"></i>
    ) : (
      <i className="bi bi-check2-all text-primary"></i>
    );
  const image = (
    <img
      src={
        item.sender?.imageURL
          ? `${keys.BASE_LOCAL_URL}/${item.sender?.imageURL}`
          : "assets/img/user.png"
      }
      style={{
        height: 30,
        width: 30,
      }}
      className="rounded rounded-circle shadow"
    />
  );
  const images = item.images;

  return (
    <div
      style={{
        maxWidth: "75%",
      }}
      className={`
                d-flex
                align-items-end
                gap-2
                ${isUs ? "align-self-end" : "align-self-start"}  
            `}
    >
      {isUs ? null : isNextMe ? <div className="m-3" /> : image}
      <div
        className={`
                d-flex flex-column
                ${isUs ? "align-items-end" : "align-items-start"}
            `}
      >
        {/* Images Row */}
        {item.images?.length > 0 ? (
          <div
            className={`
                        
                            ${isUs ? "bg-secondary" : "bg-primary"} 
                            text-white 
                            p-3 
                            ${images?.length >= 3 ? "" : "d-flex"}
                        `}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 20,
              borderStyle: "solid",
              borderRadius: 20,
              borderBottomLeftRadius: isUs ? 20 : 0,
              borderBottomRightRadius: isUs ? 0 : 20,
            }}
          >
            {images?.map((img, index) => (
              <div key={index} className="bg-white rounded p-2">
                <img
                  src={`${keys.BASE_LOCAL_URL}/${img}`}
                  style={{
                    objectFit: "cover",
                    height: 200,
                    width: 200,
                    cursor: "pointer",
                    borderRadius: 10,
                  }}
                  onClick={() => handleShow(index + 1)}
                />
              </div>
            ))}
          </div>
        ) : null}
        {/* Bubble */}
        <div
          className={`   
                        ${isUs ? "bg-secondary" : "bg-primary"} 
                        d-flex
                        text-white 
                        p-3 
                    `}
          style={{
            borderRadius: 20,
            borderBottomLeftRadius: isUs ? 20 : 0,
            borderBottomRightRadius: isUs ? 0 : 20,
          }}
        >
          {item.text}
        </div>
        {/* Date row */}
        <div className="d-flex gap-2 align-items-center">
          <span>{time}</span>
          {!isUs ? null : <span>{statusIcon}</span>}
        </div>
      </div>
      {!isUs ? null : isNextMe ? <div className="m-3" /> : image}
      <SlideShowModal
        images={images}
        showModal={showModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Bubble;
