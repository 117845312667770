import React, { useEffect, useState } from "react";

import QuestionList from "./QuestionList";
import FormField from "../../components/FormField";
import { useContext } from "react";
import { AuditContext } from "../../hooks/auditContext";
import api from "../../app/api";
import { keys } from "../../config/keys";
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from "@mui/material";
const AuditQuestions = ({
  data,
  setData,
  question,
  setQuestion,
  onAddQuestiuon,
  onDublicateQuestiuon,
  setAttachments
}) => {
  const { fetchQuestionTypes, questionTypes } = useContext(AuditContext);
  const [sortOrder, setSortOrder] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  // Options
  const questionFields = [
    {
      label: "Question",
      value: question.question,
      setValue: (value) => setQuestion({ ...question, question: value }),
      type: "text",
      id: "checklistTitle",
    },
    {
      label: "Question Type",
      value: question.type,
      setValue: (value) => setQuestion({ ...question, type: value }),
      type: "dropdown",
      options: questionTypes,
      placeholder: "Select question type",
    },
    {
      label: "Images",
      value: question.questionAttachments,
      setValue: async (e) => {
        console.log(e)
        const selectedFiles = Array.from(e.target.files);
        let urls = []
        selectedFiles.forEach(async item => {
          const formData = new FormData();
          formData.append("image", item);
          let response = await api.post('/file/upload', formData);
          console.log(response)
          urls.push(response.data.result)
        })
        setQuestion({ ...question, questionAttachments: urls })
      },
      type: "multiFile",
    },
    {
      label: "Required",
      value: question.required,
      setValue: (value) => setQuestion({ ...question, required: value }),
      type: "boolean",
    },
  ];
  const optionFields = question.options?.map((item, index) => ({
    value: item,
    setValue: (value) => {
      const options = [...question.options];
      options[index] = value;
      setQuestion({ ...question, options });
    },
    type: "text",
    id: "checklistOptions",
  }));


  useEffect(() => {
    fetchQuestionTypes();
  }, []);

  return (
    <div className="row">
      {/* New Question */}
      <div className="col-xl-4">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Add Checklist Question</h5>
            {questionFields?.map((item, index) => {
              if (item.type != 'multiFile') return <FormField key={index} item={item} />
              else return <div className="col-3 w-100 mb-4">
                <label htmlFor={'files'} className="form-label">
                  Images
                </label>
                <input
                  type="file"
                  id="files"
                  multiple
                  max={5}
                  className="form-control"
                  accept={".png, .jpg, .jpeg"}
                  onChange={setAttachments}
                />
              </div>
            }
            )}
            {question.type != "options" ? null : (
              <div>
                <h5 className="card-title">Options</h5>
                <div className="gap-2 d-flex flex-column mb-2">
                  {optionFields?.map((item, index) => (
                    <div
                      className="d-flex gap-2 align-items-center"
                      key={index}
                    >
                      <i className="text-primary bi bi-record-circle"></i>
                      <FormField key={index} item={item} />
                      <i
                        onClick={() => {
                          const options = question.options?.filter(
                            (item, idx) => idx != index
                          );
                          setQuestion({ ...question, options });
                        }}
                        className="bi bi-trash text-danger"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  ))}
                </div>
                <span
                  onClick={() => {
                    const options = question.options ? [...question.options] : [];
                    options.push("");
                    setQuestion({ ...question, options });
                  }}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                >
                  + Add More
                </span>
              </div>
            )}
            {question.type != "Check Box" ? null : (
              <div>
                <h5 className="card-title">Options</h5>
                <div className="gap-2 d-flex flex-column mb-2">
                  {optionFields?.map((item, index) => (
                    <div
                      className="d-flex gap-2 align-items-center"
                      key={index}
                    >
                      <i className="text-primary bi bi-record-circle"></i>
                      <FormField key={index} item={item} />
                      <i
                        onClick={() => {
                          const options = question.options.filter(
                            (item, idx) => idx != index
                          );
                          setQuestion({ ...question, options });
                        }}
                        className="bi bi-trash text-danger"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  ))}
                </div>
                <span
                  onClick={() => {
                    const options = question.options ? [...question.options] : [];
                    options.push("");
                    setQuestion({ ...question, options });
                  }}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                >
                  + Add More
                </span>
              </div>
            )}
            {question?.questionAttachments?.length > 0 && question.questionAttachments?.map(item => (
              <Grid sx={{ position: 'relative' }} item xs={12} sm={12} md={6} lg={4}>
                <CloseIcon sx={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  cursor: 'pointer',
                  color: 'black',
                  fontSize: '30px',
                }} onClick={async () => {
                  setQuestion(prevState => {
                    let newState = { ...prevState }
                    newState.questionAttachments = newState.questionAttachments?.filter(attachment => attachment != item)
                    return newState
                  })
                  await api.delete(`/file/delete?url=${item}`);
                }} />
                <img src={`${keys.BASE_LOCAL_URL}/${item}`} alt="..." width={"100%"} />
              </Grid>
            ))}
            <button
              onClick={(e) => {
                let block = document.getElementById('files')
                if (block) {
                  block.value = ''
                }
                onAddQuestiuon(
                  e,
                  sortOrder,
                  setSortOrder,
                  editIndex,
                  setEditIndex
                );
              }}
              className="mt-3 btn btn-primary"
            >
              {editIndex || editIndex == 0 ? "Update" : "Add"} Question
            </button>
          </div>
        </div>
      </div>
      {/* Question List */}
      <div className="col-xl-8">
        <QuestionList
          questions={data?.questions}
          setQuestion={setQuestion}
          setQuestions={(questions) => setData({ ...data, questions })}
          setData={setData}
          setEditIndex={setEditIndex}
          setSortOrder={setSortOrder}
          question={question}
          onAddQuestiuon={onAddQuestiuon}
          sortOrder={sortOrder}
          editIndex={editIndex}
          onDublicateQuestiuon={onDublicateQuestiuon}
        />
      </div>
    </div>
  );
};

export default AuditQuestions;
