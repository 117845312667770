import React, { useEffect } from 'react';

import useAuth from '../../hooks/useAuth';
import useCompanies from '../../hooks/useCompanies';

import FormField from '../../components/FormField';
import SubmitButton from '../../components/SubmitButton';
import { restricted } from '../../config/restricted';

const Permissions = ({ company, tab, branchID }) => {
    const { isAdmin, isCompany, isManager, permissions: companyPermissions,user } = useAuth();
    const { loading, permissions, fetchPermissions, setPermissions, changePermissions } = useCompanies();
    const isDocument = permissions?.routes?.includes('/documents')

    const branchProfile = (isCompany || isManager) && branchID;
    // const params = branchProfile ? { branchID } : { companyID: company?._id };
    const params  = { companyID: company?._id };
    const disabled = !isAdmin;

    const documentFields = [
        !isDocument || isManager ? null : {
            label: 'Maximum Storage (MBs)',
            value: permissions?.size,
            setValue: (value) => value < 0 ? {} : setPermissions({ ...(permissions ? permissions : {}), size: value }),
            type: 'number',
            placeholder: '0MB'
        },
        // branchProfile ? null : {
        //     label: 'Maximum Sites',
        //     value: permissions?.branches,
        //     setValue: (value) => value < 0 ? {} : setPermissions({ ...(permissions ? permissions : {}), branches: value }),
        //     type: 'number',
        //     placeholder: '0'
        // }
    ].filter(item => item)

    useEffect(() => {
        fetchPermissions(params)
    }, [company, tab])

    return (
        <div className="tab-pane fade pt-3" id="profile-manage-permissions">
            <div className='row'>
                {/* Pages */}
                {/* <div className={'col-12'}>
                    <div className='card'>
                        <div className='card-body p-3'>
                            <h5 className='text-primary'>Tabs</h5>
                            {
                                restricted?.map((route, index) => {
                                    const selected = permissions?.routes?.includes(route.path);
                                    const notInCompanyPermissions = branchProfile && !companyPermissions?.routes?.includes(route.path);
                                    return (
                                        <div key={index} className='d-flex justify-content-between'>
                                            <span >{route.title}</span>
                                            <div className='form-check form-switch'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    disabled={(disabled || notInCompanyPermissions)}
                                                    checked={selected}
                                                    onChange={(disabled || notInCompanyPermissions) ? undefined : () => {
                                                        const routes = (
                                                            !selected
                                                                ? [...(!permissions?.routes ? [] : permissions?.routes), route.path]
                                                                : permissions?.routes?.filter(path => path != route.path)
                                                        )
                                                        const data = { ...(permissions ? permissions : {}), routes };

                                                        changePermissions(params, data)
                                                            .then(() => setPermissions(data))
                                                    }} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div> */}
                {/* Document Size */}
                {!isManager && <div className='col-12'>
                   {!isAdmin &&  <center><p style={{
                        color:'red'
                    }}>If you need more storage, Contact Support!</p></center>}
                    <div className='card'>
                        <div className='card-body d-flex flex-column p-3 gap-3'>
                            {
                                documentFields.map((item, index) => (
                                    <FormField disabled={disabled} item={item} key={index} />
                                ))
                            }
                            {
                                disabled ? null :
                                    <SubmitButton
                                        title={'Set'}
                                        color={'primary w-100'}
                                        loaderColor={'white'}
                                        loading={loading}
                                        onClick={disabled ? null : () => changePermissions(params)}
                                    />
                            }
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    )
}

export default Permissions