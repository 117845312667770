import React from 'react'

const Footer = () => {
    return (
        <footer id="footer" className="footer">
            <div className="copyright">
                &copy; Copyright <strong><span>CompCheck.io</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
            </div>
        </footer>
    )
}

export default Footer