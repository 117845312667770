import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import useDocuments from "../../hooks/useDocuments";
import useAuth from "../../hooks/useAuth";

import FolderList from "./FolderList";
import FilesList from "./FilesList";
import AddDocumentModal from "./AddDocumentModal";
import Stats from "./Stats";
import SubmitButton from "../../components/SubmitButton";
import Screen from "../../components/Screen";
import { PoliciesContext } from "../../hooks/policiesContext";

const Documents = () => {
  const [query] = useSearchParams();

  const { isAdmin, isCompany,isManager } = useAuth();
  const {
    loading,
    setLoading,
    addLoading,
    addFolderLoading,
    deleteLoading,
    page,
    limit,
    docData,
    modalName,
    showAddModal,
    setDocData,
    fetchDocuments,
    setPage,
    fetchFolders,
    AddFolders,
    AddNewDocument,
    setModalName,
    setShowAddModal,
    changeDocument,
    changeFolderName,
    removeFolder,
    changeFolderPrivacy,
  } = useDocuments();

  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState([]);
  const { removeAllFromShare } = useContext(PoliciesContext);
  const [filter, setFilter] = useState({
    company:"",
    branch:""
  });
  const isPublic = query?.get("public");
  const noActions = isPublic && !isAdmin;

  const getDataInitial = () => {
    setFolders([]);
    setSelectedFolder([]);
    setFilter({});
    if (folders.length < 1) {
      if (isPublic) {
        fetchFolders({ isPublic }).then((data) => {
          setFolders([...data]);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        });
      } else {
        fetchFolders({ user_id: localStorage.getItem("_id") }).then((data) => {
          setFolders([...data]);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        });
      }
    }
  };
  const getDataFilter = () => {
    setSelectedFolder([]);
    if (filter.company && !filter.branch) {
      fetchFolders({ user_id: filter.company }).then((data) => {
        setFolders([...data]);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
    } else if (filter.branch) {
      fetchFolders({ user_id: filter.branch, isBranchManager: true }).then(
        (data) => {
          setFolders([...data]);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        }
      );
    } else if (!filter.company && !filter.branch) {
      if (isPublic) {
        fetchFolders({ isPublic }).then((data) => {
          setFolders([...data]);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        });
      } else {
        fetchFolders({ user_id: localStorage.getItem("_id") }).then((data) => {
          setFolders([...data]);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        });
      }
    }
  };
  useEffect(() => {
    getDataInitial();
    removeAllFromShare()
  }, [isPublic]);
  useEffect(() => {
    getDataFilter();
  }, [filter]);

  const handleshowAddModal = () => {
    setDocData({
      ...docData,
      folder_id: selectedFolder[selectedFolder.length - 1]._id,
    });
    setShowAddModal(true);
    setModalName("Add Document");
  };
  return (
    <>
      <Screen
        loading={loading}
        showBranchFilter={true}
        filter={isPublic ? null : filter}
        setFilter={setFilter}
        showShare={isAdmin || isCompany || isManager}
        flow={[
          {
            label: isPublic ? `Public Library` : `Document Library`,
            to: isPublic ? `/documents?public=${isPublic}` : "/documents",
          },
          { label: "Home", to: "/dashboard" },
          {
            label: isPublic ? `Public Library` : `Document Library`,
            to: isPublic ? `/documents?public=${isPublic}` : "/documents",
            onClick: () => {
              setSelectedFolder([]);
              setFolders([]);
              setFilter({
                company: "",
                filter: "",
              });
              if (isPublic) {
                fetchFolders({ isPublic }).then((data) => {
                  setFolders([...data]);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1500);
                });
              } else {
                fetchFolders({ user_id: localStorage.getItem("_id") }).then(
                  (data) => {
                    setFolders([...data]);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1500);
                  }
                );
              }
            },
          },
          ...selectedFolder?.map((item, index) => ({
            label: item.name,
            to: isPublic ? `/documents?public=${true}` : "/documents",
            onClick: () => {
              const arr = selectedFolder;
              arr?.splice(index + 1, arr?.length - (index + 1));
              setFolders(arr);
              if (isPublic) {
                fetchFolders({
                  parentID: selectedFolder[index]._id
                    ? selectedFolder[index]._id
                    : undefined,
                  isPublic,
                }).then((data) => {
                  setFolders([...data]);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1500);
                });
              } else {
                if (filter.company && !filter.branch) {
                  fetchFolders({
                    parentID: selectedFolder[index]?._id || undefined,
                    user_id: selectedFolder[index]?._id
                      ? undefined
                      : filter.company,
                  }).then((data) => {
                    setFolders([...data]);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1500);
                  });
                } else if (filter.branch) {
                  fetchFolders({
                    parentID: selectedFolder[index]?._id || undefined,
                    user_id: filter.branch,
                    isBranchManager: true,
                  }).then((data) => {
                    setFolders([...data]);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1500);
                  });
                } else if (!filter.company && !filter.branch) {
                  if (isPublic) {
                    fetchFolders({ isPublic }).then((data) => {
                      setFolders([...data]);
                      setTimeout(() => {
                        setLoading(false);
                      }, 1500);
                    });
                  } else {
                    fetchFolders({
                      parentID: selectedFolder[index]?._id || undefined,
                      user_id: selectedFolder[index]?._id
                        ? undefined
                        : filter.company,
                    }).then((data) => {
                      setFolders([...data]);
                      setTimeout(() => {
                        setLoading(false);
                      }, 1500);
                    });
                  }
                }
              }
              fetchDocuments({ folder_id: arr[0]._id, page: 1 }).then(() => {});
            },
          })),
        ].filter((item) => item)}
      >
        <Stats isPublic={isPublic} />
        <FolderList
          isPublic={isPublic}
          setFolder={(item) => {
            fetchFolders({ parentID: item._id, isPublic }).then((data) => {
              setSelectedFolder((prevState) => {
                return [...prevState, item];
              });
              setFolders((prevState) => {
                return data;
              });
              setTimeout(() => {
                setLoading(false);
              }, 1500);
            });
            fetchDocuments({ folder_id: item._id, page: 1 });
          }}
          isAdmin={isAdmin}
          isCompany={isCompany}
          filter={filter}
          AddFolders={(name) => {
            AddFolders(
              {
                name,
                parentID: selectedFolder[selectedFolder.length - 1]?._id
                  ? selectedFolder[selectedFolder.length - 1]?._id
                  : undefined,
                visibility: isPublic ? "public" : "private",
              },
              filter,
              isCompany
            );
          }}
          addLoading={addLoading}
          changeFolderName={changeFolderName}
          removeFolder={removeFolder}
          deleteLoading={deleteLoading}
          addFolderLoading={addFolderLoading}
          selectedFolder={selectedFolder}
          changeFolderPrivacy={changeFolderPrivacy}
        />
        {selectedFolder.length < 1 ? null : (
          <>
            {noActions ||
            (selectedFolder[0].name == "Bespoke Documents" && !isAdmin) ||
            (!isAdmin &&
              !isCompany &&
              selectedFolder[0]?.companyFolder) ? null : (
              <div className="col-12 d-flex justify-content-end mb-2 mt-2">
                <SubmitButton
                  title={"Add document"}
                  onClick={handleshowAddModal}
                  color={"success"}
                  loaderColor={"white"}
                />
              </div>
            )}

            <div className="col-12">
              <FilesList
                noActions={noActions}
                setModalName={setModalName}
                setShowAddModal={setShowAddModal}
                setDocData={setDocData}
                docData={docData}
                page={page}
                limit={limit}
                setPage={setPage}
                selectedFolder={selectedFolder}
              />
            </div>
            <AddDocumentModal
              showAddModal={showAddModal}
              docData={docData}
              modalName={modalName}
              setDocData={setDocData}
              handleClose={() => setShowAddModal(false)}
              submit={
                modalName == "Add Document" ? AddNewDocument : changeDocument
              }
              addLoading={addLoading}
            />
          </>
        )}
      </Screen>
    </>
  );
};

export default Documents;
