import React, { useEffect, useState, useRef, useCallback } from "react";

import useChat from "../../hooks/useChat";

import FormField from "../../components/FormField";
import { useSearchParams } from "react-router-dom";
import MessageHeader from "./MessageHeader";
import Bubble from "./Bubble";
import SubmitButton from "../../components/SubmitButton";
import InputImages from "./InputImages";
import Loader from "../../components/Loader";

const Messages = ({ pageState }) => {
  const {
    addLoading,
    page,
    limit,
    messages,
    chats,
    setMessageData,
    messageData,
    loading,
    setLimit,
    fetchMessages,
    newMessage,
    updateMessages,
  } = useChat();
  const [query] = useSearchParams();
  const chatID = query.get("id");
  const params = {
    page,
    limit,
    // text: search
  };

  const chat = chats?.find((item) => item._id == chatID);
  useEffect(() => {
    if (chatID) {
      fetchMessages(chatID, params);
    }
  }, [page, limit, chatID]);

  const field = {
    placeholder: "Type here ...",
    value: messageData.text,
    setValue: (value) => setMessageData({ ...messageData, text: value }),
    // type: 'textarea'
  };
  const handleFileUpload = (event) => {
    const images = event.target.files;
    if (images || images?.length > 0) {
      setMessageData({
        ...messageData,
        images: [...messageData?.images, ...images],
      });
    }
  };

  const data = messages
    ?.slice()
    .reverse()
    .filter((item) => item.chat == chatID);

  const divRef = useRef(null);
  const [scroll, setScroll] = useState({
    atEnd: false,
    atTop: false,
  });

  useEffect(() => {
    if (scroll.atTop && data.length >= limit) {
      setLimit(limit + 10);
      scrollToBottom(200);
    }
  }, [scroll]);

  const handleScroll = useCallback(() => {
    const divElement = divRef.current;
    // Check if the div is scrolled to the end
    const atEnd = divElement.scrollTop >= 0;
    const atTop =
      divElement.scrollTop <
      (divElement.scrollHeight - divElement.clientHeight - 3) * -1;
    if (atEnd && chatID) updateMessages(chatID, "Seen");

    setScroll({ ...scroll, atEnd, atTop });
  }, [divRef]);

  useEffect(() => {
    const divElement = divRef.current;

    // Attach the scroll event listener to the div element
    divElement.addEventListener("scroll", handleScroll);

    // Initially check the scroll position and set isScrollAtEnd
    handleScroll();

    // Cleanup the event listener when the component unmounts
    return () => {
      divElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToBottom = (pixels) => {
    if (divRef.current) {
      divRef.current.scrollTop = pixels
        ? divRef.current.scrollTop + pixels
        : divRef.current.scrollHeight;
    }
  };

  return (
    <div className="col-md-12">
      <div className="card" style={{ height: "100%" }}>
        <MessageHeader chat={chat} pageState={pageState} />
        {/* List */}
        <div
          ref={divRef}
          className="d-flex align-items-end"
          style={{
            overflow: "auto",
            overflowX: "hidden",
            height: "50vh",
            flexDirection: "column-reverse",
          }}
        >
          <Loader loading={scroll.atTop && loading} />
          <div className="d-flex flex-column w-100 gap-2 ps-4 pe-4 pt-2 pb-2">
            {data?.map((item, index) => {
              const isNextMe =
                item?.sender?._id == data[index + 1]?.sender?._id;
              return <Bubble item={item} key={index} isNextMe={isNextMe} />;
            })}
          </div>
          {scroll.atEnd ? null : (
            <div
              className="d-flex p-3"
              style={{ position: "absolute" }}
              onClick={scrollToBottom}
            >
              <div
                className={`d-flex ${
                  chat?.unseen ? "border border-danger" : ""
                } align-items-center justify-content-center rounded rounded-circle btn btn-light`}
                style={{ width: 50, height: 50 }}
              >
                <i className="bi bi-chevron-down"></i>
              </div>
              {!chat?.unseen ? null : (
                <div
                  style={{ position: "absolute", right: "45%", top: "-10%" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="text-danger" style={{ fontWeight: "bold" }}>
                    {chat?.unseen}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        {/* Images View */}
        <InputImages
          images={messageData?.images}
          setImages={(images) => setMessageData({ ...messageData, images })}
        />
        {/* Input */}

        <form
          className="d-flex p-2 gap-2"
          onSubmit={(e) => {
            e.preventDefault();
            newMessage(chatID, messageData);
          }}
        >
          <FormField
            item={field}
            update={() => {
              updateMessages(chatID, "Seen");
            }}
            onClick={() => (!chatID ? {} : updateMessages(chatID, "Seen"))}
          />
          <div>
            <label htmlFor="fileInput" className="btn">
              <i className="bi bi-image"></i>
            </label>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileUpload}
              multiple={true}
            />
          </div>
          <SubmitButton
            title={"Send"}
            color={"primary"}
            loaderColor={"white"}
            type="submit"
            loading={addLoading}
          />
        </form>
      </div>
    </div>
  );
};

export default Messages;
