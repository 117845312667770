import axios from "axios";
import { keys } from "../config/keys";

const api = axios.create({
  // baseURL: "http://localhost:5000/api",
  baseURL: keys.BASE_URL,
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    // const subscription = localStorage.getItem("_klIDbSoIaBU");
    if (token) {
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    }
    // if(subscription){
    //   config.headers = { ...config.headers, subscription: `${subscription}` };
    // }
    return config;
  },
  (err) => Promise.reject(err)
);

export default api;
