import { createSlice } from "@reduxjs/toolkit";

const documentSlice = createSlice({
  name: "proposals",
  initialState: {
    proposals: [],
  },
  reducers: {
    setProposal: (state, action) => {
      state.proposals = action.payload;
    },
    updateProposal: (state, action) => {
      state.proposals = state.proposals.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteProposal: (state, action) => {
      state.proposals = state.proposals.filter(
        (item) => item._id != action.payload._id
      );
    },
    addProposal: (state, action) => {
      state.proposals = [...state.proposals, action.payload];
    }
  },
});

export const {
  setProposal,
  updateProposal,
  deleteProposal,
  addProposal,
} = documentSlice.actions;

export default documentSlice.reducer;
