import React, { useContext, useEffect, useState } from "react";

import useChat from "../../hooks/useChat";
import { keys } from "../../config/keys";
import FormField from "../../components/FormField";
import EmptyData from "../../components/EmptyData";

import SidebarItem from "./SidebarItem";
import useBroadcast from "../../hooks/useBroadcast";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useCompanies from "../../hooks/useCompanies";
import { useSelector } from "react-redux";
import appContext from "../../app/contextState/context";
import Loader from "../../components/Loader";
const Sidebar = ({ setChat, pageState, setpageState }) => {
  const { page, limit, chats, fetchChats } = useChat();
  const { setShowMessageSidebar } = useContext(appContext);
  const { isAdmin, isManager, isCompany } = useAuth();
  const { companies, branches, fetchUsers } = useCompanies();
  const { getForCompany, broadcasts } = useBroadcast();
  const { newChat } = useChat();
  const [search, setSearch] = useState("");
  const params = {
    page,
    limit,
    text: search,
  };
  useEffect(() => {
    getForCompany();
    fetchChats(params);
  }, [search, page, limit]);

  const fields = [
    {
      placeholder: "Search...",
      value: search,
      setValue: (value) => setSearch(value),
    },
  ];

  return (
    <div className="col-md-12">
      <div className="card" style={{ height: "100%" }}>
        <div
          className="card-header d-flex"
          style={{
            justifyContent: "space-between",
          }}
        >
          <button
            className="btn btn-primary"
            style={{
              height: "40px",
              backgroundColor:
                (pageState == "newChat" || pageState == "broadcast") &&
                "transparent",
              color:
                (pageState == "newChat" || pageState == "broadcast") && "blue",
            }}
            onClick={() => {
              setpageState("inbox");
            }}
          >
            Inbox
          </button>
          {!isAdmin && !isManager && !isCompany ? (
            <button
              className="btn btn-primary"
              style={{
                height: "40px",
                backgroundColor: pageState == "inbox" && "transparent",
                color: pageState == "inbox" && "blue",
              }}
              onClick={() => {
                setpageState("newChat");
                fetchUsers();
              }}
            >
              Broadcasts
            </button>
          ) : (
            <button
              className="btn btn-primary"
              style={{
                height: "40px",
                backgroundColor:
                  (pageState == "inbox" || pageState == "broadcast") &&
                  "transparent",
                color:
                  (pageState == "inbox" || pageState == "broadcast") && "blue",
              }}
              onClick={() => {
                setpageState("newChat");
                fetchUsers();
              }}
            >
              New Chat
            </button>
          )}
        </div>
        {pageState == "inbox" ? (
          <>
            <div className="card-header">
              {fields?.map((item, index) => (
                <FormField item={item} key={index} />
              ))}
            </div>
            <div
              className="card-body"
              style={{
                overflow: "auto",
                overflowX: "hidden",
                height: "50vh",
              }}
            >
              <div className="d-flex flex-column gap-2">
                <EmptyData data={chats} message={"No chats found"}>
                  {chats
                    ?.slice()
                    // ?.filter((item) => (search && !loading) || item.lastMessage)
                    ?.sort(
                      (a, b) =>
                        new Date(b.lastMessage?.createdAt).getTime() -
                        new Date(a.lastMessage?.createdAt).getTime()
                    )
                    ?.map((item, index) => {
                      return (
                        <SidebarItem
                          item={item}
                          key={index}
                          setChat={setChat}
                        />
                      );
                    })}
                </EmptyData>
              </div>
            </div>
          </>
        ) : pageState == "broadcast" ? (
          <>
            <div
              className="card-body"
              style={{
                overflow: "scroll",
                overflowX: "hidden",
                height: "50vh",
              }}
            >
              <div className="d-flex flex-column gap-2">
                <EmptyData data={broadcasts} message={"No broadcasts found"}>
                  {broadcasts?.map((item, index) => (
                    <Link
                      className="d-flex gap-2 align-items-center justify-content-start rounded-2 p-2 mt-3 text-black"
                      to={`/inbox?broadcast=${item._id}`}
                      onClick={() => {}}
                    >
                      <div>
                        <img
                          src={"assets/img/logo.png"}
                          className="rounded rounded-circle shadow "
                          style={{
                            objectFit: "cover",
                            height: 50,
                            width: 50,
                          }}
                        />
                      </div>
                      <div className="d-flex w-100 flex-column gap-1 ">
                        <div className="d-flex align-items-center justify-content-between">
                          <span
                            style={{
                              maxWidth: "50%",
                              textOverflow: "ellipsis",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            Super Admin
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <span
                            style={{
                              maxWidth: "50%",
                              textOverflow: "ellipsis",
                              fontWeight: "normal",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {item.message.substring(0, 10)}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </EmptyData>
              </div>
            </div>
          </>
        ) : pageState == "newChat" ? (
          <>
            <div
              className="card-body"
              style={{
                overflow: "scroll",
                overflowX: "hidden",
                height: "70vh",
              }}
            >
              <div className="d-flex flex-column gap-2">
                <EmptyData
                  data={isAdmin || isCompany ? companies : branches}
                  message={"No companies found"}
                >
                  {companies.length > 0 ? (
                    companies?.map((item, index) => {
                      return (
                        <Link
                          key={index}
                          className="d-flex gap-2 align-items-center justify-content-start rounded-2 p-2 mt-3 text-black"
                          to={`/inbox?id=${item._id}`}
                          onClick={() => {
                            newChat(
                              item._id,
                              {
                                users: [item._id, localStorage.getItem("_id")],
                              },
                              setpageState,
                              setShowMessageSidebar
                            ).then(() => {
                              // setShowMessageSidebar(!showMessageSidebar);
                              setpageState("inbox");
                            });
                          }}
                        >
                          <div>
                            <img
                              src={
                                item.imageURL
                                  ? `${keys.BASE_LOCAL_URL}/${item.imageURL}`
                                  : "assets/img/logo.png"
                              }
                              className="rounded rounded-circle shadow "
                              style={{
                                objectFit: "cover",
                                height: 50,
                                width: 50,
                              }}
                            />
                          </div>
                          <div className="d-flex w-100 flex-column gap-1 ">
                            <div className="d-flex align-items-center justify-content-between">
                              <span
                                style={{
                                  maxWidth: "50%",
                                  textOverflow: "ellipsis",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                {item.name}
                              </span>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  ) : (
                    <Loader />
                  )}
                </EmptyData>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
