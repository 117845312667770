import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import useNature from '../hooks/useNature';

const NatureFilter = ({ nature, selectNature ,className}) => {
  const location = useLocation();
  const { natures, fetchNatures } = useNature();

  useEffect(() => {
    fetchNatures();
  }, [])

  return  (
    <select className={`form-select ${className}`} onChange={selectNature} aria-label="Default select example">
      <option value=''>Select Nature</option>
      {
        natures
          ?.map((item, index) => (
            <option
            key={index}
              value={item._id}
              selected={item._id == nature}
              index={index}
            >
              {item.name}
            </option>
          ))
      }
    </select>
  )
}

export default NatureFilter