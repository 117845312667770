import React, { useEffect, useState } from 'react'

import useCompanies from '../../hooks/useCompanies';
import { hhmm } from '../../config/functions';
import FormField from '../../components/FormField';

const Overview = ({ company, tab, isManager, branchID }) => {
    const {
        addLoading: loading,
        data: schedule,
        setData,
        branches,
        fetchBranches,
        changeCompany
    } = useCompanies();
    const fields = [
        {
            value: hhmm(schedule?.workStartTime),
            setValue: (value) => {
                const date = new Date()
                date.setHours(parseInt(value.split(':')[0]))
                date.setMinutes(parseInt(value.split(':')[1]))
                setData({ ...schedule, workStartTime: date.getTime() })
                changeCompany(undefined, {
                    ...schedule,
                    workStartTime: date.getTime(),
                    _id: company?._id
                })
            },
            type: 'time',
            id: 'workStartTime',
            placeholder: 'Start',
            disabled: loading
        },
        {
            value: hhmm(schedule?.workEndTime),
            setValue: (value) => {
                const date = new Date()
                date.setHours(parseInt(value.split(':')[0]))
                date.setMinutes(parseInt(value.split(':')[1]))
                setData({ ...schedule, workEndTime: date.getTime() })
                changeCompany(undefined, {
                    ...schedule,
                    workEndTime: date.getTime(),
                    _id: company?._id
                })
            },
            type: 'time',
            id: 'workEndTime',
            placeholder: 'End',
            disabled: loading
        },
    ]

    useEffect(() => {
        if (company?._id && tab?.includes('overview')) fetchBranches(company._id);
        if (company) setData(company)
        //(company)
    }, [])

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const branch = branchID ? branches?.find(item => item._id == branchID) : branches[0]

    const data =
        isManager || branchID ? [
            { label: 'Name', value: branch?.name },
            { label: 'Location', value: branch?.location },
        ] : [
            { label: 'Email Address', value: company.email },
            { label: 'Business Name', value: company?.name },
            { label: 'Industry', value: company.nature?.name },
            { label: 'Position', value: company.position },
            { label: 'Manager Name', value: company.adminName },
            { label: 'Manager Email', value: company.adminEmail },
            { label: 'Number of Employees', value: company.employees },
            { label: 'Website', value: company.website },
            { label: 'Phone Number', value: company.telephone1 },
            { label: 'Telephone 2', value: company.telephone2 },
            { label: 'Address', value: company.address },
            ...branches?.map((item, index) => ({ label: `Additional Site ${index + 1}`, value: item?.name }))
        ]

    return (
        <div className={`tab-pane fade pt-3 ${tab?.includes('overview') ? ' show active' : ''}`} id="profile-overview">
            {/* About */}
            <h5 className="card-title">About</h5>
            <p className="small fst-italic">{branchID ? (branch?.about ? branch?.about : 'About not added yet'):(company.about ? company.about : 'About not added yet')}</p>

            {/* Schedule */}
            {
                isManager || branchID ? null :
                    <div className='mb-2'>
                        <h5 className="card-title">Schedule</h5>
                        {/* Days */}
                        <div className='d-flex gap-2 w-100 mb-2' style={{ flexWrap: 'wrap' }}>
                            {
                                days.map((day, index) => {
                                    const selected = schedule.schedule?.includes(day);
                                    const filtered = !selected ? [...(schedule.schedule ? schedule.schedule : []), day] : schedule.schedule.filter(item => item != day);
                                    return (
                                        <button
                                            onClick={() => {
                                                setData({ ...schedule, schedule: filtered });
                                                //(company)
                                                changeCompany(undefined, {
                                                    ...schedule,
                                                    "schedule": filtered,
                                                    _id: company?._id
                                                })
                                            }}
                                            className={`d-flex justify-content-center btn ${!selected ? 'btn-outline-primary' : 'btn-primary'}`}
                                            style={{ flex: 1 }}
                                            key={index}
                                            disabled={loading}
                                        >
                                            {day}
                                        </button>
                                    )
                                })
                            }
                        </div>
                        {/* Timing */}
                        <div className='d-flex gap-2 w-100 mb-2' style={{ flexWrap: 'wrap' }}>
                            {
                                fields?.map((item, index) => (
                                    <div style={{ flex: 1 }} key={index}>
                                        <h5 className="card-title">{item.placeholder}</h5>
                                        <FormField item={item} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
            }


            {/* Details */}
            <h5 className="card-title">{isManager ? 'Site' : 'Company'} Details</h5>
            {
                data?.map((item, index) => !item.value ? null : (
                    <div className="row" key={index}>
                        <div className="col-lg-3 col-md-4 label ">{item.label}</div>
                        <div className="col-lg-9 col-md-8">{item.value}</div>
                    </div>
                ))
            }

        </div>
    )
}

export default Overview