import { createSlice } from "@reduxjs/toolkit";

const natureSlice = createSlice({
  name: "nature",
  initialState: {
    natures: []
  },
  reducers: {
    setNature: (state, action) => {
      state.natures = action.payload;
    },
    updateNature: (state, action) => {
      state.natures = state.natures.map(item => item._id == action.payload._id ? action.payload : item);
    },
    deleteNature: (state, action) => {
      state.natures = state.natures.filter(item => item._id != action.payload._id);
      state.natures = state.natures.map(item => item.name == 'Default' ? { ...item, checklists: action.payload.checklists + item.checklists } : item);
    },
    addNature: (state, action) => {
      state.natures = [...state.natures, { ...action.payload, checklists: 0 }];
    },
  },
});

export const { setNature, updateNature, deleteNature, addNature } = natureSlice.actions;

export default natureSlice.reducer;
