import { createSlice } from "@reduxjs/toolkit";

const checklistSlice = createSlice({
    name: "checklist",
    initialState: {
        collection: []
    },
    reducers: {
        setChecklist: (state, action) => {
            state.collection = action.payload;
        },
        updateChecklist: (state, action) => {
            state.collection = state.collection.map(item => item._id == action.payload._id ? action.payload : item);
        },
        deleteChecklist: (state, action) => {
            state.collection = state.collection.filter(item => item._id != action.payload._id);
        },
        addChecklist: (state, action) => {
            state.collection = [...state.collection, action.payload];
        },
    },
});

export const { setChecklist, updateChecklist, deleteChecklist, addChecklist } = checklistSlice.actions;

export default checklistSlice.reducer;

