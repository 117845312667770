import { Checkbox, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../app/api";
import Loader from "../../components/Loader";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import { DeleteAlert } from "../../components/Swal Alert";
import Table from "../../components/Table";
import { convertToLocal } from "../../config/functions";
import { keys } from "../../config/keys";
import { PoliciesContext } from "../../hooks/policiesContext";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { deleteProposal, setProposal } from "./slice";

const FilesList = () => {
  const { proposals } = useSelector(state => state.proposals)
  const [deleteLoading, setDeleteLoading] = useState(null);
  const [resendLoading, setResendLoading] = useState(null)
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false);
  const [filter, setfilter] = useState({
    company: "",
    admin: "",
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { showAlert } = useAlert()
  const getData = async () => {
    setloading("initial");
    try {
      let response = await api.get(`/proposal/getAll`, {
        ...filter,
        page,
        limit,
      });
      if (response.data?.status) {
        // setProposals(response.data?.result);
        dispatch(setProposal(response.data?.result));
      }
    } catch (error) {
    } finally {
      setloading(null);
    }
  };
  const resendProposal = async (id) => {
    setResendLoading(id);
    try {
      let response = await api.post(`/proposal/resend?_id=${id}`);
      console.log(response);
      if (response.data?.status) {
        showAlert("Proposal resent successfully.", "success")
        // setProposals(response.data?.result);
        // dispatch(setSubscriptions(response.data?.result));
      }
    } catch (error) {
    } finally {
      setResendLoading(null);
    }
  };
  const removeProposal = async (id) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(id)
    try {
      let response = await api.delete(`/proposal/delete?_id=${id}`);
      if (response.data?.status) {
        showAlert(response?.data?.message, "success")
        dispatch(deleteProposal(response.data?.result));
      } else {
        showAlert(response?.data?.message, "danger")
      }
    } catch (error) {

    } finally {
      setDeleteLoading(null)
    }
  }
  // const [proposals, setProposals] = useState([]);
  // const { subscriptions } = useSelector((state) => state.subscription);
  const { isAdmin } = useAuth();
  const {
    selectedForShare,
    addForShare,
    removeFromShare,
    removeAllFromShare,
    addAllToShare,
  } = useContext(PoliciesContext);

  const labels = [
    isAdmin ? (
      <Checkbox
        checked={selectedForShare.length == proposals.length}
        onChange={async (e) => {
          if (e.target.checked) {
            addAllToShare(proposals ? proposals : []);
          } else {
            removeAllFromShare();
          }
        }}
      />
    ) : (
      "#"
    ),
    "Name",
    "Cost",
    "Date Added",
    "Added for",
    "Added By",
    "Status",
    "File",
    isAdmin && "Actions",
  ].filter((item) => item);
  const onView = (item) => {
    window.open(`${keys.BASE_LOCAL_URL}/${item.pdfLink}`, "_blank", "noreferrer");
  };
  const data = useMemo(() => {
    if (!proposals || proposals.length === 0) return [];
    return proposals.map((item) => {
      return {
        data: item,
        fields: [
          isAdmin ? (
            <Checkbox
              checked={selectedForShare.includes(item)}
              onChange={async (e) => {
                if (e.target.checked) {
                  addForShare(item);
                } else {
                  removeFromShare(item);
                }
              }}
            />
          ) : (
            "#"
          ),
          item?.name,
          <td>{item?.subscriptionID?.cost}</td>,
          convertToLocal(item?.createdAt),
          <td>{item?.email}</td>,
          <td>{item?.createdBy?.name}</td>,
          <td>{item?.subscriptionID?.status}</td>,
          <SubmitButton
            icon={"Download"}
            loading={loading == "download"}
            onClick={() => {
              onView(item)
            }}
            color={"success"}
            loaderColor={"white"}
          />,
          isAdmin && (
            <div className="d-flex gap-3">
              <SubmitButton
                icon={"Resend"}
                loading={resendLoading == item._id}
                onClick={() => {
                  resendProposal(item._id)
                }}
                color={"success"}
                loaderColor={"white"}
                disabled={item.subscriptionID?.status == "paid"}
              />
              {item?._id == deleteLoading ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={deleteLoading != null} color={"red"} />
                </div>
              ) : (
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeProposal(item._id)}
                ></i>
              )}
              <Tooltip title="Copy proposal link">
                <i
                  className="bi bi-clipboard"
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    await navigator.clipboard.writeText(`${keys.BASE_LOCAL_URL}/${item.pdfLink}`);
                    showAlert("Proposal link copies to clipboard", "success");
                  }}
                ></i>
              </Tooltip>
            </div>
          ),
        ].filter((it) => it),
      };
    });
  }, [proposals, selectedForShare, deleteLoading, loading, resendLoading]);
  // const handleConnect = () => {
  //   console.log("here")
  //   window.location.href = `http://localhost:5001/api/stripe/connect?adminId=${localStorage.getItem('_id')}`; // Redirect to Stripe OAuth
  // };
  useEffect(() => {
    getData();
  }, [page, limit, filter]);

  return (
    <Screen
      loading={loading == "initial"}

      flow={[
        { label: `Proposals`, to: `/proposal-list` },
        { label: "Home", to: "/checklists" },
        { label: `Proposals`, to: `/proposal-list` },
      ]}
    >
      {/* <button onClick={handleConnect}>
        Connect with Stripe
      </button> */}
      <Table
        labels={labels}
        data={data}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      // totalData={totalMsDocs}
      />
    </Screen>
  );
};

export default FilesList;
