import React from 'react'
import { Link } from 'react-router-dom';

const BreadCrumb = ({ flow }) => {
  return (
    <div className="pagetitle">
      <h1>{flow[0].label}</h1>
      <nav>
        <ol className="breadcrumb">
          {
            flow?.map((item, index) => {
              if (index == 0) return;
              const active = index == flow?.length - 1;
              return (
                <li onClick={item.onClick} key={index} className={`breadcrumb-item ${active ? 'active' : ''}`}>
                  {active ? item.label : <Link to={item.to}>{item.label}</Link>}
                </li>
              )
            })
          }
        </ol>
      </nav>
    </div>
  )
}

export default BreadCrumb