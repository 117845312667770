import React, { useEffect } from 'react'
import useCategory from '../../hooks/useCategory'
import { useLocation } from 'react-router-dom';

const CategoryFilter = ({ category, selectCategory }) => {
  const location = useLocation();
  const { categories, fetchCategories } = useCategory();

  useEffect(() => {
    fetchCategories();
  }, [])

  return (location.pathname != '/checklists' && location.pathname != '/policy' && location.pathname != '/risk-assesments') ? null : (
    <select className="form-select" onChange={selectCategory} aria-label="Default select example">
      <option value=''>Select Category</option>
      {
        categories
          ?.map((item, index) => (
            <option
            key={index}
              value={item._id}
              selected={item._id == category}
              index={index}
            >
              {item.name}
            </option>
          ))
      }
    </select>
  )
}

export default CategoryFilter