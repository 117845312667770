import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"

import api from "../app/api";
import useAlert from "./useAlert";
import Alert, { DeleteAlert, UpdateAlert } from '../components/Swal Alert/index';

import { addCompanyAdmin, deleteCompanyAdmin, setCompanyAdmin, updateCompanyAdmin } from "../features/admins/slice";

export default () => {
  const dispatch = useDispatch();
  const { companyAdmins } = useSelector(state => state.companyAdmins)

  const { showAlert } = useAlert()

  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([])
  // New Admin State
  const [data, setData] = useState({
    name: '',
  })
  const fetchAdmins = async (params) => {
    setLoading(true);
    try {
      const response = await api.get(`/companyAdmin`, { params });
      dispatch(setCompanyAdmin(response.data))
      setLoading(false);
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setLoading(false);
    }
  }
  const newAdmin = async () => {
    setAddLoading(true);
    try {
      const response = await api.post('/companyAdmin/new', {...data, assignedCompanies: selectedCompanies});
      dispatch(addCompanyAdmin(response.data.data))
      setAddLoading(false);
      setData({ name: '', email: '', status: false })
      setSelectedCompanies([])
      showAlert(response.data.message, 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      Alert('error', message);
      // showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const changeAdmin = async () => {
    if (!await UpdateAlert()) return
    setAddLoading(true);
    try {
      let selectedCompaniesIds = selectedCompanies.map((company) => company._id)

      const response = await api.put(`/companyAdmin/update/${data._id}`,  {...data, assignedCompanies: selectedCompaniesIds});
      dispatch(updateCompanyAdmin(data))
      setData({ name: '', email: '', status: false })
      setSelectedCompanies([])
      setAddLoading(false);
      showAlert('Admin updated successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const removeAdmin = async (item) => {
    if (!await DeleteAlert()) return
    setDeleteLoading(item);
    try {
      const response = await api.delete(`/companyAdmin/delete/${item ? item._id : data._id}`);
      dispatch(deleteCompanyAdmin(item))
      setDeleteLoading(false);
      showAlert('Admin removed successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setDeleteLoading(false);
    }
  }

  return {
    companyAdmins,
    loading,
    addLoading,
    deleteLoading,
    data,
    setData,
    fetchAdmins,
    newAdmin,
    changeAdmin,
    removeAdmin,
    selectedCompanies, setSelectedCompanies
  }
}