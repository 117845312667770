import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import imagePlaceholder from "../assets/placeholder-img.png";
import useAuth from "../hooks/useAuth";
import PasswordPopup from "../components/PasswordPopup";
import NotificationList from "../features/notification/NotificationList";
import { keys } from "../config/keys";
import useAlert from "../hooks/useAlert";

const Header = () => {
  const { user, onLogout, isCompany, isManager, isAdmin, onUser } = useAuth();
  const { showAlert } = useAlert();
  const [sidebar, setSidebar] = useState();
  const [changePassword, setChangePassword] = useState(false);

  const showSidebar = (event) => {
    document.body.classList.add("toggle-sidebar");
    setSidebar(true);
  };
  const hideSidebar = (event) => {
    document.body.classList.remove("toggle-sidebar");
    setSidebar(false);
  };
  const uploadAdminImage = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target?.files[0]);
    await fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/admins/updateImage?id=${localStorage.getItem("_id")}`,
      {
        method: "PUT",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status) {
          onUser();
          showAlert(response.message, "success");
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    hideSidebar();
  }, []);

  const query = isCompany
    ? `companyID=${user?._id}`
    : `branchID=${user?.branchID}`;

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      {/* Logo */}
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/dashboard" className="logo d-flex align-items-center">
          <img src={"assets/img/logo.png"} alt="" />
          <span className="d-none d-lg-block">CompCheck.io</span>
        </Link>
        <i
          className="bi bi-list toggle-sidebar-btn"
          onClick={sidebar ? hideSidebar : showSidebar}
        ></i>
      </div>

      {/* Navigation */}
      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <NotificationList />
          {/* Profile */}
          <li className="nav-item dropdown pe-3">
            {/* Profile Image */}
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <img
                src={
                  user?.imageURL
                    ? `${keys.BASE_LOCAL_URL}/${user.imageURL}`
                    : imagePlaceholder
                }
                alt="Profile"
                className="rounded-circle"
              />
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {user?.name}
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              {/* Details */}
              <li className="dropdown-header">
                <h6>{user?.name}</h6>
                <span>{user?.email}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {isAdmin && (
                <li>
                  <div className="col-md-8 col-lg-9">
                    <div className="pt-2">
                      <input
                        type="file"
                        accept="image/*"
                        id="pfpp"
                        className="hidden"
                        onChange={uploadAdminImage}
                      />
                      <label
                        htmlFor="pfpp"
                        className="dropdown-item d-flex align-items-center"
                        title="Upload new profile image"
                      >
                        <i className="bi bi-lock"></i>
                        Upload Profile Picture
                        {/* <i className="bi bi-upload"></i> */}
                      </label>
                    </div>
                  </div>
                </li>
              )}
              {/* Profile */}
              {!isCompany && !isManager ? null : (
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to={`/profile?${query}`}
                    state={user}
                  >
                    <i className="bi bi-person"></i>
                    <span>{isManager ? "Site" : "My"} Profile</span>
                  </Link>
                </li>
              )}
              {/* Logout */}
              <li>
                <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={() => setChangePassword(true)}
                >
                  <i className="bi bi-lock"></i>
                  <span>Change Password</span>
                </button>
              </li>
              {/* Logout */}
              <li>
                <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={onLogout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <PasswordPopup
        show={changePassword}
        handleClose={() => setChangePassword(false)}
      />
    </header>
  );
};

export default Header;
