import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// import useAudit from '../../hooks/useAudit'

import Pagination from "../../components/Pagination";
import Screen from "../../components/Screen";
import AuditCard from "../../components/AuditCard";
// import CategoryFilter from '../category/CategoryFilter'
import EmptyData from "../../components/EmptyData";
import useAuth from "../../hooks/useAuth";
import appContext from "../../app/contextState/context";
import { AuditContext } from "../../hooks/auditContext";

const Collection = () => {
  const location = useLocation();
  const { auditCollection } = useSelector((state) => state.audit);
  const {user, isAdmin} = useAuth()
  const state = useContext(appContext);
  const { loading } = state;
  const { limit, page, fetchAudits, setPage,setLimit } =
    useContext(AuditContext);
  const { isCompany, isManager } = useAuth();
  const [filter, setFilter] = useState({
    company: "",
    category: "",
  });

  const template = location?.search.split("?template=")[1];
  const review = location?.search.split("?review=")[1];

  const title = template ? "Templates" : review ? "Reviews" : "Audit";
  const path = template
    ? "/audits?template=true"
    : review
    ? "/audits?review=true"
    : "/audits";

  const params = {
    page,
    limit,
    company: filter.company,
    category: filter.category,
    ...(template ? { template: true } : {}),
    ...(review ? { status: "pending" } : {}),
    isCompany,
    isManager,
  };

  useEffect(() => {
    fetchAudits(params);
  }, [filter, page, limit, review, template]);
  useEffect(() => {
    setPage(1);
  }, [template]);

  const data = auditCollection;
  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setFilter}
      flow={[
        { label: title, to: path },
        { label: "Home", to: "/audits" },
        { label: title, to: path },
      ]}
      dontShowCompany={template ? true : false}
      allowed={template && (user?.subscriptionID?.allowedCLTemplates != undefined && !user?.subscriptionID?.allowedCLTemplates && !isAdmin)}
    >
      <section className="section" id="checklist">
        <EmptyData data={data} onReload={() => fetchAudits(params)}>
          <div className="row icon-boxes">
            {data?.map((item, index) => {
              return (
                <AuditCard
                  data={item}
                  key={index}
                  type="new"
                  template={template}
                  select={template}
                />
              );
            })}
          </div>
        </EmptyData>
        <Pagination
          data={auditCollection}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </section>
    </Screen>
  );
};

export default Collection;
