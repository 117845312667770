import React, { useContext, useState } from "react";

import Table from "../../components/Table";
import { capitalFirst } from "../../config/functions";
import FormField from "../../components/FormField";
import { ChecklistContext } from "../../hooks/checklistContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Card, MenuItem } from "@mui/material";
const QuestionList = ({
  questions,
  setQuestions,
  setQuestion,
  setEditIndex,
  setSortOrder,
  question,
  onAddQuestiuon,
  sortOrder,
  editIndex,
  onDublicateQuestiuon,
}) => {
  const labels = ["#", "Question", "Question Type", "Required", "Actions"];
  const { questionTypes } = useContext(ChecklistContext);
  const [dubIndex, setDubIndex] = useState(null);
  const onChange = (question, required) => {
    setQuestions(
      questions?.map((item) =>
        item.question == question.question ? { ...item, required } : item
      )
    );
  };
  const onDelete = (question) => {
    setQuestions(
      questions?.filter((item) => item.question != question.question)
    );
  };
  const onEdit = (question, index) => {
    setQuestion(question);
    setEditIndex(index);
    setSortOrder(index + 1);
  };
  const optionFields = question.options?.map((item, index) => ({
    value: item,
    setValue: (value) => {
      const options = [...question.options];
      options[index] = value;
      setQuestion({ ...question, options });
    },
    type: "text",
    id: "riskAssesmentOptions",
  }));
  const data = questions?.map((question, index) => {
    return {
      data: question,
      fields: [
        index + 1,
        question.question,
        capitalFirst(question.type),
        (question.type != 'statement' && question.type != 'statement/Instruction') && <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={question?.required}
            onChange={(e) => onChange(question, !question.required)}
          />
        </div>,
        <div className="d-flex gap-2">
          <Box sx={{ position: "relative" }}>
            {dubIndex != null && dubIndex == index && (
              <Card
                sx={{
                  position: "absolute",
                  top: "30px",
                  left: "0",
                  backgroundColor: "white",
                  zIndex: "99",
                }}
              >
                <MenuItem
                  onClick={() => {
                    onDublicateQuestiuon(
                      {
                        question: question.question,
                        required: question.required,
                        type: question.type,
                        options:question.options
                      },
                      index
                    );
                    setDubIndex(null);
                  }}
                >
                  Under
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDublicateQuestiuon({
                      question: question.question,
                      required: question.required,
                      type: question.type,
                      options:question.options
                    });
                    setDubIndex(null);
                  }}
                >
                  End
                </MenuItem>
              </Card>
            )}
            <ContentCopyIcon
              // className="bi bi-pencil-square text-primary"
              onClick={() => {
                setDubIndex(
                  dubIndex == null || dubIndex != index ? index : null
                );
              }}
              style={{
                cursor: "pointer",
                height: "20px",
                width: "20px",
                color: "green",
              }}
            ></ContentCopyIcon>
          </Box>
          <i
            className="bi bi-pencil-square text-primary"
            onClick={() => onEdit(question, index)}
            style={{ cursor: "pointer" }}
          ></i>
          <i
            onClick={() => onDelete(question)}
            className="bi bi-trash3 text-danger"
            style={{ cursor: "pointer" }}
          ></i>
        </div>,
      ],
    };
  });
  return (
    <Table
      title={"Checklist Questions"}
      labels={labels}
      editIndex={editIndex}
      data={[
        ...data,
        {
          noDrag: true,
          fields: [
            "-",
            <>
              <FormField
                item={{
                  // label: question.type == "statement" ? "Text" : "Question",
                  value: question.question,
                  setValue: (value) =>
                    setQuestion({ ...question, question: value }),
                  type: "text",
                  id: "riskAssesmentTitle",
                }}
              />
              {question.type != "options" &&
              question.type != "Check Box" ? null : (
                <div>
                  <h5 className="card-title">Options</h5>
                  <div className="gap-2 d-flex flex-column mb-2">
                    {optionFields?.map((item, index) => (
                      <div
                        className="d-flex gap-2 align-items-center"
                        key={index}
                      >
                        <i className="text-primary bi bi-record-circle"></i>
                        <FormField key={index} item={item} />
                        <i
                          onClick={() => {
                            const options = question.options?.filter(
                              (item, idx) => idx != index
                            );
                            setQuestion({ ...question, options });
                          }}
                          className="bi bi-trash text-danger"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    ))}
                  </div>
                  <span
                    onClick={() => {
                      const options = question.options
                        ? [...question.options]
                        : [];
                      options.push("");
                      setQuestion({ ...question, options });
                    }}
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                  >
                    + Add More
                  </span>
                </div>
              )}
            </>,
            <FormField
              item={{
                // label: "Question Type",
                value: question.type,
                setValue: (value) => setQuestion({ ...question, type: value }),
                type: "dropdown",
                options: questionTypes,
                placeholder: "Select question type",
              }}
            />,
            (question.type != 'statement' && question.type != 'statement/Instruction') && <FormField
              item={{
                value: question.required,
                setValue: (value) =>
                  setQuestion({ ...question, required: value }),
                type: "boolean",
              }}
            />,
            <button
              onClick={(e) => {
                onAddQuestiuon(
                  e,
                  sortOrder,
                  setSortOrder,
                  editIndex,
                  setEditIndex
                );
              }}
              className="btn btn-primary"
            >
              {editIndex || editIndex == 0 ? "Update" : "Add"}
            </button>,
          ],
        },
      ]}
    />
  );
};

export default QuestionList;
