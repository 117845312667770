import React, { useState } from 'react'
import { PDFDownloadLink } from "@react-pdf/renderer";

export default function Default({ PDF, item, fileName, pdfButton }) {
    const [download, setDownload] = useState(false)
    return (
        <>
            {
                download ? <PDFDownloadLink
                    document={PDF}
                    fileName={fileName}
                >
                    {() => {
                        return (
                            pdfButton ? (pdfButton):<button
                            className="btn btn-sm btn-success"
                        >
                            Open
                        </button>
                            
                        );
                    }}
                </PDFDownloadLink> : (pdfButton ? <div className="text-danger" style={{ cursor: "pointer" }}>
              <i
                className="bi bi-download"
                style={{ fontSize: 20 }}
                onClick={() => setDownload(true)}
              ></i>
            </div> : <button
                    onClick={() => setDownload(true)}
                    className="btn btn-sm btn-success"
                >
                    Download
                </button>)
            }
        </>
    )
}
