import React, { useEffect } from "react";

const Navbar = ({ tab, setTab, isManager, branchID, isAdmin }) => {
  const tabs = [
    { target: "#profile-overview", title: "Overview" },
    { target: "#profile-edit", title: "Edit Profile" },
    // { target: "#profile-form-selection", title: "Selected Checklists" },
    { target: "#profile-manage-permissions", title: "Permissions" },
    ...(isAdmin
      ? []
      : [{ target: "#profile-manage-team", title: "Manage Teams" }]),

    ...(isManager || branchID
      ? []
      : [
        ...(isAdmin
          ? []
          : [{ target: "#profile-manage-branches", title: "Manage Sites" }]),
      ]),
    { target: "#profile-support", title: "Support" },
    // { target: "#profile-comment", title: "Comments" },
  ];

  useEffect(() => {
    if (!tab) setTab(tabs[0].target);
  }, [tab]);

  return (
    <ul className="nav nav-tabs nav-tabs-bordered">
      {tabs?.map((item, index) => (
        <li key={index} className={"nav-item"}>
          <button
            onClick={() => setTab(item.target)}
            className={"nav-link " + (tab == item.target ? "active" : "")}
            data-bs-toggle="tab"
            data-bs-target={item.target}
          >
            {item.title}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Navbar;
