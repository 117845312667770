import React from 'react'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { ddmmyyyy, formatTime, hhmm, compareTimes } from '../../config/functions'

// import useChecklist from '../../hooks/useChecklist'
import useAuth from '../../hooks/useAuth'

import FormField from '../../components/FormField'
import { frequency } from '../../config/constants'
import { ChecklistContext } from '../../hooks/checklistContext'
import { useContext } from 'react'

const ChecklistDetails = ({ data, setData }) => {
    const { categories } = useContext(ChecklistContext)
    const {  isCompany, user } = useAuth();
    const [animationParent] = useAutoAnimate()
    //(data)
    const company = isCompany ? user : data?.companyID;
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        .filter(day => !company ? true : company?.schedule?.includes(day))

    const setTimes = (times) => {
        const arr = times
            ?.map(item => {
                const date = new Date(item);
                const dateTime = new Date(2000, 0, 1);
                dateTime.setHours(date.getHours(), date.getMinutes(), 0, 0);
                return dateTime.getTime()
            })

        setData({
            ...data,
            notificationFrequency: {
                ...data.notificationFrequency,
                times: arr
            }
        })
    }

    const titleField = {
        value: data?.title,
        setValue: (value) => setData({ ...data, title: value }),
        placeholder: 'Enter checklist title',
        type: 'text',
        id: 'checklistTitle'
    }
    const categoryField = {
        type: 'dropdown',
        options: categories,
        placeholder: 'Select checklist category',
        value: data?.categoryID,
        setValue: (value) => setData({ ...data, categoryID: value }),
        id: 'checklistCategory'
    }
    const checklistFrequencyFields = [
        {
            value: data?.checklistFrequency,
            setValue: (value) => setData({ ...data, checklistFrequency: value }),
            type: 'radio',
            options: [...frequency, 'None']
        },
        data?.checklistFrequency != 'Weekly' ? null : {
            placeholder: 'Select Day',
            value: data?.notificationFrequency?.day,
            type: 'dropdown',
            options: days,
            setValue: (value) => {
                setData({
                    ...data,
                    notificationFrequency: { ...data.notificationFrequency, day: value }
                })
            },
        },
        data?.checklistFrequency != 'Monthly' ? null : {
            placeholder: 'Select Day',
            value: data?.notificationFrequency?.day,
            type: 'dropdown',
            options: [...new Array(31)].map((item, index) => `${index + 1}`),
            setValue: (value) => {
                setData({
                    ...data,
                    notificationFrequency: { ...data.notificationFrequency, day: value }
                })
            },
        },
        data?.checklistFrequency != 'Annual' ? null : {
            value: ddmmyyyy(data?.notificationFrequency?.date),
            setValue: (value) => {
                const date = new Date(value)
                setData({ ...data, notificationFrequency: { ...data.notificationFrequency, date: date.getTime() } })
            },
            type: 'date',
            id: 'checklistTitle',
            placeholder: 'Select frequency date'
        },
    ].filter(item => item)

    const times = data?.notificationFrequency?.times;
    const notificationFrequencyFields = times?.map((time, index) => ({
        value: hhmm(time),
        setValue: (value) => {
            const date = new Date()
            date.setHours(parseInt(value.split(':')[0]))
            date.setMinutes(parseInt(value.split(':')[1]))
            const newTime = date.getTime()
            const newArray = times
                ?.map((item, i) => i == index ? newTime : item)
            setTimes(newArray)
        },
        type: 'time',
        id: `checklistTime${index}`,
        placeholder: 'Select frequency time',
        min: hhmm(company?.workStartTime),
        max: hhmm(company?.workEndTime),
    }))

    return (
        <div className='row'>
            {/* Title */}
            <div className="col-md-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Checklist Title</h5>
                        <FormField item={titleField} />
                        <h5 className="card-title">Category</h5>
                        <FormField item={categoryField} />
                    </div>
                </div>
            </div>
            {/* Checklist Frequency */}
            <div className="col-md-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Checklist Frequency</h5>
                        {
                            checklistFrequencyFields?.map((item, index) => (
                                <FormField item={item} key={index} />
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* Notification Frequency */}
            {
                data?.checklistFrequency == 'None' ? null :
                    <div className="col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className="card-title">Set Notification Times</h5>
                                </div>
                                <div className='d-flex flex-column gap-3'>
                                    <ul ref={animationParent} className='ps-0'>
                                        {
                                            notificationFrequencyFields?.map((item, index) => (
                                                <li className='d-flex gap-3 mb-3 align-items-center' key={index}>
                                                    <FormField item={item} />
                                                    {
                                                        index == 0 ? null :
                                                            <i
                                                                onClick={() => setTimes(times?.filter((it, i) => index != i))}
                                                                className="bi bi-trash3 text-danger"
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <span
                                        className='text-primary text-center'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setTimes([...times, Date.now() + (times?.length * 3600000)])}
                                    >
                                        <i className="bi bi-plus-circle"></i> Add More
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ChecklistDetails