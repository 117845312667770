import { Box, Typography } from '@mui/material'
import { useStripe } from '@stripe/react-stripe-js'
import React, { useEffect } from 'react'
import { Card, CardBody } from 'react-bootstrap'
import americanCard from "../../assets/american.png"
import masterCard from "../../assets/master.webp"
import visaCard from "../../assets/visa.png"
import PaymentModel from "../../components/paymentModel"
import Screen from '../../components/Screen'
import useAuth from '../../hooks/useAuth'
import usePaymentMethod from '../../hooks/usePaymentMethod'
import { useSelector } from 'react-redux'
export default function Default() {
    const { paymentMethods } = useSelector(state => state.paymentMethods)
    const {
        loading,
        showPaymentModel,
        setShowPaymentModel,
        error,
        stripeLoading,
        // paymentMethods,
        getPaymentMethods,
        deletePaymentMethod,
        onAddCard
    } = usePaymentMethod()
    const stripe = useStripe();
    const { user } = useAuth()
    useEffect(() => {
        getPaymentMethods()
    }, [])
    return (
        <Screen
            loading={loading}
            flow={[
                { label: "Payment Methods", to: "/payment-method" },
                { label: "Home", to: "/checklists" },
                { label: "Payment Methods", to: "/payment-method" },
            ]}
        >
            <PaymentModel
                show={showPaymentModel}
                setShow={() => setShowPaymentModel(false)}
                header={"Add new card"}
                onSubmit={(e) => onAddCard(e)}
                error={error}
                stripe={stripe}
                stripeLoading={stripeLoading}
                user={user}
                isAdd={true}
            />
            <div className='row'>
                {paymentMethods?.map((item, index) => (
                    <div key={index} className='col-lg-4 col-md-6 col-sm-12'>
                        <Card className="py-3" style={{ border: item.isDefault && '2px solid #0d6efd' }} >
                            <CardBody>
                                {item.isDefault && <center><Typography sx={{ color: '#0d6efd' }}>Active</Typography></center>}
                                <div className='d-flex align-items-center py-2' style={{ borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
                                    <img src={item.card.brand == 'visa' ? visaCard : item.card.brand == 'mastercard' ? masterCard : americanCard} style={{ height: '80px', border: '1px solid rgba(0,0,0,0.1)' }} />
                                    <Box sx={{ marginLeft: '15px' }}>
                                        <Typography><b>{item?.card?.brand?.toUpperCase()} **** </b>{item?.card?.last4}</Typography>
                                        <Typography sx={{ fontSize: '12px' }}><b>Expires </b>{item?.card?.exp_month}/{item?.card?.exp_year}</Typography>
                                    </Box>
                                </div>
                                <div className='d-flex mt-2' style={{ flexDirection: 'row-reverse' }}>
                                    <button onClick={() => deletePaymentMethod(item.id)} className='btn btn-danger'>Delete</button>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                ))}
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <Card className="pt-3" style={{ height: '150px', border: '1px dotted black', cursor: 'pointer' }} onClick={() => setShowPaymentModel(true)}>
                        <CardBody style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography>Add New Card</Typography>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Screen>
    )
}
