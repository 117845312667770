import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import useAuth from '../../hooks/useAuth';
import FormField from '../../components/FormField';
import SubmitButton from './SubmitButton';
import Title from './Title'

const Forgot = () => {


    const location = useLocation();
    // Hooks
    const { loading, onForgot } = useAuth();


    const email = location?.state?.email;
    // States
    const [data, setData] = useState({ email });

    // constants
    const fields = [
        {
            value: data.email,
            setValue: (value) => setData({ ...data, email: value }),
            label: 'Email Address',
            required: true,
            type: 'email',
            className: 'col-12'
        },
    ]

    // Functions
    const onSubmit = (e) => {
        e.preventDefault();
        onForgot(data)
    }

    return (
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex register-container">
                <Title />
                <div className="col-lg-6 col-md-12 d-flex flex-column align-items-center justify-content-center" style={{ paddingRight: 50 }}>
                    <div className="card mb-0">
                        <div className="card-body pt-2 pb-5 ps-5 pe-5">
                            {/* Title */}
                            <div className="pt-4 pb-4">
                                <h5 className="card-title text-center pb-0 fs-4">Forgot your password?</h5>
                                <p className="text-center small">Don't worry, enter your email and you will receive a password reset link in your email.</p>
                            </div>
                            {/* From */}
                            <form className="row g-3" onSubmitCapture={onSubmit}>
                                {
                                    fields?.map((item, index) => (
                                        <FormField item={item} key={index} />
                                    ))
                                }
                                <div className="text-center">
                                    <SubmitButton
                                        title={'Send Link'}
                                        onClick={onSubmit}
                                        loading={loading}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="text-center">
                                    <p className="small mb-0">Recieved reset link? <Link to='/login'>Login here</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Forgot