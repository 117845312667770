import React from 'react'
import Loader from './Loader'

const SubmitButton = ({ iconStyle, onClick, loading, title, icon, color, disabled, width, style, loaderColor, type }) => {
  return (
    <button disabled={disabled || loading} className={`btn btn-${color}`} type={type ? type : "button"} onClick={onClick} style={style}>
      <Loader loading={loading} color={loaderColor}>
        <div className={icon ? `d-flex flex-row justify-content-between align-items-center gap-2` : 'd-flex flex-row justify-content-center'} style={{ width }}>
          {icon}
          {title ? <span>{title}</span> : null}
        </div>
      </Loader>
    </button>
  )
}

export default SubmitButton