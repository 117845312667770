import React, { useEffect } from 'react'
import useCompanies from '../../hooks/useCompanies';
import Loader from '../../components/Loader';
import Table from './Table';
import FormModal from './FormModal';

const Team = ({ company, tab, isManager, branchID }) => {
    const labels = ['#', 'Name', 'Position', 'Site Name', 'Actions'];
    const {
        managers,
        branches,
        loading,
        deleteLoading,
        addLoading,
        data,
        setData,
        fetchEmployees,
        removeEmployee,
        addEmployee,
        changeEmployee,
    } = useCompanies();

    useEffect(() => {
        if (company?._id && tab?.includes('team'))
            fetchEmployees(
                company._id, {
                position: isManager ? 'Supervisor' : '',
                branchID
            })
    }, [company, tab])

    const isUpdate = !!data?._id;
    const existingManager = managers.find(item => item.position == 'Manager');

    const fields = [
        {
            placeholder: 'Member Name',
            value: data?.name,
            setValue: (value) => setData({ ...data, name: value }),
            className: 'col-md-6'
        },
        {
            placeholder: 'Member Email',
            value: data?.email,
            setValue: (value) => setData({ ...data, email: value }),
            className: 'col-md-6'
        },
        isManager ? null : {
            placeholder: 'Select Site...',
            value: data?.branchID,
            setValue: (value) => setData({ ...data, branchID: value }),
            className: 'col-md-6',
            type: 'dropdown',
            options: branches
        },
        {
            placeholder: 'Select Role...',
            value: data?.position,
            setValue: (value) => setData({ ...data, position: value }),
            className: isManager ? 'col-md-12' : 'col-md-6',
            type: 'dropdown',
            options: [
                { _id: 'Supervisor', name: 'Supervisor' },
                { _id: 'Guest', name: 'Guest' },
                // { _id: 'Manager', name: 'Manager' }
                isManager ? null : { _id: 'Manager', name: 'Manager' }
            ].filter(item => item)
        },
    ].filter(item => item)

    return (
        <div className="tab-pane fade pt-3" id="profile-manage-team">
            <div className="table-container">
                <Loader loading={loading} center>
                    <Table
                        labels={labels}
                        data={managers?.map(item => ({
                            cells: [item?.name, item?.position, branches?.find(b => b._id == item.branchID)?.name],
                            data: item
                        }))}
                        onDelete={removeEmployee}
                        onEdit={(item) => setData({ ...item, show: true })}
                        onAdd={() => setData({ show: true })}
                        deleteLoading={deleteLoading}
                    />
                </Loader>
            </div>
            <FormModal
                show={data.show == true}
                title={isUpdate ? 'Update Member' : 'New Member'}
                onSubmit={isUpdate ? () => changeEmployee(company?._id) : () => addEmployee(company?._id)}
                fields={fields}
                onClose={() => setData({ ...data, show: false })}
                loading={addLoading}
            />
        </div>
    )
}

export default Team