import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import useCompanies from '../../hooks/useCompanies'

import Loader from '../../components/Loader'
import Screen from '../../components/Screen'
import Table from '../../components/Table'
import CompanyModal from './CompanyModal'
import useChat from '../../hooks/useChat'
import useAuth from '../../hooks/useAuth'

const Companies = () => {
    const {
        deleteLoading,
        loading,
        companies,
        page,
        limit,
        setPage,
        fetchCompanies,
        setData,
        removeCompany,
        changeCompany,
        downloadExcel
    } = useCompanies();
    const { addLoading, newChat } = useChat();
    const { user } = useAuth();

    const [show, setShow] = useState(false);
    const hanldeShow = () => setShow(true)
    const hanldeClose = () => setShow(false)
const {isFellowAdmin} = useAuth()
    const changeStatus = (item) => {
        changeCompany(undefined, { ...item, status: !item.status });
    }

    const labels = [
        '#',
        'Email Address',
        'Company Name',
        'Industry',
        'Director Name',
        'N.o of Employees',
        'Subscription',
        'Telephone',
        'Status',
        'Actions',
    ];
    const data = companies
        ?.map((item) => {
             return {
                data: item,
                fields: [
                    item?._id.substr(item?._id.length - 5),
                    item.email,
                    item.name,
                    item.nature?.name,
                    item.adminName,
                    item.employees,
                    item.subscriptionID?.name,
                    item.telephone1,
                    <div className='form-check form-switch'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            checked={item.status == undefined ? false : item.status}
                            onChange={() => changeStatus(item)}
                        />
                    </div>,
                    <div className='d-flex gap-3'>
                        <Link to={`/profile?companyID=${item._id}`} state={item} >
                            <i onClick={() => setData(item)} className="bi bi-pencil-square text-primary"></i>
                        </Link>
                        <Loader loading={addLoading?._id == item._id} color='blue'>
                            <i onClick={() => newChat(item, {
                                users: [
                                    user?._id,
                                    item._id
                                ]
                            })} className="bi bi-chat-dots text-primary" style={{ cursor: 'pointer' }}></i>
                        </Loader>
                        <Loader loading={deleteLoading?._id == item._id} color='red'>
                            <i onClick={() => removeCompany(item)} className="bi bi-trash text-danger" style={{ cursor: 'pointer' }}></i>
                        </Loader>
                    </div>
                ]
            }
        })
    const fetchCompaniesWithPagination = ()=>fetchCompanies({ page, limit })
    useEffect(() => {
        fetchCompaniesWithPagination();
    }, [page]);

    return (
        <Screen loading={loading} flow={[
            { label: 'Companies', to: '/companies' },
            { label: 'Home', to: '/' },
            { label: 'Companies', to: '/companies' }
        ]}>
            <div className='d-flex justify-content-end align-items-center mb-3 gap-2'>
                {!isFellowAdmin && <button className='btn btn-primary' onClick={hanldeShow}>
                    Create Company
                </button>}

                {/* <div className='text-success' style={{ cursor: 'pointer' }} onClick={() => downloadExcel()}>
                    <i className="bi bi-file-excel-fill" style={{ fontSize: 20 }}></i>
                </div> */}
                <button className='btn btn-success' onClick={() => downloadExcel()}>
                    <i className="bi bi-download"></i>
                </button>
            </div>
            <Table
                title={'Registered Companies'}
                data={data}
                labels={labels}
                limit={limit}
                page={page}
                setPage={setPage}
            />
            <CompanyModal
                handleClose={hanldeClose}
                show={show}
                fetchCompaniesWithPagination={fetchCompaniesWithPagination}
            />
        </Screen>
    )
}

export default Companies