import { useState } from "react";
import api from "../app/api";
import { keys } from "../config/keys";
import useAlert from "./useAlert";
import { useDispatch } from "react-redux";
import { removeSubscription, setSubscriptions } from "../features/subscription/slice";
import { DeleteAlert } from "../components/Swal Alert";
import { useNavigate } from "react-router-dom";
export default () => {
    const [loading, setloading] = useState(false);
    const [deleteLoading, setdeleteLoading] = useState(null);
    const navigator = useNavigate()
    const [data, setData] = useState({
        validity: "",
        allowedInbox: "",
        companyID: "",
        additionalFeatures: [
            {
                feature: "",
                allocation: "",
            },
        ],
    });
    const [filter, setfilter] = useState({
        company: "",
        admin:""
    });
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const dispatch = useDispatch();
    const { showAlert } = useAlert();
    const getDynamicData = async () => {
        setloading(true);
        try {
            let response = await api.post(`/dynamic-subscription/getAllSubscription`, { ...filter, page, limit });
            if (response.data?.status) {
                dispatch(setSubscriptions(response.data?.result));
            }
        } catch (error) {
        } finally {
            setloading(false);
        }
    };
    const onAddCompanySubscription = async () => {
        setloading("new");
        try {
            const response = await api.post(
                `${keys.BASE_LOCAL_URL}/dynamic-subscription/createCompanySubscription`,
                { ...data, name: "custom-" + data.name }
            );
            if (response.data.status) {
                showAlert("Subscription added successfully.", "success");
                setData({
                    validity: "",
                    allowedInbox: "",
                    companyID: "",
                    additionalFeatures: [
                        {
                            feature: "",
                            allocation: "",
                        },
                    ],
                })
                navigator("/subscription-list")
            } else {
                showAlert(response.data.message, "danger");
            }
        } catch (error) {
            console.log(error.message);
            showAlert(error.message, "danger");
        } finally {
            setloading(false);
        }
    };
    const onUpdateCompanySubscription = async () => {
        setloading("new");
        try {
            const response = await api.put(
                `${keys.BASE_LOCAL_URL}/dynamic-subscription/updateCompanySubscription`,
                { ...data, name: data.name?.includes("custom-") ? data.name : "custom-" + data.name }
            );
            if (response.data.status) {
                showAlert("Subscription updated successfully.", "success");
                setData({
                    validity: "",
                    allowedInbox: "",
                    companyID: "",
                    additionalFeatures: [
                        {
                            feature: "",
                            allocation: "",
                        },
                    ],
                })
                navigator("/subscription-list")
            } else {
                showAlert(response.data.message, "danger");
            }
        } catch (error) {
            console.log(error.message);
            showAlert(error.message, "danger");
        } finally {
            setloading(false);
        }
    };
    const onUpdateAdminSubscription = async () => {
        setloading("new");
        try {
            const response = await api.put(
                `${keys.BASE_LOCAL_URL}/dynamic-subscription/updateAdminSubscription`,
                { ...data, name: data.name?.includes("custom-") ? data.name : "custom-" + data.name }
            );
            if (response.data.status) {
                showAlert("Subscription updated successfully.", "success");
                setData({
                    validity: "",
                    allowedInbox: "",
                    adminID: "",
                    additionalFeatures: [
                        {
                            feature: "",
                            allocation: "",
                        },
                    ],
                })
                navigator("/subscription-list")
            } else {
                showAlert(response.data.message, "danger");
            }
        } catch (error) {
            showAlert(error.message, "danger");
        } finally {
            setloading(false);
        }
    };
    const onAddAdminSubscription = async () => {
        setloading("new");
        try {
            const response = await api.post(
                `${keys.BASE_LOCAL_URL}/dynamic-subscription/createAdminSubscription`,
                { ...data, name: "custom-" + data.name }
            );
            if (response.data.status) {
                showAlert("Subscription added successfully.", "success");
                setData({
                    validity: "",
                    allowedInbox: "",
                    adminID: "",
                    additionalFeatures: [
                        {
                            feature: "",
                            allocation: "",
                        },
                    ],
                })
                navigator("/subscription-list")
            } else {
                showAlert(response.data.message, "danger");
            }
        } catch (error) {
            showAlert(error.message, "danger");
        } finally {
            setloading(false);
        }
    };
    const deleteSubscription = async (id)=>{
        if (!(await DeleteAlert())) return;
        setdeleteLoading(id)
        try {
            let response = await api.delete(`/dynamic-subscription/deleteSubscription?_id=${id}`);
            console.log(response)
            if (response.data?.status) {
                showAlert(response?.data?.message, "success")
                dispatch(removeSubscription(response.data?.result));
            }else{
                showAlert(response?.data?.message, "danger")
            }
        } catch (error) {
            
        }finally{
            setdeleteLoading(null)
        }
    }
    return {
        loading,
        setloading,
        page,
        setPage,
        limit,
        setLimit,
        filter,
        setfilter,
        data,
        setData,
        onAddCompanySubscription,
        onAddAdminSubscription,
        getDynamicData,
        deleteSubscription,
        deleteLoading,
        onUpdateCompanySubscription,
        onUpdateAdminSubscription
    };
};
