import { createSlice } from "@reduxjs/toolkit";

const fellowAdminSlice = createSlice({
  name: "fellowAdmins",
  initialState: {
    fellowAdmins: []
  },
  reducers: {
    setFellowAdmin: (state, action) => {
      state.fellowAdmins = action.payload;
    },
    updateFellowAdmin: (state, action) => {
      state.fellowAdmins = state.fellowAdmins.map(item => item._id == action.payload._id ? action.payload : item);
    },
    deleteFellowAdmin: (state, action) => {
      state.fellowAdmins = state.fellowAdmins.filter(item => item._id != action.payload._id);
      state.fellowAdmins = state.fellowAdmins.map(item => item.name == 'Default' ? { ...item, checklists: action.payload.checklists + item.checklists } : item);
    },
    addFellowAdmin: (state, action) => {
      state.fellowAdmins = [...state.fellowAdmins, { ...action.payload, checklists: 0 }];
    },
  },
});

export const { setFellowAdmin, updateFellowAdmin, deleteFellowAdmin, addFellowAdmin } = fellowAdminSlice.actions;

export default fellowAdminSlice.reducer;
