import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import api from "../app/api";
import useAlert from "./useAlert";
import { DeleteAlert, UpdateAlert } from "../components/Swal Alert/index";

import { set, add, remove, update } from "../features/companies/slice";
import useAuth from "./useAuth";

export default () => {
  const dispatch = useDispatch();
  const { companies, branches, managers } = useSelector(
    (state) => state.company
  );
  const { showAlert } = useAlert();
  const { isManager } = useAuth();
  const [tab, setTab] = useState();
  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState({
    email: "",
    name: "",
    nature: "",
    position: "",
    adminName: "",
    adminEmail: "",
    employees: 0,
    website: "",
    telephone1: "",
    telephone2: "",
    address: "",
  });
  const [permissions, setPermissions] = useState();

  // Companies
  const fetchCompanyById = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`/company/${id}`);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
      setLoading(false);
    }
  };
  const fetchCompanies = async (params) => {
    setLoading(true);
    try {
      let response;
      if (params) {
        response = await api.get(`/company`, { params });
      } else {
        response = await api.get(`/company`);
      }
      if (response.data.length < 1 && params.page > 1) {

      } else {
        dispatch(set({ data: response?.data, type: "companies" }));
      }
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
      setLoading(false);
    }
  };
  const fetchUsers = async () => {
    setLoading(true);
    try {
      let response;
      response = await api.get(
        `/chat/getUsersForChat?companyID=${isManager
          ? localStorage.getItem("companyID")
          : localStorage.getItem("_id")
        }`
      );
      dispatch(set({ data: response?.data, type: "companies" }));
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
      setLoading(false);
    }
  };
  const changeCompany = async (error, item) => {
    if (!item && !(await UpdateAlert())) return;
    setAddLoading(data);
    try {
      if (error) throw error;
      if (item) setData(item);
      const formData = new FormData();

      Object.keys(data).map((key) => {
        if (key != "schedule") {
          formData.append(
            key,
            data[key]?.name && (!key.includes("image") && !key.includes("signaturee"))
              ? data[key]?._id
              : data[key]
          );
        }
      });
      if (item) {
        delete item.imageURL;
      }

      const response = await api.put(
        `/company/update/${item ? item._id : data._id}`,
        item ? item : formData
      );
      dispatch(update({ data: response.data, type: "companies" }));
      showAlert("Updated company successfully", "success");
      setAddLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(message, "danger");
      setAddLoading(false);
    }
  };
  const removeCompany = async (item) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(item);
    try {
      const response = await api.delete(`/company/delete/${item._id}`);
      dispatch(remove({ data: item, type: "companies" }));
      setDeleteLoading(false);
      showAlert("Deleted company successfully", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setDeleteLoading(false);
      showAlert(message, "danger");
      setDeleteLoading(false);
    }
  };
  const sendMail = async (data, id, role) => {
    try {
      let response;
      if (role) {
        response = await api.post(`/company/contact/${id}?role=${role}`, data);
      } else {
        response = await api.post(`/company/contact/${id}`, data);
      }
      showAlert(response.data.message, "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const downloadExcel = async () => {
    const type =
      "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const send = (companies || companies?.length > 0 ? companies : []).map(
      (item) => ({
        "#": item?._id.substr(item?._id.length - 5),
        "Email Address": item?.email,
        "Company Name": item?.name,
        "Industry": item?.nature?.name,
        "Manager Email": item?.adminEmail,
        "N.o of Employees": item?.employees,
        Address: item?.address,
        Telephone: item?.telephone1,
      })
    );

    const fileName = "Companies-CRM";

    const ws = XLSX.utils.json_to_sheet(send);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileData = new Blob([excelBuffer], { type });
    FileSaver.saveAs(fileData, fileName + fileExtension);
  };

  // Branches
  const fetchBranches = async (companyID, branchID) => {
    setLoading(true);
    try {
      const response = await api.get(`/company/branch/${companyID}?branch=${branchID ? branchID : ''}`);
      dispatch(set({ data: response.data, type: "branches" }));
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const removeBranch = async (item) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(item);
    try {
      const response = await api.delete(
        `/company/branch/delete/${item.companyID}/${item._id}`
      );
      dispatch(remove({ data: item, type: "branches" }));
      setDeleteLoading(false);
      showAlert("Deleted Site successfully", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setDeleteLoading(false);
      showAlert(message, "danger");
      setDeleteLoading(false);
    }
  };
  const addBranch = async (companyID) => {
    setAddLoading(true);
    try {
      const response = await api.post(`/company/branch/new/${companyID}`, data);
      await fetchBranches(companyID);
      // dispatch(add({ data: response.data, type: 'branches' }))
      setAddLoading(false);
      showAlert("Added Site successfully", "success");
      setData({ show: false });
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddLoading(false);
      showAlert(message, "danger");
    }
  };
  const changeBranch = async (companyID) => {
    if (!(await UpdateAlert())) return;
    setAddLoading(true);
    try {
      if (isManager) {
        delete data.managerID;
      }
      const response = await api.put(
        `/company/branch/update/${companyID}/${data._id}`,
        data
      );
      await fetchBranches(companyID);
      setAddLoading(false);
      showAlert("Updated Site successfully", "success");
      // setData({ show: false });
      setTab("#profile-overview");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddLoading(false);
      showAlert(message, "danger");
    }
  };

  // Managers
  const fetchEmployees = async (companyID, params, route) => {
    setLoading(true);
    try {
      const response = await api.get(`/company/employee/${companyID}?`, {
        params,
      });
      dispatch(set({ data: response.data, type: "managers" }));
      if (route != '/risk-assesments' && route != '/checklists' && route != '/audits') setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };
  const removeEmployee = async (item) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(item);
    try {
      const response = await api.delete(
        `/company/employee/delete/${item.companyID}/${item._id}`
      );
      dispatch(remove({ data: item, type: "managers" }));
      setDeleteLoading(false);
      showAlert("Deleted Manager successfully", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(message, "danger");
      setDeleteLoading(false);
    }
  };
  const addEmployee = async (companyID) => {
    setAddLoading(true);
    try {
      const response = await api.post(
        `/company/employee/new/${companyID}`,
        data
      );
      await fetchEmployees(companyID);
      // dispatch(add({ data: response.data, type: 'managers' }))
      setAddLoading(false);
      showAlert("Login credentials sent via email", "success");
      setData({ show: false });
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddLoading(false);
      showAlert(message, "danger");
    }
  };
  const changeEmployee = async (companyID) => {
    if (!(await UpdateAlert())) return;
    setAddLoading(true);
    try {
      const response = await api.put(
        `/company/employee/update/${companyID}/${data._id}`,
        data
      );
      await fetchEmployees(companyID);
      // dispatch(update({ data: response.data, type: 'managers' }))
      setAddLoading(false);
      showAlert("Updated Manager successfully", "success");
      setData({ show: false });
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddLoading(false);
      showAlert(message, "danger");
    }
  };

  // Permissions
  const fetchPermissions = async (params) => {
    try {
      const response = await api.get(`/permissions`, { params });
      setPermissions(response.data);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(message, "danger");
    }
  };
  const changePermissions = async (params, data) => {
    setLoading(true);

    try {
      const response = await api.put(
        `/permissions/update`,
        data ? data : permissions,
        { params }
      );
      setLoading(false);
      showAlert("Permissions updated successfully.", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };

  return {
    companies,
    branches,
    managers,
    permissions,
    page,
    limit,
    loading,
    setLoading,
    deleteLoading,
    addLoading,
    data,
    fetchUsers,

    setData,
    setPage,
    setPermissions,

    fetchCompanyById,
    fetchCompanies,
    changeCompany,
    removeCompany,
    downloadExcel,

    fetchBranches,
    removeBranch,
    addBranch,
    changeBranch,

    fetchEmployees,
    removeEmployee,
    addEmployee,
    changeEmployee,

    fetchPermissions,
    changePermissions,

    sendMail,

    tab,
    setTab,
  };
};
