import { Box, Card, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Screen from "../../components/Screen";
import SubscriptionBullet from "../../components/subscriptionBullet";
import useAuth from "../../hooks/useAuth";
import useSubscription from "../../hooks/useSubscription";
import DoneIcon from '@mui/icons-material/Done';
import { useStripe } from "@stripe/react-stripe-js";
import PaymentModel from "../../components/paymentModel";
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from "react-router-dom";
import usePaymentMethod from "../../hooks/usePaymentMethod";
import Loader from "../../components/Loader";
// import "./index.css";
export default function Default() {
  const { subscriptions } = useSelector((state) => state.subscription);
  const { paymentMethods } = useSelector(state => state.paymentMethods)
  const stripe = useStripe();
  const { user, isCompanyAdmin } = useAuth();

  const [query] = useSearchParams()
  const {
    getPaymentMethods,
  } = usePaymentMethod()
  let _id = query.get('id')
  const {
    getData,
    stripeLoading,
    filter,
    setfilter,
    loading,
    error,
    setError,
    showPaymentModel,
    setShowPaymentModel,
    handleSubmit,
  } = useSubscription();
  useEffect(() => {
    getData(true, _id);
    setError(null);
    getPaymentMethods()
  }, []);
  const [view, setView] = useState('monthly'); // Default view
  const [showCurrent, setshowCurrent] = useState(true)
  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView); // Set the view to either 'monthly' or 'yearly'
    }
  };
  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setfilter}
      flow={[
        { label: "Subscriptions", to: "/subscription" },
        { label: "Home", to: "/" },
        { label: "Subscriptions", to: "/subscription" },
      ]}
    >
      {(!_id && user.subscriptionID?.name != 'Basic' && showCurrent && user.subscriptionID?.name != 'BASIC +' && user.subscriptionID?.name != 'BRONZE' && user.subscriptionID?.name != 'SILVER' && user.subscriptionID?.name != 'GOLD') && <>
        <center style={{ backgroundColor: '#FFFFE0', padding: '7px 0px', borderRadius: '10px', border: '1px solid #FFD700' }}><Typography><b>Note: </b> Your plan is management by super admin. Contact super admin to change your plan.</Typography></center><br />
      </>}
      {paymentMethods.length < 1 && <PaymentModel
        show={showPaymentModel}
        setShow={() => setShowPaymentModel(false)}
        header={"Card Payment"}
        // onSubmit={makePayment}
        onSubmit={handleSubmit}
        error={error}
        stripe={stripe}
        stripeLoading={stripeLoading}
        user={user}
        _id={_id}
      />}
      {!showCurrent && !_id && <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="view-toggle"
        >
          <ToggleButton
            value="monthly"
            aria-label="monthly"
            sx={{
              borderRadius: '50px',  // Oval shape
              padding: '8px 20px',   // Make the button larger
              '&.Mui-selected': {
                backgroundColor: '#0d6efd',  // Blue background for selected tab
                color: 'white',            // White text for selected tab
              },
              '&:not(.Mui-selected)': {
                backgroundColor: '#f0f0f0',  // Light gray background for unselected
                color: 'black',              // Default text color for unselected tab
              },
              transition: 'background-color 0.3s ease', // Smooth transition for background color change
            }}
          >
            Monthly
          </ToggleButton>
          <ToggleButton
            value="yearly"
            aria-label="yearly"
            sx={{
              borderRadius: '50px',  // Oval shape
              padding: '8px 20px',   // Make the button larger
              '&.Mui-selected': {
                backgroundColor: '#0d6efd',  // Blue background for selected tab
                color: 'white',            // White text for selected tab
              },
              '&:not(.Mui-selected)': {
                backgroundColor: '#f0f0f0',  // Light gray background for unselected
                color: 'black',              // Default text color for unselected tab
              },
              transition: 'background-color 0.3s ease', // Smooth transition for background color change
            }}
          >
            Yearly (Get 2 Months free)
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>}

      {(showCurrent && !_id) && <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
        <Typography variant="h5">My Current Plan</Typography>
      </Box>}
      {(showCurrent && !_id) && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card
          sx={{
            borderRadius: "10px",
            border: "3px solid #0d6efd",
            width: {
              xs: '90%',
              sm: '90%',
              md: '40%',
              lg: '40%'
            }
          }}
          className="aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <Box sx={{ padding: "35px 30px 20px 5px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 className="title" style={{ color: "#0d6efd" }}>
                {user.subscriptionID.name}
              </h4>
            </Box>
            {!isCompanyAdmin ? <ul>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Feature</Typography>
                <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Allowance</Typography>
              </Box>
              {Object.keys(user.subscriptionID).map((item1) => {
                if (
                  item1 != "_id" &&
                  item1 != "name" &&
                  item1 != "cost" &&
                  item1 != "updatedAt" &&
                  item1 != "createdAt" &&
                  item1 != "priceID" &&
                  item1 != "validity" &&
                  item1 != "discountPercent" &&
                  item1 != "createdBy" &&
                  item1 != "status" &&
                  item1 != "companyID" &&
                  item1 != "__v" &&
                  item1 != "additionalFeatures"
                ) {
                  return (
                    <SubscriptionBullet
                      itemKey={item1}
                      value={user.subscriptionID[item1]}
                    />
                  );
                }
              })}
              {user.subscriptionID?.additionalFeatures?.length > 0 && user.subscriptionID?.additionalFeatures?.map((item1, index) => (
                    <SubscriptionBullet
                      key={index}
                      itemKey={item1.feature}
                      value={item1.allocation}
                    />
                  ))}
              <SubscriptionBullet
                itemKey={"Chat"}
                value={user.subscriptionID?.allowedInbox ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
              />
              <SubscriptionBullet
                itemKey={"Management System"}
                value={user.subscriptionID?.allowedManagementSystem ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
              />
              <SubscriptionBullet
                itemKey={"Access to Checklist Templates"}
                value={user.subscriptionID?.allowedCLTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
              />
              <SubscriptionBullet
                itemKey={"Access to Policy Templates"}
                value={user.subscriptionID?.allowedPOTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
              />
              <SubscriptionBullet
                itemKey={"Access to RA Templates"}
                value={user.subscriptionID?.allowedRATemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
              />
              <SubscriptionBullet
                itemKey={"Access to Audit Templates"}
                value={user.subscriptionID?.allowedATTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
              />
            </ul> :
              <ul>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Feature</Typography>
                  <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Allowance</Typography>
                </Box>
                {user?.subscriptionID?.additionalFeatures?.map((item1, index) => (
                  <SubscriptionBullet
                    key={index}
                    itemKey={item1.feature}
                    value={item1.allocation}
                  />
                ))}
              </ul>}
          </Box>
          <Box sx={{ padding: "10px 20px 35px 20px" }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography
                sx={{
                  color: "#0d6efd",
                  fontWeight: "bold",
                }}
              >
                {"Valid Till: "}
              </Typography>
              <Typography
                sx={{
                  color: "#0d6efd",
                  paddingLeft: '5px'
                  // fontWeight: "bold",
                }}
              >
                {` ${user.endDate?.substring(0, 10)}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className={`btn btn-sm rounded-5 btn-outline-primary px-5`}
                disabled={(user.subscriptionID?.name != 'Basic' && user.subscriptionID?.name != 'BASIC +' && user.subscriptionID?.name != 'BRONZE' && user.subscriptionID?.name != 'SILVER' && user.subscriptionID?.name != 'GOLD')}
                onClick={() => {
                  setshowCurrent(false)
                  // setSelectedSubscription(item);
                }}
              >
                Change Plan
              </button>
            </Box>
          </Box>
        </Card>
      </Box>}

      {((!showCurrent && !_id) || (_id)) && <Grid container spacing={2}>
        {subscriptions?.map((item, index) => (
          (item.validity == view || _id) && <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
            <Card
              sx={{
                borderRadius: "10px",
                border:
                  user.subscriptionID?._id == item._id && "3px solid #0d6efd",
              }}
              className="aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <Box sx={{ padding: "35px 30px 20px 5px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4 className="title" style={{ color: "#0d6efd" }}>
                    {item.name}
                  </h4>
                </Box>
                {!isCompanyAdmin ? <ul>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Feature</Typography>
                    <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Allowance</Typography>
                  </Box>
                  {Object.keys(item).map((item1) => {
                    if (
                      item1 != "_id" &&
                      item1 != "name" &&
                      item1 != "cost" &&
                      item1 != "updatedAt" &&
                      item1 != "createdAt" &&
                      item1 != "priceID" &&
                      item1 != "validity" &&
                      item1 != "discountPercent" &&
                      item1 != "additionalFeatures" &&
                      item1 != "createdBy" &&
                      item1 != "status" &&
                      item1 != "companyID" &&
                      item1 != "__v"
                    ) {
                      return (
                        <SubscriptionBullet
                          itemKey={item1}
                          value={item[item1]}
                        />
                      );
                    }
                  })}
                  <SubscriptionBullet
                    itemKey={"Chat"}
                    value={item.allowedInbox ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                  />
                  <SubscriptionBullet
                    itemKey={"Management System"}
                    value={item.allowedManagementSystem ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                  />
                  <SubscriptionBullet
                    itemKey={"Access to Checklist Templates"}
                    value={item.allowedCLTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                  />
                  <SubscriptionBullet
                    itemKey={"Access to Policy Templates"}
                    value={item.allowedPOTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                  />
                  <SubscriptionBullet
                    itemKey={"Access to RA Templates"}
                    value={item.allowedRATemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                  />
                  <SubscriptionBullet
                    itemKey={"Access to Audit Templates"}
                    value={item.allowedATTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                  />
                  
                </ul> : <ul>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Feature</Typography>
                    <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Allowance</Typography>
                  </Box>
                  {item.additionalFeatures?.map((item1, index) => (
                    <SubscriptionBullet
                      key={index}
                      itemKey={item1.feature}
                      value={item1.allocation}
                    />
                  ))}
                </ul>}
              </Box>
              <Box sx={{ padding: "10px 20px 35px 20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#0d6efd",
                    fontWeight: "bold",
                  }}
                >
                  Cost: £{item.cost} / Month {item.validity == 'yearly' && '(2 Months free)'}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={`btn btn-sm rounded-5 btn${user.subscriptionID?._id == item._id ? "-outline" : ""
                      }-primary px-5`}
                    disabled={(user.subscriptionID?._id == item._id) || (user.subscriptionID?.name != 'Basic' && user.subscriptionID?.name != 'BASIC +' && user.subscriptionID?.name != 'BRONZE' && user.subscriptionID?.name != 'SILVER' && user.subscriptionID?.name != 'GOLD' && !_id)}
                    onClick={(e) => {
                      if (paymentMethods.length < 1) {
                        setShowPaymentModel(true);

                      } else {
                        handleSubmit(e, user, _id, item)
                      }
                      // setSelectedSubscription(item);
                    }}
                  >
                    <Loader loading={stripeLoading} color={'white'}>

                      {user.subscriptionID?._id == item._id
                        ? "Current Plan" : _id ? 'Pay Now'
                          : "Change Plan"}
                    </Loader>
                  </button>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>}
    </Screen>
  );
}
