// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-tag {
    position: absolute;
    top:0;
    left:20px;
}
`, "",{"version":3,"sources":["webpack://./src/features/documents/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,KAAK;IACL,SAAS;AACb","sourcesContent":[".admin-tag {\n    position: absolute;\n    top:0;\n    left:20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
