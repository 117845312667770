import React from "react";
import useAlert from "../../hooks/useAlert";

const Alert = () => {
  const { type, show, body, dismissAlert } = useAlert();

  const icon = () => {
    switch (type) {
      case "primary":
        return <i className="bi bi-star me-1"></i>;
      case "secondary":
        return <i className="bi bi-collection me-1"></i>;
      case "warning":
        return <i className="bi bi-exclamation-triangle me-1"></i>;
      case "info":
        return <i className="bi bi-info-circle me-1"></i>;
      case "dark":
        return <i className="bi bi-folder me-1"></i>;
      case "success":
        return <i className="bi bi-check-circle me-1"></i>;
      case "danger":
        return <i className="bi bi-exclamation-octagon me-1"></i>;
      default:
        return;
    }
  };

  const className = `alert alert-dismissible ${!type ? '' : `alert-${type}`} ${show ? "fade show" : ""}`

  return (
    <div
      className="alert-container d-flex w-100 ps-5 pe-5 justify-content-center pt-2"
      style={{
        position: "fixed",
        top: "90%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: 'none'
      }}
    >
      <div
        className={className}
        style={{ pointerEvents: 'all' }}
      >
        {icon(type)}
        {body}
        {
          !show ? null :
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={dismissAlert}
            />
        }
      </div>
    </div>
  );
};

export default Alert;
