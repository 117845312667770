import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"

import api from "../app/api";
import useAlert from "./useAlert";
import Alert, { DeleteAlert, UpdateAlert } from '../components/Swal Alert/index';

import { setAdmin, addAdmin, updateAdmin, deleteAdmin } from '../features/admin/slice';

export default () => {
  const dispatch = useDispatch();
  const { admins } = useSelector(state => state.admin)

  const { showAlert } = useAlert()

  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  // New Admin State
  const [data, setData] = useState({
    name: '',
  })
  const fetchAdmins = async (params) => {
    setLoading(true);
    try {
      const response = await api.get(`/admins`, { params });
      dispatch(setAdmin(response.data))
      setLoading(false);
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setLoading(false);
    }
  }
  const newAdmin = async () => {
    setAddLoading(true);
    try {
      const response = await api.post('/admins/new', data);
      dispatch(addAdmin(response.data.data))
      setAddLoading(false);
      setData({ name: '', email: '', status: false })
      showAlert(response.data.message, 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      Alert('error', message);
      // showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const changeAdmin = async () => {
    if (!await UpdateAlert()) return
    setAddLoading(true);
    try {
      const response = await api.put(`/admins/update/${data._id}`, data);
      dispatch(updateAdmin(data))
      setData({ name: '', email: '', status: false })
      setAddLoading(false);
      showAlert('Admin updated successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const removeAdmin = async (item) => {
    if (!await DeleteAlert()) return
    setDeleteLoading(item);
    try {
      const response = await api.delete(`/admins/delete/${item ? item._id : data._id}`);
      dispatch(deleteAdmin(item))
      setDeleteLoading(false);
      showAlert('Admin removed successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setDeleteLoading(false);
    }
  }

  return {
    admins,
    loading,
    addLoading,
    deleteLoading,
    data,
    setData,
    fetchAdmins,
    newAdmin,
    changeAdmin,
    removeAdmin,
  }
}