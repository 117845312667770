import React, { useContext, useEffect, useState } from "react";
import Screen from "../../components/Screen";
import Sidebar from "./Sidebar";
import Messages from "./Messages";
import BroadCasts from "./broadCast";
import useBroadcast from "../../hooks/useBroadcast";
import appContext from "../../app/contextState/context";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import useAuth from "../../hooks/useAuth";
const Chat = () => {
  const { showMessageSidebar, setShowMessageSidebar } = useContext(appContext);
  const {user,isAdmin} = useAuth()
  const [chat, setChat] = useState();
  const [pageState, setpageState] = useState("inbox");
  const [query] = useSearchParams();
  const chatID = query.get("id");
  const { broadcasts } = useBroadcast();
  useEffect(() => {
    if (!chatID) {
      setShowMessageSidebar(true);
    } else {
      setShowMessageSidebar(false);
    }
  }, []);

  return (
    <Screen
      flow={[
        { label: "Inbox", to: "/inbox" },
        { label: "Home", to: "/" },
        { label: "Inbox", to: "/inbox" },
      ]}
      allowed={user?.subscriptionID?.allowedInbox != undefined && !user?.subscriptionID?.allowedInbox && !isAdmin}
    >
      <div className="row">
        {showMessageSidebar && (
          <Sidebar
            setChat={setChat}
            setpageState={setpageState}
            pageState={pageState}
          />
        )}
        {!showMessageSidebar &&
          (pageState == "inbox" ? (
            <Messages chat={chat} pageState={pageState} />
          ) : (
            <BroadCasts chat={broadcasts} />
          ))}
      </div>
    </Screen>
  );
};

export default Chat;
