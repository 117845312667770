import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Pagination from "../../components/Pagination";

import Screen from "../../components/Screen";
import ChecklistCard from "../../components/ChecklistCard";
import DownloadPopup from '../../components/DownloadPopup'
import EmptyData from "../../components/EmptyData";

import useRecords from "../../hooks/useRecords";
import useAuth from "../../hooks/useAuth";

const Tasks = () => {
  const location = useLocation();
  const { records, loading, limit, page, fetchRecords, setPage } = useRecords();
  const { isCompany, isAdmin } = useAuth();
  const [filter, setFilter] = useState({
    company: '',
    branch: '',
    isRiskAssesment: 'checklist'
  })
  const [checklist, setChecklist] = useState();
  const frequency = location?.search.split('?frequency=')[1];
  const params = {
    page,
    limit,
    ...(frequency == 'All'
      ? { all: true }
      : { checklistFrequency: frequency }
    ),
    company: filter.company,
    branch: filter.branch,
    isRiskAssesment: filter.isRiskAssesment
    // all: true
  }

  useEffect(() => {
    setPage(1)
    fetchRecords(params)
  }, [filter]);
  useEffect(() => {
    fetchRecords(params)
  }, [filter.page, location]);
  useEffect(() => {
    setPage(1)
  }, [frequency]);

  return (
    <Screen
      loading={loading}
      filter={filter} setFilter={setFilter}
      showBranchFilter={isAdmin || isCompany}
      flow={[
        { label: `${frequency} Tasks` },
        { label: "Home", to: "/" },
        {
          label: `${frequency} Tasks`,
          to: `/tasks${location?.search}`,
        },
      ]}
    >
      <section className="section" id="checklist">
        <EmptyData data={records} onReload={() => fetchRecords(params)}>
          <div className="row icon-boxes">
            {records?.map((item, index) => (
              <ChecklistCard data={item} key={index} type={"task"} search={location?.search} onClick={() => setChecklist(item)} />
            ))}
          </div>
        </EmptyData>
        <Pagination data={records} limit={limit} page={page} setPage={setPage} />
      </section>
      <DownloadPopup
        checklist={checklist}
        branch={filter.branch}
        handleClose={() => setChecklist(undefined)}
        isSubmit={true}
        isRiskAssesment={filter.isRiskAssesment}
      />
    </Screen>
  );
};

export default Tasks;