import React, { useContext, useEffect } from 'react'

import Loader from '../../components/Loader'
import ChecklistCard from '../../components/ChecklistCard'


import appContext from '../../app/contextState/context'
import { ChecklistContext } from '../../hooks/checklistContext'

const Checklist = ({ company, tab, branchID }) => {
    const state = useContext(appContext)
    const{ loading } = state;
    const {  collection, fetchChecklistByCompanyId } = useContext(ChecklistContext)

    useEffect(() => {
        if (company && tab?.includes('selection')) fetchChecklistByCompanyId(company?._id, branchID)
    }, [company, tab])

    return (
        <div className="tab-pane fade pt-3" id="profile-form-selection">
            <section className="section" id="checklist">
                <div className="row icon-boxes">
                    <Loader loading={loading} center>
                        {
                            collection?.map((item, index) => (
                                <ChecklistCard
                                    data={item}
                                    key={index}
                                />
                            ))
                        }
                    </Loader>

                </div>
            </section>
        </div>
    )
}

export default Checklist