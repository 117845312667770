import React, { useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Card, MenuItem } from "@mui/material";
import Table from "../../components/Table";
import { capitalFirst } from "../../config/functions";

const QuestionList = ({
  questions,
  setQuestions,
  setQuestion,
  setEditIndex,
  onDublicateQuestiuon,
}) => {
  const labels = ["#", "Question", "Question Type", "Images", "Required", "Actions"];
  // const { questionTypes } = useContext(AuditContext);
  const [dubIndex, setDubIndex] = useState(null);
  const onChange = (question, required) => {
    setQuestions(
      questions?.map((item) =>
        item.question == question.question ? { ...item, required } : item
      )
    );
  };
  const onDelete = (question) => {
    setQuestions(
      questions?.filter((item) => item.question != question.question)
    );
  };
  const onEdit = (question, index) => {
    setQuestion(question);
    setEditIndex(index);
  };
  const data = questions?.map((question, index) => {
    return {
      data: question,
      fields: [
        index + 1,
        question.question,
        capitalFirst(question.type),
        question.questionAttachments?.length ? `${question.questionAttachments.length} images` : "0 images",
        (question.type != 'statement' && question.type != 'statement/Instruction') && <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={question?.required}
            onChange={(e) => onChange(question, !question.required)}
          />
        </div>,
        <div className="d-flex gap-2">
          <Box sx={{ position: "relative" }}>
            {dubIndex != null && dubIndex == index && (
              <Card
                sx={{
                  position: "absolute",
                  top: "30px",
                  left: "0",
                  backgroundColor: "white",
                  zIndex: "99",
                }}
              >
                <MenuItem
                  onClick={() => {
                    onDublicateQuestiuon(
                      {
                        question: question.question,
                        required: question.required,
                        type: question.type,
                        options: question.options,
                        questionAttachments: question.questionAttachments,
                      },
                      index
                    );
                    setDubIndex(null);
                  }}
                >
                  Under
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDublicateQuestiuon({
                      question: question.question,
                      required: question.required,
                      type: question.type,
                      options: question.options,
                      questionAttachments: question.questionAttachments,
                    });
                    setDubIndex(null);
                  }}
                >
                  End
                </MenuItem>
              </Card>
            )}
            <ContentCopyIcon
              // className="bi bi-pencil-square text-primary"
              onClick={() => {
                setDubIndex(
                  dubIndex == null || dubIndex != index ? index : null
                );
              }}
              style={{
                cursor: "pointer",
                height: "20px",
                width: "20px",
                color: "green",
              }}
            ></ContentCopyIcon>
          </Box>
          <i
            className="bi bi-pencil-square text-primary"
            onClick={() => onEdit(question, index)}
            style={{ cursor: "pointer" }}
          ></i>
          <i
            onClick={() => onDelete(question)}
            className="bi bi-trash3 text-danger"
            style={{ cursor: "pointer" }}
          ></i>
        </div>,
      ],
    };
  });
  return (
    <Table
      title={"Audit Questions"}
      labels={labels}
      data={data}
    />
  );
};

export default QuestionList;
