import { Box } from "@mui/material";
import { CardElement } from "@stripe/react-stripe-js";
import React from "react";
import { Modal } from "react-bootstrap";
import americanExpressIcon from "../assets/amexIcon.png";
import visaIcon from "../assets/visaIcon.png";
import masterIcon from "../assets/masterCardIcon.png";
import unionIcon from "../assets/uncionpayIcon.png";
import Loader from "./Loader";
export default function Default({
  show,
  setShow,
  header,
  onSubmit,
  error,
  stripe,
  stripeLoading,
  user,
  isAdd,
  _id,
}) {
  return (
    <Modal show={show} centered onHide={setShow}>
      <Modal.Header>
        <div className="row w-100">
          <Modal.Title className={"col-12"}>{header}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(e) => onSubmit(e, user,_id)}>
          <div className="card-element">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
              }}
            >
              <img
                src={unionIcon}
                alt="unionIcon"
                height={"30px"}
                width={"40px"}
              />
              <img
                src={masterIcon}
                alt="masterIcon"
                height={"30px"}
                width={"40px"}
              />
              <img
                src={visaIcon}
                alt="visaIcon"
                height={"30px"}
                width={"40px"}
              />
              <img
                src={americanExpressIcon}
                alt="americanExpressIcon"
                height={"30px"}
                width={"40px"}
              />
            </Box>
            <CardElement
              options={{
                style: {
                  base: {
                    color: "#32325d",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "16px",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                },
              }}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
            <center>
              <button
                type="submit"
                className="btn btn-success my-4"
                disabled={!stripe}
              >
                <Loader loading={stripeLoading} color={"white"}>
                  <div className={"d-flex flex-row justify-content-center"}>
                    {<span>{isAdd ? "Add" : "Pay Now"}</span>}
                  </div>
                </Loader>
              </button>
            </center>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
