import React, { useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import useAuth from '../../hooks/useAuth';
import FormField from '../../components/FormField';
import SubmitButton from './SubmitButton';
import Title from './Title'

const Reset = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    // Hooks
    const { loading, onReset } = useAuth();

    // States
    const [data, setData] = useState({
        new_password: '',
        confirm_password: ''
    });

    // constants
    const fields = [
        {
            value: data.new_password,
            setValue: (value) => setData({ ...data, new_password: value }),
            label: 'New Password',
            required: true,
            type: 'password',
            className: 'col-12'
        },
        {
            value: data.confirm_password,
            setValue: (value) => setData({ ...data, confirm_password: value }),
            label: 'Confirm Password',
            required: true,
            type: 'password',
            className: 'col-12'
        },
    ]

    // Functions
    const onSubmit = (e) => {
        e.preventDefault();
        const resetPasswordToken = searchParams.get('resetPasswordToken');
        onReset({
            ...data,
            resetPasswordToken
        }, () => navigate('/login'))
    }

    return (
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex register-container">
                <Title />
                <div className="col-lg-6 col-md-12 d-flex flex-column align-items-center justify-content-center" style={{ paddingRight: 50 }}>
                    <div className="card mb-0">
                        <div className="card-body pt-2 pb-5 ps-5 pe-5">
                            {/* Title */}
                            <div className="pt-4 pb-4">
                                <h5 className="card-title text-center pb-0 fs-4">Reset your password</h5>
                                <p className="text-center small">Enter new password details.</p>
                            </div>
                            {/* From */}
                            <form className="row g-3" onSubmitCapture={onSubmit}>
                                {
                                    fields?.map((item, index) => (
                                        <FormField item={item} key={index} />
                                    ))
                                }
                                <div className="text-center">
                                    <SubmitButton
                                        title={'Reset'}
                                        onClick={onSubmit}
                                        loading={loading}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="text-center">
                                    <p className="small mb-0">If password has been reset, <Link to='/login'>Login here</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Reset