import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import authReducer from "../features/auth/slice";
import alertReducer from "../features/alert/slice";
import dashboardReducer from "../features/dashboard/slice";
import checklistReducer from "../features/checklist/slice";
import taskReducer from "../features/tasks/slice";
import documentReducer from "../features/documents/slice";
import companyReducer from "../features/companies/slice";
import profileReducer from "../features/profile/slice";
import categoryReducer from "../features/category/slice";
import natureReducer from "../features/nature/slice";
import adminReducer from "../features/admin/slice";
import broadcastReducer from "../features/broadcast/slice";
import notificationReducer from "../features/notification/slice";
import recordsReducer from "../features/records/slice";
import chatReducer from "../features/chat/slice";
import PolicyReducer from "../features/policies/slice";
import EmailReducer from "../features/emails/slice";
import RishAssesmentReducer from "../features/riskAssesment/slice";
import AuditReducer from "../features/audit/slice";
import SubscriptionReducers from "../features/subscription/slice"
import MsDocumentsReducers from "../features/managementSystem/slice"
import companyAdmins from "../features/companyAdmin/slice"
import fellowAdmins from "../features/fellowAdmin/slice";
import paymentMethod from "../features/paymentMethod/slice";
import ProposalReducer from "../features/proposals/slice"
const persistConfig = {
  key: "root",
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  dashboard: dashboardReducer,
  checklist: checklistReducer,
  task: taskReducer,
  document: documentReducer,
  company: companyReducer,
  profile: profileReducer,
  category: categoryReducer,
  nature: natureReducer,
  broadcast: broadcastReducer,
  admin: adminReducer,
  notification: notificationReducer,
  records: recordsReducer,
  chat: chatReducer,
  Policy: PolicyReducer,
  Email: EmailReducer,
  riskAssesment: RishAssesmentReducer,
  audit: AuditReducer,
  subscription: SubscriptionReducers,
  msDocuments: MsDocumentsReducers,
  companyAdmins: companyAdmins,
  fellowAdmins: fellowAdmins,
  paymentMethods: paymentMethod,
  proposals: ProposalReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
