import { Typography } from "@mui/material";
import React from "react";

export default function Default({ itemKey, value }) {
  function camelToWords(camelCaseString) {
    // Convert camelCase string to words
    let converted = camelCaseString
      ?.replace(/([a-z])([A-Z])/g, "$1 $2")
      ?.replace(/^./, (str) => str.toUpperCase());
    return converted.substring(0, 7) == "Allowed"
      ? converted.substring(8, converted.length)
      : converted; // Capitalize the first letter
  }
  let convertedKey = camelToWords(itemKey);
  return (
    <>
      {typeof value != "boolean" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            listStyle: "none",
            position: "relative", // Needed for pseudo-element positioning
            // paddingLeft: "1.5em",
          }}
        >
          <Typography sx={{ fontSize: "13px" }}>
            {convertedKey == "Employees"
              ? "Managers/supervisors/guest accounts"
              : convertedKey == "Emails"
              ? "Email Recipients in Email Database"
              : convertedKey == "Groups"
              ? "Email Groups"
              : convertedKey == "Share Per Day"
              ? "Email Share Per Day"
              : convertedKey == "Tasks Per Day"
              ? "Task Assignment Per Day"
              : convertedKey}
          </Typography>
          <h6>{value != -1 ? value : "unlimited"}</h6>
        </div>
      )}
    </>
  );
}
