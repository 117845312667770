import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    showNotification: true,
  },
  reducers: {
    setNotification: (state, action) => {
      state.notifications = action.payload;
    },
    setShowNotification: (state, action) => {
      state.showNotification = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications =
        state.notifications.find(item => item._id == action.payload._id)
          ? state.notifications
          : [action.payload, ...state.notifications];
    },
    seenAll: (state) => {
      state.notifications = state.notifications.map(item => ({ ...item, seen: true }));
    },
    clearAll: (state) => {
      state.notifications = []
    },
  },
});

export const { setNotification, seenAll,setShowNotification, clearAll, addNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
