import { Box } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import useEmails from "../../hooks/useEmails";
import Emails from "./emails";
import Groups from "./groups";
import "./style.css";
import useAuth from "../../hooks/useAuth";
export default function Default() {
  const { isCompany,isAdmin } = useAuth()
  const {
    loading,
    addLoading,
    data,
    setdata,
    newGroup,
    pageState,
    getEmailGroups,
    addGroup,
    handleBackClick,
    getEmails,
    addEmails,
    addEmailField,
    handleCsvFile,
    handleRemoveEmail,
    addGroupField,
    deleteGroup,
    deleteEmail,
    isUpdate, setisUpdate,
    handleGroupUpdate,
    updateGroup,
    handleEmailUpdate,
    updateEmail,
    setNewGroup,
    addNameField
  } = useEmails();
  const [filter, setFilter] = useState({
    branch:'',
    company:'',
  })
  useEffect(() => {
    getEmailGroups(filter);
  }, [filter]);

  return (
    <Screen
      loading={loading}
      flow={[
        { label: "Emails", to: "/email" },
        { label: "Home", to: "/" },
        { label: "Emails", to: "/email" },
      ]}
      filter={filter}
      setFilter={setFilter}
      showBranchFilter={(isCompany || isAdmin) && pageState.state != "email"}
    >
      {pageState.state == "email" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: "15px",
          }}
        >
          <SubmitButton
            title={"Back"}
            onClick={handleBackClick}
            color={"outline-primary"}
            icon={<i className="bi bi-arrow-left "></i>}
          />
        </Box>
      )}
      {pageState.state == "group" ? (
        <Groups
          addLoading={addLoading}
          newGroup={newGroup}
          addGroup={addGroup}
          getEmails={getEmails}
          addGroupField={addGroupField}
          deleteGroup={deleteGroup}
          isUpdate={isUpdate}
          handleGroupUpdate={handleGroupUpdate}
          updateGroup={updateGroup}
          setisUpdate={setisUpdate}
          setNewGroup={setNewGroup}
        />
      ) : (
        <Emails
          addEmailField={addEmailField}
          data={data}
          addLoading={addLoading}
          loading={loading}
          addEmails={addEmails}
          handleRemoveEmail={handleRemoveEmail}
          handleCsvFile={handleCsvFile}
          deleteEmail={deleteEmail}
          isUpdate={isUpdate}
          handleEmailUpdate={handleEmailUpdate}
          updateEmail={updateEmail}
          setisUpdate={setisUpdate}
          setdata={setdata}
          addNameField={addNameField}
        />
      )}
    </Screen>
  );
}
