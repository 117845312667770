import React, { useContext } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import useAuth from "../../hooks/useAuth";
import Table from "../../components/Table";
import useDocuments from "../../hooks/useDocuments";
import { formatBytes } from "../../config/functions";
import { keys } from "../../config/keys";
import { PoliciesContext } from "../../hooks/policiesContext";
import { Checkbox } from "@mui/material";

const FilesList = ({
  noActions,
  setModalName,
  setShowAddModal,
  setDocData,
  selectedFolder
}) => {
  const { documents, removeDocument, deleteLoading } = useDocuments();
  const { isAdmin, isCompany, user, isManager } = useAuth();
  const { selectedForShare, addForShare, removeFromShare } =
    useContext(PoliciesContext);
  const onView = (item) => {
    window.open(`${keys.BASE_LOCAL_URL}/${item.url}`, "_blank", "noreferrer");
  };
  const onHandleMoadal = (item) => {
    setModalName("Update Document");
    setShowAddModal(true);
    setDocData(item);
  };

  const labels = [
    "#",
    "Name",
    (isAdmin ? "Company" : null),
    (isAdmin || isCompany ? "Site" : null),
    "Size",
    "Last Modified",
    "File",
    noActions ||
      (selectedFolder[0]?.name == 'Bespoke Documents' && !isAdmin) ||
      (selectedFolder[0]?.companyFolder && isManager)
      ? null : "Actions"
  ].filter(item => item)
  const data = documents ? documents.map((item, index) => {
    const company = item?.user_id?.companyID ? item?.user_id?.companyID.name : item?.user_id?.name;
    const branch = item?.user_id?.branchID?.name;
    return {
      data: item,
      fields: [
        // item?._id.substr(item?._id.length - 5),
        isAdmin || isCompany || isManager ? <Checkbox
            checked={selectedForShare.includes(item)}
            onChange={async (e) => {
              if (e.target.checked) {
                addForShare(item);
              } else {
                removeFromShare(item);
              }
            }}
          /> : item?._id.substr(item?._id.length - 5),
        item?.name,
        isAdmin ? <td>{user?.name == company ? '' : company}</td> : null,
        isAdmin || isCompany ? <td>{branch}</td> : null,
        formatBytes(item?.size),
        `${new Date(item?.timestamp).toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })} ${new Date(item?.timestamp).toLocaleDateString("en-GB", {
          weekday: "short",
        })}`,
        <button
          onClick={() => onView(item)}
          className="btn btn-sm btn-success"
        >
          Open
        </button>,
        noActions ||
          (selectedFolder[0]?.name == 'Bespoke Documents' && !isAdmin) ||
          (selectedFolder[0]?.companyFolder && isManager)
          ? null
          :
          <div className="d-flex gap-3">
            <i
              className="bi bi-pencil-square text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => onHandleMoadal(item)}
            ></i>
            {
              deleteLoading?._id == item?._id ?
                (
                  <div className="d-flex align-items-center justify-content-center ">
                    <Loader loading={deleteLoading} color={"red"} />
                  </div>
                )
                :
                (
                  <i
                    className="bi bi-trash text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => removeDocument(item, item?.folder_id)}
                  ></i>
                )
            }
          </div>
      ].filter(it => it)
    }
  }) : []
  return (
    <>
      <Table
        labels={labels}
        data={data}
      />
    </>
  );
};

export default FilesList;
