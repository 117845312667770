import React, { memo, useEffect, useMemo, useState } from "react";
import Table from "../../components/Table";
import api from "../../app/api";
import Screen from "../../components/Screen";
import EmptyData from "../../components/EmptyData";
import useAuth from "../../hooks/useAuth";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from "./pdf";
import FormField from "../../components/FormField";
import { convertToLocal } from "../../config/functions";
import DownloadButton from "../../components/downloadButton"
import { useLocation } from "react-router-dom";
const FilesList = () => {
  //   const { policies } = useSelector((state) => state.Policy);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [submissionData, setSubmissionData] = useState([]);
  const { isCompany, isAdmin, user, isManager } = useAuth();
  const [limit, setlimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setfilter] = useState({ company: "", branch: "" });
  const [loading, setloading] = useState(false);
  const status = queryParams.get("status");
  const getData = async () => {
    setloading(true);
    try {
      const response = await api.get(
        `/shareSubmissions/get?${isCompany
          ? `id=${localStorage.getItem("_id")}`
          : isManager
            ? `id=${user.branchID}`
            : `companyID=${filter.company}`
        }&page=${page}&limit=${limit}&branch=${filter.branch}&status=${status == 'pending' ? status : ''}`,
        {
          ...(isCompany && { id: localStorage.getItem("_id") }),
          ...(isAdmin && { ...filter }),
        }
      );
      setSubmissionData(response.data.result);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };
  const labels = [
    "#",
    "Submitted By",
    "Type",
    "Submitted At",
    status != 'pending' && "Confirmation",
    "Content",
  ].filter((item) => item);
  const [localFilter, setLocalFilter] = useState({
    name: "",
    email: ""
  })
  const data = useMemo(()=>(
    submissionData
    ? submissionData.map((item) => {
        if (localFilter.name !== "" || localFilter.email !== "") {
          if ((localFilter.name !== "" ? item.submittedName?.includes(localFilter.name) : true)
            && (localFilter.email !== "" ? item.submittedEmail?.includes(localFilter.email) : true)) {
            return {
              data: item,
              fields: [
                item._id?.substring(0, 5),
                item?.submittedName ? item.submittedName : item.submittedEmail,
                item?.forFeature,
                convertToLocal(item?.createdAt),
                status != 'pending' ? <DownloadButton 
                  PDF={<PDF data={item} />}
                  item={item}
                  fileName={`email-confirmation-` + item?.submittedEmail + `.pdf`}
                /> : null,
                <a
                  href={`/shareView?id=${item.shareableID?._id
                    }&isUser=${true}&userID=${localStorage.getItem("_id")}`}
                  target="_blank"
                >
                  View
                </a>,
              ].filter((it) => it),
            };
          } else {
            return null
          }
        } else {
          return {
            data: item,
            fields: [
              item._id?.substring(0, 5),
              item?.submittedName ? item.submittedName : item.submittedEmail,
              item?.forFeature,
              convertToLocal(item?.createdAt),
              status != 'pending' ? <DownloadButton 
                  PDF={<PDF data={item} />}
                  item={item}
                  fileName={`email-confirmation-` + item?.submittedEmail + `.pdf`}
                />:null,
              <a
                href={`/shareView?id=${item.shareableID?._id
                  }&isUser=${true}&userID=${localStorage.getItem("_id")}`}
                target="_blank"
              >
                View
              </a>,
            ].filter((it) => it),
          };
        }
      }).filter(item => item) : []
    ), [localFilter, submissionData]);
  useEffect(() => {
    getData();
  }, [filter, page]);
  useEffect(() => {
    setfilter({ company: "", branch: "" })
    setPage(1)
    getData();
  }, [status]);

  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setfilter}
      showBranchFilter={isCompany || isAdmin}
      flow={status == 'pending' ? [
        { label: "Sent Emails", to: "/sentMails?status=pending" },
        { label: "Home", to: "/" },
        { label:  "Sent Emails", to: "/sentMails?status=pending" },
      ]:[
        { label: "Email Confirmations", to: "/sentMails" },
        { label: "Home", to: "/" },
        { label: "Email Confirmations", to: "/sentMails" },
      ]}
    >
      <div className="row mb-4">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <FormField item={{
            type: "text",
            value: localFilter.email,
            placeholder: "Email...",
            setValue: (val) => setLocalFilter({ ...localFilter, "email": val })
          }} />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <FormField item={{
            type: "text",
            value: localFilter.name,
            placeholder: "Name...",
            setValue: (val) => setLocalFilter({ ...localFilter, "name": val })
          }} />
        </div>
      </div>
      <EmptyData data={data}>
        <Table
          labels={labels}
          data={data}
          limit={limit}
          page={page}
          setPage={setPage}
        />
      </EmptyData>
    </Screen>
  );
};

export default memo(FilesList);
