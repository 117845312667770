import React from "react";
import SubmitButton from "../../components/SubmitButton";
import FormField1 from "../../components/FormField";
import Loader from "../../components/Loader";
export default function Default({ data, setData, onAddSubscription, companies, FormField, loading, selection,state,edit,onUpdateSubscription }) {
  let fields = [
    {
      value: data?.name,
      setValue: (value) => setData({ ...data, name: value }),
      label: "Enter subscription name",
      placeholder: "Enter subscription name",
      type: "text",
      id: "name",
      required: true,
    },
    {
      value: data?.cost,
      setValue: (value) => setData({ ...data, cost: value }),
      label: "Enter cost",
      placeholder: "Enter cost",
      type: "number",
      required: true,
      disabled: edit ? true:false,
      id: "cost",
    },
    {
      value: data?.allowedChecklists,
      setValue: (value) => setData({ ...data, allowedChecklists: value }),
      label: "Enter checklists",
      placeholder: "Enter checklists",
      type: "number",
      required: true,
      id: "allowedChecklists",
      required: true,
    },
    {
      value: data?.allowedAudit,
      setValue: (value) => setData({ ...data, allowedAudit: value }),
      label: "Enter audits",
      placeholder: "Enter audits",
      type: "number",
      required: true,
      id: "allowedAudit",
    },
    {
      value: data?.allowedPolicies,
      setValue: (value) => setData({ ...data, allowedPolicies: value }),
      label: "Enter policies",
      placeholder: "Enter policies",
      type: "number",
      required: true,
      id: "allowedPolicies",
    },
    {
      value: data?.allowedRiskAssessments,
      setValue: (value) => setData({ ...data, allowedRiskAssessments: value }),
      label: "Enter risk assessments",
      placeholder: "Enter risk assessments",
      type: "number",
      required: true,
      id: "allowedRiskAssessments",
    },
    {
      value: data?.allowedTasksPerDay,
      setValue: (value) => setData({ ...data, allowedTasksPerDay: value }),
      label: "Enter tasks submissions per day",
      placeholder: "Enter tasks submissions per day",
      type: "number",
      required: true,
      id: "allowedTasksPerDay",
    },
    {
      value: data?.allowedDocuments,
      setValue: (value) => setData({ ...data, allowedDocuments: value }),
      label: "Enter documents",
      placeholder: "Enter documents",
      type: "number",
      required: true,
      id: "allowedDocuments",
    },
    {
      value: data?.allowedGroups,
      setValue: (value) => setData({ ...data, allowedGroups: value }),
      label: "Enter email groups",
      placeholder: "Enter email groups",
      type: "number",
      required: true,
      id: "allowedGroups",
    },
    {
      value: data?.allowedEmails,
      setValue: (value) => setData({ ...data, allowedEmails: value }),
      label: "Enter email recipients",
      placeholder: "Enter email recipients",
      type: "number",
      required: true,
      id: "allowedEmails",
    },
    {
      value: data?.allowedSharePerDay,
      setValue: (value) => setData({ ...data, allowedSharePerDay: value }),
      label: "Enter email share per day",
      placeholder: "Enter email share per day",
      type: "number",
      required: true,
      id: "allowedSharePerDay",
    },
    {
      value: data?.allowedInbox,
      setValue: (value) =>
        setData({ ...data, allowedInbox: value == "true" ? true : false }),
      label: "Is inbox allowed",
      placeholder: "Is inbox allowed",
      type: "dropdown",
      options: [
        {
          name: "True",
          value: "true",
        },
        {
          name: "False",
          value: "false",
        },
      ],
      id: "allowedInbox",
      required: true,
    },
    {
      value: data?.allowedEmployees,
      setValue: (value) => setData({ ...data, allowedEmployees: value }),
      label: "Enter Managers/supervisors/guest accounts",
      placeholder: "Enter Managers/supervisors/guest accounts",
      type: "number",
      required: true,
      id: "allowedEmployees",
    },
    {
      value: data?.additionalSites,
      setValue: (value) => setData({ ...data, additionalSites: value }),
      label: "Enter additional sites",
      placeholder: "Enter additional sites",
      type: "number",
      required: true,
      id: "additionalSites",
    },
    {
      value: data?.validity,
      setValue: (value) => setData({ ...data, validity: value }),
      label: "Select validity",
      placeholder: "Select validity",
      required: true,
      disabled: edit ? true:false,
      options: [
        {
          name: "Monthly",
          value: "monthly",
        },
        {
          name: "Yearly",
          value: "yearly",
        },
      ],
      type: "dropdown",
      id: "validity",
    },
    {
      value: data?.companyID,
      setValue: (value) => setData({ ...data, companyID: value, adminID: "" }),
      label: "Select company",
      required: true,
      options: companies,
      placeholder: "Select company",
      disabled: edit ? true:false,
      type: "dropdown",
      id: "companyID",
    },
    // {
    //   value: data?.adminID,
    //   setValue: (value) => setData({ ...data, adminID: value, companyID:'' }),
    //   label: 'Select admin',
    //   options: companyAdmins,
    //   placeholder: 'Select admin',
    //   type: 'dropdown',
    //   id: 'adminID'
    // },
    // {
    //   value: data?.companyEmail,
    //   setValue: (value) => setData({ ...data, companyEmail: value }),
    //   label: 'Enter company email',
    //   placeholder: 'Enter company email',
    //   type: 'email',
    //   id: 'companyEmail'
    // },
    // {
    //   value: data?.adminEmail,
    //   setValue: (value) => setData({ ...data, adminEmail: value }),
    //   label: 'Enter admin email',
    //   placeholder: 'Enter admin email',
    //   type: 'email',
    //   id: 'adminEmail'
    // },
  ];
  return (
    <>
      {selection == "company" && <>
        <div className="card gap-3 py-4">
          <div className="card-body">
            {fields.map((item) => (
              <div className="col-12">
                <FormField field={item} />
              </div>
            ))}
            {data.additionalFeatures?.map((item, index) => (
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <FormField1
                                        item={{
                                            value: item?.feature,
                                            setValue: (value) => setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures[index].feature = value
                                                return newState
                                            }),
                                            placeholder: 'Enter feature name',
                                            type: 'text',
                                            required: true,
                                            id: `feature-${index}`,
                                            className: ""
                                        }}
                                    />
                                </div>
                                <div className='col-5'>
                                    <FormField1
                                        item={{
                                            value: item?.allocation,
                                            setValue: (value) => setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures[index].allocation = value
                                                return newState
                                            }),
                                            placeholder: 'Enter allocation',
                                            type: 'text',
                                            required: true,
                                            id: `allocation-${index}`,
                                            className: ""
                                        }}
                                    />
                                </div>
                                <div className='col-1'>
                                    <SubmitButton
                                        icon={<i className="bi bi-trash3"></i>}
                                        loading={loading == "delete"}
                                        onClick={() => {
                                            setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures.splice(index, 1)
                                                return newState
                                            })
                                        }}
                                        color={"danger"}
                                        loaderColor={"white"}
                                    // disabled={disabled}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className='col-12'>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-12'>
                                <button
                                    className='btn btn-sm rounded-5 btn-outline-primary w-100 mt-2'
                                    onClick={() => {
                                        setData(prevState => {
                                            let newState = { ...prevState }
                                            newState.additionalFeatures.push({
                                                feature: '',
                                                allocation: ""
                                            })
                                            return newState
                                        })
                                    }}
                                >
                                    <Loader loading={loading} center color={'white'}>
                                        Add More +
                                    </Loader>
                                </button>
                            </div>
                        </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <SubmitButton
              title={state?._id ? "Update subscription" : "Save subscription"}
              loading={loading == "new" || loading == "update"}
              onClick={() => {
                if(state?._id){
                  onUpdateSubscription()
                }
                else{
                  onAddSubscription();
                }
              }}
              color={"success"}
              loaderColor={"white"}
              disabled={
                data.name == "" ||
                data.cost == "" ||
                data.allowedChecklists == "" ||
                data.allowedAudit == "" ||
                data.allowedPolicies == "" ||
                data.allowedRiskAssessments == "" ||
                data.allowedEmployees == "" ||
                data.allowedTasksPerDay == "" ||
                data.allowedDocuments == "" ||
                data.allowedGroups == "" ||
                data.allowedEmails == "" ||
                data.allowedSharePerDay == "" ||
                (data.allowedInbox != true && data.allowedInbox != false) ||
                data.additionalSites == "" ||
                data.validity == "" ||
                data.companyID == ""
              }
            />
          </div>
        </div>
      </>}
    </>
  )
}
