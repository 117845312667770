import React from 'react'
import EmptyData from '../../components/EmptyData'
import Screen from '../../components/Screen'

const Training = () => {
    return (
        <Screen flow={[
            { label: 'Training', to: '/training' },
            { label: 'Home', to: '/' },
            { label: 'Training', to: '/training' }
        ]}>
            <section className="section training">
                <EmptyData data={[]} message={'Coming Soon!'} />
            </section>
        </Screen>
    )
}

export default Training