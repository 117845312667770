import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useCompanies from '../../hooks/useCompanies'

import Loader from '../../components/Loader'
import Screen from '../../components/Screen'
import Table from '../../components/Table'

const Branches = () => {
  const { user } = useAuth()
  const {
    deleteLoading,
    loading,
    branches,
    page,
    limit,
    setPage,
    fetchBranches,
    removeBranch
  } = useCompanies();


  const labels = [
    '#',
    'Name',
    'Location',
    'Action',
  ];
  const data = branches
    ?.map((item) => {
      return {
        data: item,
        fields: [
          item?._id.substr(item?._id.length - 5),
          item.name,
          item.location,
          <div className='d-flex gap-3'>
            <Link to={`/profile?branchID=${item._id}`} state={{ branch: item }} >
              <i className="bi bi-pencil-square text-primary"></i>
            </Link>
            <Loader loading={deleteLoading?._id == item._id} color='red'>
              <i onClick={() => removeBranch(item)} className="bi bi-trash text-danger" style={{ cursor: 'pointer' }}></i>
            </Loader>
          </div>
        ]
      }
    })

  useEffect(() => {
    fetchBranches(user?._id);
  }, []);

  return (
    <Screen loading={loading} flow={[
      { label: 'Sites', to: '/branches' },
      { label: 'Home', to: '/' },
      { label: 'Sites', to: '/branches' }
    ]}>
      <Table
        title={'Registered Sites'}
        data={data}
        labels={labels}
        limit={limit}
        page={page}
        setPage={setPage}
      />
    </Screen>
  )
}

export default Branches