import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
    name: "paymentMethods",
    initialState: {
        paymentMethods: [],
    },
    reducers: {
        setPaymentMethod: (state, action) => {
            state.paymentMethods = action.payload;
        },
        updatePaymentMethod: (state, action) => {
            state.paymentMethods = state.paymentMethods.map((item) =>
                item._id == action.payload.id ? action.payload : item
            );
        },
        deletePaymentMethodSlice: (state, action) => {
            state.paymentMethods = state.paymentMethods.filter(
                (item) => item.id != action.payload.id
            );
        },
        addPaymentMethod: (state, action) => {
            state.paymentMethods = [...(state.paymentMethods.map(item => ({
                ...item,
                isDefault: action.payload.id == item.id ? true : false
            }))), action.payload];
        },
        updateIsDefaultPaymentMethod: (state, action) => {
            state.paymentMethods = state.paymentMethods.map(item => ({
                ...item,
                isDefault: action.payload.id == item.id ? true : false
            }))
        }
    },
});

export const {
    setPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethodSlice,
    addPaymentMethod,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
