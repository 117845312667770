import React from 'react'

import FormField from '../../components/FormField';
import Loader from '../../components/Loader';

const NewNature = ({ data, setData, onSubmit, loading }) => {
  const isUpdate = !!data._id;
  const fields = [
    {
      label: 'Name',
      value: data.name,
      setValue: (value) => setData({ ...data, name: value }),
      placeholder: 'Name',
      type: 'text',
      className: 'mb-2',
    },
    {
      label: 'Email',
      value: data.email,
      setValue: (value) => setData({ ...data, email: value }),
      placeholder: 'Email',
      type: 'email',
      className: 'mb-2',
    },
    {
      label: data.status ? 'Active' : 'InActive',
      value: data.status,
      setValue: (value) => setData({ ...data, status: value }),
      placeholder: 'Status',
      type: 'boolean',
      className: 'mb-2',
    },
  ]
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{isUpdate ? 'Update' : 'New'} Super Admin</h5>
        {
          fields.map((item, index) => (
            <FormField item={item} key={index} />
          ))
        }
        <button className='btn btn-primary w-100 mt-3' onClick={onSubmit}>
          <Loader loading={loading} color={'white'}>
            {isUpdate ? 'Update' : 'Add'}
          </Loader>
        </button>
      </div>
    </div>
  )
}

export default NewNature