export const restricted = [
    // { title: 'Checklists', path: '/checklist' },
    { title: 'Tasks', path: '/tasks' },
    { title: 'Records', path: '/records' },
    { title: 'Documents', path: '/documents' },
    { title: 'Public Library', path: '/documents?public=true' },
    { title: 'Policies', path: '/policy' },
    { title: 'Risk Assessment', path: '/risk-assesments' },
]

export const restrictedRoutes = restricted.map(item => item.path);