import React from 'react'
import { Link } from 'react-router-dom'

const SummaryCard = ({ title, value, subtitle, icon, className, to }) => {
    return (
        <div className={`card info-card ${className}-card`}>
            <div className="card-body">
                <Link to={to}>
                    <h5 className="card-title">{title}</h5>
                    <div className="d-flex align-items-center">
                        <div
                            className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                        >
                            {icon}
                        </div>
                        <div className="ps-3">
                            <h6>{value}</h6>
                            <span className="text-muted small pt-2 ps-1">{subtitle}</span>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default SummaryCard