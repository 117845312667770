import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import useAuth from "../../hooks/useAuth";
import useEmails from "../../hooks/useEmails";
const FilesList = ({ emails, deleteEmail, handleEmailUpdate }) => {
  //   const { emails } = useSelector((state) => state.Policy);
  const { loading } = useEmails();
  const { isAdmin, isCompany, isManager } = useAuth();

  const labels = ["Email", "Name", (isAdmin || isCompany || isManager) && "Actions"].filter(
    (item) => item
  );
  const data = emails
    ? emails.map((item) => {
        return {
          data: item,
          fields: [
            item?.email,
            item?.name,
            (isAdmin || isCompany || isManager) && (
              <div className="d-flex gap-3">
                <i
                  className="bi bi-pencil-square text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEmailUpdate(item)}
                ></i>
                {loading?._id == item?._id ? (
                  <div className="d-flex align-items-center justify-content-center ">
                    <Loader loading={loading} color={"red"} />
                  </div>
                ) : (
                  <i
                    className="bi bi-trash text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteEmail(item?._id)}
                  ></i>
                )}
              </div>
            ),
          ].filter((it) => it),
        };
      })
    : [];
  return (
    <>
      <Table labels={labels} data={data} />
    </>
  );
};

export default FilesList;
