import React, { useContext } from "react";
import { keys } from "../../config/keys";
import { Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import appContext from "../../app/contextState/context";
import { useNavigate } from "react-router-dom";
const MessageHeader = ({ chat, pageState }) => {
  const { setShowMessageSidebar } = useContext(appContext);
  const navigator = useNavigate();
  return (
    <div className="card-header d-flex align-items-center gap-2 text-black">
      {!chat ? (
        <>
          <h5 className="mt-2 text-secondary" style={{ fontWeight: "bold" }}>
            {pageState == "inbox" ? "Messages" : "Broadcast"}
          </h5>
        </>
      ) : (
        <>
          <div
            style={{
              alignItems: "center",
            }}
            className="d-flex"
          >
            <Box
              sx={{
                marginRight: "15px",
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowMessageSidebar(true);
                  navigator("/inbox");
                }}
              />
            </Box>
            <img
              src={
                pageState == "inbox"
                  ? chat.title?.imageURL
                    ? `${keys.BASE_LOCAL_URL}/${chat.title?.imageURL}`
                    : "assets/img/user.png"
                  : "assets/img/logo.png"
              }
              className="rounded rounded-circle "
              style={{
                objectFit: "cover",
                height: 50,
                width: 50,
              }}
            />
          </div>
          <span
            style={{
              maxWidth: "50%",
              textOverflow: "ellipsis",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {pageState == "inbox" ? chat?.title?.name : chat?.title}
          </span>
        </>
      )}
    </div>
  );
};

export default MessageHeader;
