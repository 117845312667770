import React, { useEffect, useState } from "react";

import QuestionList from "./QuestionList";
import FormField from "../../components/FormField";
import { useContext } from "react";
import { RiskAssesmentContext } from "../../hooks/riskAssesmentContext";
import { Box } from "@mui/material";

const RiskAssesmentQuestions = ({
  data,
  setData,
  question,
  setQuestion,
  onAddQuestiuon,
  onDublicateQuestiuon
}) => {
  const { questionTypes, fetchQuestionTypes } =
    useContext(RiskAssesmentContext);
  const [sortOrder, setSortOrder] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  // Options

  useEffect(() => {
    fetchQuestionTypes();
  }, []);

  return (
    <div className="row">
      {/* Question List */}
      <div
        className="col-xl-12"
      >
        <QuestionList
          questions={data?.questions}
          setQuestion={setQuestion}
          setQuestions={(questions) => setData({ ...data, questions })}
          setData={setData}
          setEditIndex={setEditIndex}
          setSortOrder={setSortOrder}
          question={question}
          onAddQuestiuon={onAddQuestiuon}
          sortOrder={sortOrder}
          editIndex={editIndex}
          onDublicateQuestiuon={onDublicateQuestiuon}
        />
      </div>
    </div>
  );
};

export default RiskAssesmentQuestions;
