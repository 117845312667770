import React from "react";

const FormField = ({ item }) => {
  const onChange = (e) => item.setValue(e.target.value);

  return (
    <div
      className={`${item.className} d-flex flex-column justify-content-between`}
    >
      {item.label ? <label className="form-label">{item.label}</label> : null}
      {item.type == "dropdown" ? (
        <select
          className="form-select"
          aria-label="Default select example"
          value={item.value}
          onChange={(e) => item.setValue(e.target.value)}
        >
          {item.options?.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </select>
      ) : item.type == "file" ? (
        <input
          className={`form-control`}
          type={item.type}
          //   value={item.value}
          onChange={(e) => item.setValue(e.target.files[0])}
          placeholder={item.placeholder}
        />
      ) : (
        <input
          className={`form-control`}
          type={item.type}
          value={item.value}
          onChange={(e) => item.setValue(e.target.value)}
          placeholder={item.placeholder}
        />
      )}
    </div>
  );
};

export default FormField;
