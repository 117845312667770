import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import useCompanies from "../../hooks/useCompanies";
import useAuth from "../../hooks/useAuth";

import Screen from "../../components/Screen";

import Branches from "./Branches";
import Checklist from "./Checklist";
import Support from "./Support";
import Edit from "./Edit";
import Header from "./Header";
import Navbar from "./Navbar";
import Overview from "./Overview";
import Team from "./Team";
import Permissions from "./Permissions";
import Comments from "./comments";
import useSubscription from "../../hooks/useSubscription";

const Profile = () => {
  const [query] = useSearchParams();
  const location = useLocation();
  const { user, onUser, isManager, isCompany, isAdmin } = useAuth();
  const {
    loading,
    data,
    addLoading,
    branches,
    setData,
    fetchCompanyById,
    changeCompany,
    changeBranch,
    tab,
    setTab,
  } = useCompanies();
  const [nature, setNature] = useState(data?.nature?.name);
  const {getData} = useSubscription()
  const companyID = query.get("companyID");
  const branchID = query.get("branchID");
  const company = branchID
    ? isManager
      ? user?.companyID
      : user
    : isManager
    ? user?.companyID
    : data;
  useEffect(() => {
    if (isManager) onUser();
    else if (!branchID) fetchCompanyById(companyID);
  }, [query]);
  useEffect(() => {
    if(isAdmin) getData()
  }, [])
  
  useEffect(() => {
    if (isManager) {
      setData(branches[0]);
    }
    if (branchID) {
      setData(branches?.find((item) => item._id == branchID));
    }
    setTab("#profile-overview");
    setTimeout(() => {
      if (location.state?.tab) {
        setTab('#profile-support');
      } else {
        setTab("#profile-overview");
      }
    }, 2000);
  }, []);

  return (
    <Screen
      loading={loading}
      flow={[
        {
          label: branchID
            ? "Sites"
            : (isCompany && !branchID) || isManager
            ? "Profile"
            : "Companies",
          to: branchID ? "/branches" : "/companies",
        },
        { label: "Home", to: "/" },
        (isCompany && !branchID) || isManager
          ? null
          : {
              label: branchID ? "Sites" : "Companies",
              to: branchID ? "/branches" : "/companies",
            },
        {
          label: branchID || isManager ? "Site Profile" : "Company Profile",
          to: "/profile",
        },
      ].filter((item) => item)}
    >
      <section className="section profile">
        <div className="row">
          {branchID ? null : <Header user={company} nature={nature} />}

          <div className={branchID ? "col-xl-12" : "col-xl-8"}>
            <div className="card">
              <div className="card-body pt-3">
                <Navbar
                  tab={tab}
                  setTab={setTab}
                  isAdmin={isAdmin}
                  isManager={isManager}
                  branchID={branchID}
                />
                <div className="tab-content pt-2">
                  <Overview
                    company={company}
                    tab={tab}
                    isManager={isManager}
                    branchID={branchID}
                  />
                  <Edit
                    data={data}
                    setData={setData}
                    onSubmit={() => {
                      isManager || branchID
                        ? changeBranch(branchID ? company?._id : companyID)
                        : changeCompany();
                    }}
                    loading={addLoading}
                    isManager={isManager}
                    branchID={branchID}
                    isAdmin={isAdmin}
                  />
                  <Checklist company={company} tab={tab} branchID={branchID} />
                  <Team
                    company={company}
                    tab={tab}
                    isManager={isManager}
                    branchID={branchID}
                  />
                  {
                    <>
                      {isManager ||
                        (isCompany && <Branches company={company} tab={tab} />)}
                      <Support
                        company={company}
                        isManager={isManager}
                        isCompany={isCompany}
                        isAdmin={isAdmin}
                      />
                    </>
                  }
                  <Permissions
                    company={company}
                    branchID={branchID}
                    tab={tab}
                  />
                  {isAdmin ||
                        (isCompany && <Comments company={company} tab={tab} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Screen>
  );
};

export default Profile;
