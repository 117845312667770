import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "../app/api";
import useAlert from "./useAlert";
import { DeleteAlert, UpdateAlert } from "../components/Swal Alert/index";

import {
  setStats,
  setDocument,
  addDocument,
  updateDocument,
  deleteDocument,
  setFolder,
  addFolder,
  deleteFolder,
  updateFolder,
} from "../features/documents/slice";
import useAuth from "./useAuth";

export default () => {
  const dispatch = useDispatch();
  const { documents, stats } = useSelector((state) => state.document);

  const { showAlert } = useAlert();
  const { isManager } = useAuth()
  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalName, setModalName] = useState("Add document");
  const [addFolderLoading, setAddFolderLoading] = useState(false);
  const [docData, setDocData] = useState({
    name: "",
    file: "",
    folder_id: "",
    _id: "",
  });

  // Stats
  const fetchStats = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/document/stats`);
      dispatch(setStats(response.data));
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(message, "danger");
      setLoading(false);
    }
  };

  // Folders
  const fetchFolders = async (params) => {
    setLoading(true);
    dispatch(setDocument([]));
    try {
      if (isManager) {
        params.isManager = true
      }
      const response = await api.get(`/folder`, { params });
      dispatch(setFolder(response.data));
      return response.data;
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(message, "danger");
    }
  };

  const AddFolders = async (data, filter, isCompany) => {
    setAddLoading(true);
    try {
      // data.visibility = 'private'
      if (isCompany) {
        if (filter.branch) {
          // await fetch(`${keys}/`)
          data.user_id = filter.branch
          data.addedByCompany = isCompany

        }
        else {
          data.user_id = localStorage.getItem('_id')
        }
      }
      else {
        data.user_id = localStorage.getItem('_id')
      }
      const response = await api.post(`/folder/new`, data);
      dispatch(addFolder(response.data));
      setAddLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddLoading(false);
      showAlert(message, "danger");
      setAddLoading(false);
    }
  };

  //Add Documents
  const AddNewDocument = async () => {
    setAddLoading(true);
    //(docData)
    try {
      let response;
      if (docData?.files?.length > 0) {
        await Promise.all(
          docData?.files?.map(async (item, index) => {
            const formdata = new FormData();
            formdata.append("name", item?.name);
            formdata.append("image", item);
            formdata.append("folder_id", docData?.folder_id);

            response = await api.post(`/document/new`, formdata);
            dispatch(addDocument(response.data));
          }))
      }
      else {
        const formdata = new FormData();
        formdata.append("name", docData?.name)
        formdata.append("image", docData?.file);
        formdata.append("folder_id", docData?.folder_id);
        response = await api.post(`/document/new`, formdata);

      }
      showAlert("Add Successful", "success");
      setAddLoading(false);
      setModalName("");
      setShowAddModal(false);
      // window.location.reload();
      setDocData({
        name: "",
        file: "",
        folder_id: "",
      });
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddLoading(false);
      //(message);
      //("eerr", err);
      showAlert(message, "danger");
      setAddLoading(false);
    }
  };
  // Documents Itself
  const fetchDocuments = async (params) => {
    setLoading(true);
    try {
      //(params)
      const response = await api.get(`/document`, { params });
      dispatch(setDocument(response.data));
      //(response.data)
      // setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      //(message);
      showAlert(message, "danger");
      setLoading(false);
    }
  };

  const changeDocument = async () => {
    if (!(await UpdateAlert())) return;
    setAddLoading(true);
    const formdata = new FormData();
    formdata.append("name", docData?.name);
    formdata.append("image", docData?.file);
    formdata.append("folder_id", docData?.folder_id);
    try {
      const response = await api.put(
        `/document/update/${docData?._id}`,
        formdata
      );
      showAlert("Updated  successfully", "success");
      dispatch(updateDocument(response.data));
      setAddLoading(false);

      setDocData({
        name: "",
        file: "",
        folder_id: "",
        _id: "",
      });
      setModalName("");
      setShowAddModal(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddLoading(false);
      //(message);
      showAlert(message, "danger");
      setAddLoading(false);
    }
  };
  const removeDocument = async (item, folder_id) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(item);
    try {
      const response = await api.delete(
        `/document/delete/${item?._id}?folder_id=${folder_id}`
      );
      dispatch(deleteDocument(item));
      setDeleteLoading(false);
      showAlert("Deleted Document successfully", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setDeleteLoading(false);
      //(message);
      showAlert(message, "danger");
      setDeleteLoading(false);
    }
  };

  const removeFolder = async (item, folder_id) => {
    if (await DeleteAlert()) {
      setAddFolderLoading(folder_id);
      try {
        await api.delete(`/folder/delete/${folder_id}`);
        dispatch(deleteFolder(item));
        setAddFolderLoading(false);
        showAlert("Deleted Document successfully", "success");
      } catch (err) {
        const message = err.response
          ? err.response.data?.message
            ? err.response.data?.message
            : err.response.data
          : err;
        setAddFolderLoading(false);
        //(message);
        showAlert(message, "danger");
        setAddFolderLoading(false);
      }
    }
  };
  const changeFolderPrivacy = async (id) => {
    setAddFolderLoading(id);
    try {
      const response = await api.put(`/folder/updatePrivacy/${id}`);
      showAlert("Privacy Updated successfully", "success");
      dispatch(updateFolder(response.data));
      setAddFolderLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddFolderLoading(false);
      //(message);
      showAlert(message, "danger");
      setAddFolderLoading(false);
    }
  };
  const changeFolderName = async (id, name) => {
    // if (!(await UpdateAlert())) return;
    // if (await UpdateAlert()) {

    // }
    setAddFolderLoading(id);
    try {
      const response = await api.put(`/folder/update/${id}`, { name });
      showAlert("Updated  successfully", "success");
      dispatch(updateFolder(response.data));
      setAddFolderLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddFolderLoading(false);
      //(message);
      showAlert(message, "danger");
      setAddFolderLoading(false);
    }
  };
  return {
    documents,
    stats,
    page,
    limit,
    loading,
    deleteLoading,
    addLoading,
    docData,
    setDocData,
    setPage,
    showAddModal,
    setShowAddModal,
    fetchFolders,
    fetchStats,
    AddNewDocument,
    fetchDocuments,
    changeDocument,
    removeDocument,
    AddFolders,
    changeFolderName,
    removeFolder,
    modalName,
    setModalName,
    deleteLoading,
    setLoading,
    addFolderLoading,
    changeFolderPrivacy
  };
};
