import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chats: [],
    messages: [],
  },
  reducers: {
    setChat: (state, action) => {
      state.chats = action.payload;
    },
    updateChat: (state, action) => {
      state.chats = state.chats.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteChat: (state, action) => {
      state.chats = state.chats.filter(
        (item) => item._id != action.payload._id
      );
      state.chats = state.chats.map((item) =>
        item.name == "Default"
          ? { ...item, checklists: action.payload.checklists + item.checklists }
          : item
      );
    },
    addChat: (state, action) => {
      state.chats = [...state.chats, { ...action.payload, checklists: 0 }];
    },

    setMessage: (state, action) => {
      state.messages = action.payload;
    },
    updateMessagesStatus: (state, action) => {
      state.messages = state.messages.map((item) =>
        item.sender._id == action.payload.user._id
          ? {
              ...item,
              status:
                item.status == "Seen" ? item.status : action.payload.status,
            }
          : item
      );
    },
    deleteMessage: (state, action) => {
      state.messages = state.messages.filter(
        (item) => item._id != action.payload._id
      );
      state.messages = state.messages.map((item) =>
        item.name == "Default"
          ? { ...item, checklists: action.payload.checklists + item.checklists }
          : item
      );
    },
    addMessage: (state, action) => {
      state.messages = [action.payload, ...state.messages];
    },
  },
});

export const {
  setChat,
  updateChat,
  deleteChat,
  addChat,
  setMessage,
  updateMessagesStatus,
  deleteMessage,
  addMessage,
} = chatSlice.actions;

export default chatSlice.reducer;
