import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormField from "../../components/FormField";
import Loader from "../../components/Loader";
import SubmitButton from "../../components/SubmitButton";
import useCompanies from "../../hooks/useCompanies";

const Branches = ({ tab }) => {
    const {
        loading,
        data,
        setData,
    } = useCompanies();
    useEffect(() => {

    }, [tab]);
    const titleField = {
        value: data?.comment,
        setValue: (value) => setData({ ...data, comment: value }),
        placeholder: 'Enter comment',
        type: 'text',
        id: 'comment'
    }
    const [comments, setComments] = useState([
        {
            addedBy: {
                _id: '23424324342',
                role: 'admin'
            },
            createdAt: '04-11-2024T02:24:00:00',
            type: 'comment',
            body: "Comment added by users will be here. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            addedBy: {
                _id: '23424324342',
                role: 'companyAdmin'
            },
            dateAdded: '04-11-2024T02:24:00:00',
            type: 'comment',
            body: "Comment added by users will be here. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            addedBy: {
                _id: '23424324342',
                role: 'company'
            },
            dateAdded: '04-11-2024T02:24:00:00',
            type: 'comment',
            body: "Comment added by users will be here. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
    ])
    return (
        <div className="tab-pane fade pt-3" id="profile-comment">
            <div className="table-container">
                <Loader loading={loading} center>

                    {/* Add Comment */}
                    <div className="col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Add Comment</h5>
                                <div className="row">
                                    <div className="col-md-9 col-9">
                                        <FormField item={titleField} />
                                    </div>
                                    <div className="col-md-3 col-3">
                                        <div>
                                            <label className="btn btn-secondary w-100" htmlFor="files">
                                                {data.files ? `${data.files.length} files selected` : 'Browse'}
                                            </label>
                                            <input
                                                type="file"
                                                id="files"
                                                multiple
                                                max={5}
                                                className="hidden"
                                                // accept={allowed}
                                                onChange={(e) => {
                                                    const selectedFiles = Array.from(e.target.files);
                                                    setData({
                                                        ...data,
                                                        file: e.target.files[0],
                                                        name: e.target.files[0].name,
                                                        files: selectedFiles,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center mb-2 mt-4">
                                    <SubmitButton
                                        title={"Add comment"}
                                        onClick={() => { }}
                                        color={"success"}
                                        loaderColor={"white"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** Comments */}
                    {comments?.map((item, index) => (
                        <div key={index} className="col-md-12 col-12">
                            <div className="card pt-4">
                                <div className="card-body">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}><h5 style={{ padding: '0' }} className="card-title">Added By: </h5><h5 className="mx-2">{item.addedBy?.role}</h5></Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}><h5 style={{ padding: '0' }} className="card-title">Date Added: </h5><h5 className="mx-2">{item.createdAt?.substring(0, 10)}</h5></Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}><h5 style={{ padding: '0' }} className="card-title">Type: </h5><h5 className="mx-2">{item.type}</h5></Box>
                                    <h5 className="">{item.body}</h5>
                                    <Link to="/">View attachments</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                    
                </Loader>
            </div>
        </div>
    );
};

export default Branches;
