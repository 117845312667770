import React, { Suspense, lazy, useEffect, useState } from "react";

import { frequency as frequencyOptions } from "../../config/constants";

import useAuth from "../../hooks/useAuth";
import useRecords from "../../hooks/useRecords";

import Screen from "../../components/Screen";
// import Table from "../../components/Table";
const Table = lazy(() => import("../../components/Table"));
const DownloadPopup = lazy(() => import("../../components/DownloadPopup"));
// const LazyComponent = lazy(() => import('./LazyComponent'));
const Records = () => {
  const [frequency, setFrequency] = useState("All");
  const { records, loading, limit, page, fetchRecords, setPage } = useRecords();
  const { isAdmin, user } = useAuth();
  const [checklist, setChecklist] = useState();
  const [filter, setFilter] = useState({
    company: "",
    branch: "",
    isRiskAssesment: "checklist",
  });
  const params = {
    page,
    limit,
    ...(frequency == "All" ? {} : { checklistFrequency: frequency }),
    company: filter.company,
    branch: filter.branch,
    isRiskAssesment: filter.isRiskAssesment,
  };

  useEffect(() => {
    fetchRecords(params);
  }, [page, filter, frequency]);

  useEffect(() => {
    setPage(1);
    // fetchRecords({ ...params });
  }, [frequency]);

  const labels = [
    "#",
    "Title",
    "Frequency",
    "Task Submissions",
    isAdmin ? "Company" : null,
    "Action",
  ].filter((item) => item);
  const data = records.map((item, index) => {
    const company = item?.companyID?.name;
    const branch = item?.userID?.branchID?.name;
    return {
      data: item,
      fields: [
        `#${item?._id.substr(item?._id.length - 5)}`,
        item.title,
        item.checklistFrequency || item.riskAssesmentFrequency || item.auditFrequency,
        item.tasks?.length ? item.tasks?.length : 0,
        isAdmin ? <td>{user?.name == company ? "" : company}</td> : null,
        <td
          className="text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => setChecklist(item)}
        >
          <i className="bi bi-cloud-download"></i> Download
        </td>,
      ].filter((it) => it),
    };
  });

  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setFilter}
      showBranchFilter={true}
      flow={[
        { label: `Records`, to: "/records" },
        { label: "Home", to: "/" },
        { label: `Records`, to: "/records" },
      ]}
    >
      <section className="section dashboard">
        <div className="row">
          <div className="col-12">
          <Suspense fallback={<div>Loading...</div>}>
            <Table
              title={
                filter.isRiskAssesment == "checklist"
                  ? "Checklist Records"
                  : "Risk Assessment Records"
              }
              className={"recent-sales overflow-auto"}
              labels={labels}
              data={data}
              limit={limit}
              page={page}
              setPage={setPage}
              options={["All", ...frequencyOptions]}
              option={frequency}
              setOption={setFrequency}
            />
            </Suspense>
          </div>
        </div>
      </section>
      <Suspense fallback={<div>Loading...</div>}>
        <DownloadPopup
          checklist={checklist}
          branch={filter.branch}
          handleClose={() => setChecklist(undefined)}
          isRiskAssesment={filter.isRiskAssesment}
        />
      </Suspense>
    </Screen>
  );
};

export default Records;
