import { Box, Typography } from "@mui/material";
import { default as React, useEffect } from "react";
import CSVReader from "react-csv-reader";
import "react-quill/dist/quill.snow.css";
import FormField from "../../components/FormField";
import SubmitButton from "../../components/SubmitButton";
import EmailList from "./emailList";
import { useSelector } from "react-redux";
export default function Default({
  addEmailField,
  addNameField,
  data,
  addLoading,
  addEmails,
  handleRemoveEmail,
  loading,
  handleCsvFile,
  deleteEmail,
  handleEmailUpdate,
  isUpdate,
  setisUpdate,
  updateEmail,
  setdata,
}) {
  const { email } = useSelector((state) => state.Email);
  useEffect(() => {
    return () => {
      setisUpdate(false);
      setdata({ newEmail: "",name:"", importedEmails: [] });
    };
  }, []);

  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "block",
          md: "flex",
          lg: "flex",
        },
        justifyContent: "space-between",
      }}
    >
      <div className="col-md-12 col-lg-6">
        <EmailList
          emails={email}
          deleteEmail={deleteEmail}
          handleEmailUpdate={handleEmailUpdate}
        />
      </div>
      <div className="col-md-12 col-lg-5">
        <div className="card">
          <div className="card-body">
            <div className="col-md-12 col-12">
              <h5 className="card-title">Add Email</h5>
              <div className="d-flex">
                <FormField
                  item={addEmailField}
                  disabled={data.importedEmails?.length > 0}
                />
                <FormField
                  item={addNameField}
                  disabled={data.importedEmails?.length > 0}
                />
              </div>
              <SubmitButton
                style={{ width: "100%", marginTop: "10px" }}
                title={!isUpdate ? "Add" : "Update"}
                loading={addLoading}
                onClick={() => {
                  if (isUpdate) {
                    updateEmail();
                  } else {
                    addEmails();
                  }
                }}
                color={"success"}
                loaderColor={"white"}
                disabled={
                  (data.newEmail == ""&&
                  data.importedEmails?.length < 1) ||
                  (data.name == "" &&
                  data.importedEmails?.length < 1) 
                }
              />
              {/* </div> */}
              {data.importedEmails?.length > 0 && (
                <Box
                  sx={{
                    maxHeight: "100px",
                    overflow: "auto",
                    marginTop: "15px",
                    border: "1px solid #00000012",
                    padding: "5px",
                  }}
                >
                  {data.importedEmails?.map((item) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#00000012",
                        padding: "3px 6px",
                        margin: "5px 0px",
                        borderRadius: "8px",
                      }}
                    >
                      <Box>
                        <Typography>
                          <b>Email: </b>
                          {item.email}
                        </Typography>
                        <Typography>
                          <b>Name: </b>
                          {item.name}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveEmail(item)}
                      >
                        x
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              <div
                style={{
                  position: "relative",
                }}
              >
                <label
                  className="d-flex justify-content-center align-items-center btn flex flex-col items-center justify-center p-4 border-dashed border-2 border-gray-300 rounded-md"
                  style={{ height: "fit-content" }}
                >
                  {/* <InboxOutlined style={{ fontSize: "30px" }} /> */}
                  <SubmitButton
                    style={{ width: "100%", marginTop: "15px" }}
                    title={"Import Bulk"}
                    loading={loading}
                    color={"success"}
                    loaderColor={"white"}
                    disabled={isUpdate}
                  />
                  <CSVReader
                    inputId="CSVReader"
                    inputStyle={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                      opacity: 0,
                    }}
                    onFileLoaded={handleCsvFile}
                    disabled={isUpdate}
                    parserOptions={{ header: true, skipEmptyLines: true }}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
