import React, { useState } from 'react'
import { keys } from '../config/keys';
import FormField from './FormField';
import api from '../app/api';
import { Box, Button, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
export default function Default({ item, isBoolean, getAnswer, setAnswer, disabled, setComment, setAttachment, data, getComment, removeAtachment }) {
    const [showComment, setshowComment] = useState(false)
    return (
        <div
            className={`card col-12 ${(item.type == "signature" || item.type == 'statement' || item.type == 'statement/Instruction' || item.questionAttachments != null)
                ? "col-lg-12 col-xl-12"
                : "col-lg-5 col-xl-5 "
                }`}
        >
            <div
                className={
                    "card-body" +
                    (isBoolean
                        ? " d-flex flex-column justify-content-center align-items-center h-100"
                        : "")
                }
            >
                <div
                    className={
                        isBoolean
                            ? "d-flex justify-content-between align-items-center w-100"
                            : ""
                    }
                >
                    <h5
                        style={{ textAlign: 'justify' }}
                        className={`${(item.type != 'statement' && item.type != 'statement/Instruction') ? 'card-title' : 'mt-4'} d-flex`}
                        htmlFor={isBoolean ? "flexSwitchCheckDefault" : ""}
                    >
                        {item.question}
                        {item.required ? (
                            <span className="text-danger"> *</span>
                        ) : null}
                    </h5>
                    <Grid container sx={{ marginBottom: "20px" }}>
                        {item?.questionAttachments?.length > 0 && item.questionAttachments?.map((item1, index) => (
                            <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
                                <img src={`${keys.BASE_LOCAL_URL}/${item1}`} alt="..." width={"300px"} height={"300px"} />
                            </Grid>
                        ))}
                    </Grid>
                    <div className="d-flex align-items-center">
                        <FormField
                            item={{
                                value: [getAnswer(item._id)],
                                setValue: (value, checkbox) => {
                                    setAnswer(item._id, value, checkbox);
                                },
                                type: item.type,
                                options: item.options,
                            }}
                            disabled={disabled}
                        />
                    </div>
                    {((data.auditID || data.auditFrequency) && item.type != 'statement/Instruction') && <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button onClick={() => setshowComment(!showComment)} variant="outlined">Add Comment</Button>
                        {/* <Button variant="outlined">Add Media</Button> */}
                        <div>
                            <label className="btn btn-outline-primary" htmlFor={`answerAttachments_${item._id}`}>
                                Upload image{'(s)'}
                            </label>
                            <input
                                type="file"
                                id={`answerAttachments_${item._id}`}
                                multiple
                                max={5}
                                className="hidden"
                                accept={".png, .jpg, .jpeg"}
                                onChange={async (e) => {
                                    const selectedFiles = Array.from(e.target.files);
                                    let urls = []
                                    await Promise.all(
                                        selectedFiles?.map(async item => {
                                            const formData = new FormData();
                                            formData.append("image", item);
                                            let response = await api.post('/file/upload', formData);
                                            urls.push(response.data.result)
                                        }))
                                    setAttachment(item._id, urls);
                                }}
                            />
                        </div>
                    </Box>}
                    {showComment && <div className="d-flex align-items-center mt-2">
                        <FormField
                            item={{
                                value: [getComment(item._id)],
                                setValue: (value) => {
                                    setComment(item._id, value);
                                },
                                type: 'text',
                                placeholder: 'Add Comment',
                            }}
                            disabled={disabled}
                        />
                    </div>}
                    <Grid container sx={{ marginTop: "20px" }}>
                        {item?.answerAttachments?.length > 0 && item.answerAttachments?.map((item1, index) => (
                            <Grid key={index} sx={{ position: 'relative' }} item xs={12} sm={12} md={6} lg={4}>
                                <CloseIcon sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    cursor: 'pointer',
                                    color: 'black',
                                    fontSize: '30px',
                                }} onClick={() => {
                                    removeAtachment(item._id, item1)
                                    let block = document.getElementById(`answerAttachments_${item._id}`)
                                    block.value = ""
                                }} />
                                <img src={`${keys.BASE_LOCAL_URL}/${item1}`} alt="..." width={"300px"} height={"300px"} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    )
}
