import { Box, Typography, Pagination as PaginationMUI } from '@mui/material'
import React from 'react'

const Pagination = ({ data, page, limit, setPage, setLimit, totalData }) => {
    const handleChange = (event, value) => {
        setPage(value);
        // Call a function to fetch new data based on the selected page
    };
    return (
        <>
            <Box sx={{
                display: setLimit && 'flex',
                justifyContent: setLimit && 'space-between',
                alignItems: setLimit && 'center',
                color: setLimit && '#68798D',
                padding: setLimit && '25px 0px',
                gap: setLimit && '15px'
            }}>
                {setLimit && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <Typography sx={{ whiteSpace: 'nowrap' }}>Page size:</Typography>
                    <select className="form-select" style={{ marginLeft: '10px' }} onChange={(e) => setLimit(e.target.value)} value={limit}>
                        <option value={'10'}>10</option>
                        <option value={'20'}>20</option>
                        <option value={'50'}>50</option>
                        <option value={'100'}>100</option>
                        <option value={'200'}>200</option>
                        <option value={'300'}>300</option>
                        <option value={'500'}>500</option>
                        <option value={'1000'}>1000</option>
                    </select></div>
                }
                {!totalData && <div className='d-flex justify-content-end align-items-center gap-3 mt-2'>
                    <button
                        type="button"
                        disabled={page == 1} 
                        className="btn btn-outline-primary"
                        onClick={page == 1 ? () => { } : () => setPage(page - 1)}
                    >{'<'}</button>
                    <span>{page}</span>
                    <button
                        type="button"
                        disabled={data?.length < limit}
                        className="btn btn-outline-primary"
                        onClick={data?.length < limit ? () => { } : () => setPage(page + 1)}
                    >{'>'}</button>
                </div>}
            </Box>
            {totalData && <Box display="flex" justifyContent="center">
                <PaginationMUI
                    count={Math.ceil(totalData / limit)}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                    shape="rounded"   // Optional: Makes the pagination buttons rounded
                    size="large"      // Optional: Can also use "small" or "medium" sizes
                    showFirstButton   // Optional: Show the first button
                    showLastButton    // Optional: Show the last button
                />
            </Box>}
        </>
    )
}

export default Pagination