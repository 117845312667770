import { createSlice } from "@reduxjs/toolkit";

const msDocumentsSlice = createSlice({
  name: "msDocuments",
  initialState: {
    msDocuments: [],
    totalMsDocs: 0
  },
  reducers: {
    setTotalMsDocs: (state, action) => {
      state.totalMsDocs = action.payload;
    },
    deleteTotalMsDocs: (state) => {
      state.totalMsDocs = 0
    },
    setMsDocuments: (state, action) => {
      state.msDocuments = action.payload;
    },
    addMsDocument: (state, action) => {
      state.msDocuments = [...state.msDocuments, ...action.payload];
    },
    deleteMsDocument: (state, action) => {
      state.msDocuments = state.msDocuments.filter(
        (item) => item._id != action.payload._id
      );
    },
    updateMsDocument: (state, action) => {
      state.msDocuments = state.msDocuments.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
  },
});
export const { setMsDocuments,deleteMsDocument,addMsDocument,updateMsDocument,setTotalMsDocs,deleteTotalMsDocs } = msDocumentsSlice.actions;
export default msDocumentsSlice.reducer;
