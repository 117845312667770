import React from 'react'
import Screen from '../../components/Screen'
import PolicyAddOptions from "./policyAddOptions"
export default function Default() {
  return (
    <Screen flow={[
        { label: "New Policy", to: "/newPolicyOption" },
        { label: "Home", to: "/" },
        { label: "New Policy", to: "/newPolicyOption" },
      ]}>
        <PolicyAddOptions />
    </Screen>
  )
}
