export const library = [
    {
        title: 'Policies and Procedures',
        icon: <i className="bi bi-folder"></i>
    },
    {
        title: 'Staff Handbooks',
        icon: <i className="bi bi-folder"></i>
    },
    {
        title: 'Training Records',
        icon: <i className="bi bi-folder"></i>
    },
    {
        title: 'Supplier Details',
        icon: <i className="bi bi-folder"></i>
    },
    {
        title: 'Risk Assessments',
        icon: <i className="bi bi-folder"></i>
    },
    {
        title: 'Miscellaneous',
        icon: <i className="bi bi-folder"></i>
    },
]

export const frequency = ['Daily', 'Weekly', 'Monthly', 'Annual']