import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    subscriptions: [],
    // totalSubscriptions:0
  },
  reducers: {
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    updateSubscription: (state, action) => {
      state.subscriptions = state.subscriptions.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    removeSubscription: (state, action) => {
      state.subscriptions = state.subscriptions.filter(
        (item) => item._id != action.payload._id
      );
    },
    addSubscription: (state, action) => {
      state.subscriptions = [...state.subscriptions, action.payload];
    }
  },
});

export const {
    setSubscriptions,
    updateSubscription,
    removeSubscription,
    addSubscription,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
