import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
    name: "company",
    initialState: {
        companies: [],
        branches: [],
        managers: [],
    },
    reducers: {
        // Companies
        set: (state, action) => {
            const field = action.payload.type;
            const data = action.payload.data;
            state[field] = data;
        },
        update: (state, action) => {
            const field = action.payload.type;
            const data = action.payload.data;
            state[field] = state[field].map(item => item._id == data._id ? data : item);
        },
        remove: (state, action) => {
            const field = action.payload.type;
            const data = action.payload.data;
            state[field] = state[field].filter(item => item._id != data._id);
        },
        add: (state, action) => {
            const field = action.payload.type;
            const data = action.payload.data;
            state[field] = [...state[field], data];
        },
    },
});

export const {
    set,
    update,
    remove,
    add,
} = companySlice.actions;

export default companySlice.reducer;
