import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";

import useCompanies from '../hooks/useCompanies';
import useTask from '../hooks/useTask';
import useAuth from '../hooks/useAuth';

import Table from './Table';
import Loader from './Loader';
import FormField from './FormField';
import SubmitButton from './SubmitButton';
import api from '../app/api';
import { useLocation } from 'react-router-dom';

const UserModal = ({ show, handleClose, checklist, isRiskAssesment, isAudit }) => {
    const labels = ['#', 'Name', 'Position', 'Site Name', 'Actions'];
    const { isCompany } = useAuth()
    const [data, setData] = useState([])
    const findExsisting = async (userID, checklistID) => {
        try {
            const response = await api.post(`/task/findTaskAssignment?userID=${userID}&checklistID=${checklistID}&taskFor=${isRiskAssesment ? 'riskAssesment' : isAudit ? 'audit' : 'checklist'}`)
            return response.data.result
        } catch (error) {
            return false
        }
    }
    const {
        managers,
        branches,
        loading,
        fetchEmployees,
        setLoading
    } = useCompanies();
    const { addLoading, setAddLoading, createTask, unAssignTask } = useTask();

    const [position, setPosition] = useState('Supervisor');

    const field = {
        placeholder: 'Select Position',
        value: position,
        setValue: (value) => setPosition(value),
        type: 'dropdown',
        options: ['Supervisor', 'Manager'],
        className: 'col-4 mb-0'
    }
const location = useLocation()

    const companyID = show?.companyID?.id;
    useEffect(() => {
        if (show) fetchEmployees(companyID, { position }, location.pathname)
    }, [show, position])

    const parseData = async () => {
        await Promise.all(
            managers
                ?.map(async (item, index) => {
                    const checklistID = show?._id;
                    const userID = item._id;
                    const assigned = await findExsisting(userID, checklist._id)
                    return {
                        data: item,
                        fields: [
                            index + 1,
                            item.name,
                            item.position,
                            branches?.find(b => b._id == item.branchID)?.name,
                            <SubmitButton
                            disabled={addLoading != false}
                                style={{ fontSize: '12px' }}
                                title={assigned == true ? 'Un-Assign' : 'Assign'}
                                loading={addLoading == userID}
                                color={'primary'}
                                loaderColor={'white'}
                                onClick={() => {
                                    setAddLoading(userID)
                                    if (assigned) {
                                        unAssignTask(userID, checklistID, isRiskAssesment ? 'riskAssesment' : isAudit ? 'audit':'checklist')
                                    } else {
                                        createTask({
                                            ...checklist,
                                            ...(isRiskAssesment ? { riskAssesmentID: checklistID } : isAudit ? { auditID: checklistID } : { checklistID }),
                                            companyID,
                                            userID
                                        })
                                    }
                                }
                                }
                            />,
                        ]
                    }
                })).then((tempData)=>{
                    setLoading(false)
                    setData(tempData)
                })
    }
    useEffect(() => {
        if(managers.length > 0){
            parseData()
        }
    }, [managers, addLoading])

    return (
        <Modal show={show} centered onHide={handleClose}>
            <Modal.Header>
                <div className='row w-100'>
                    <Modal.Title className={isCompany ? 'col-8' : 'col-12'}>Assign Task</Modal.Title>
                    {isCompany ? <FormField item={field} /> : null}
                </div>
            </Modal.Header>
            <Modal.Body>
                <Loader loading={loading} center >
                    <Table labels={labels} data={data} />
                </Loader>
            </Modal.Body>
        </Modal>
    )
}

export default UserModal