import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: {
    show: false,
    type: '',
    body: '',
  },
  reducers: {
    onShow: (state, action) => {
      state.show = true;
      state.type = action.payload.type;
      state.body = action.payload.body;
    },
    onDismiss: (state) => {
      state.show = false;
      state.type = '';
      state.body = '';
    },
  },
});

export const { onShow, onDismiss } = alertSlice.actions;

export default alertSlice.reducer;
