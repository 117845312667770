import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    branches: [],
    teamMember: [],
  },
  reducers: {
    setBranches: (state, action) => {
      state.branches = action.payload;
    },
    updateBranch: (state, action) => {
      state.branches = state.branches.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteBranch: (state, action) => {
      state.branches = state.branches.filter(
        (item) => item._id != action.payload._id
      );
    },
    addBranch: (state, action) => {
      state.branches = [...state.branches, action.payload];
    },
    setTeamMember: (state, action) => {
      state.teamMember = action.payload;
    },
    updateTeamMember: (state, action) => {
      state.teamMember = state.teamMember.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteTeamMember: (state, action) => {
      state.teamMember = state.teamMember.filter(
        (item) => item._id != action.payload._id
      );
    },
    addTeamMember: (state, action) => {
      state.teamMember = [...state.teamMember, action.payload];
    },
  },
});

export const {
  addTeamMember,
  deleteTeamMember,
  updateTeamMember,
  setTeamMember,
  addBranch,
  deleteBranch,
  updateBranch,
  setBranches,
} = profileSlice.actions;

export default profileSlice.reducer;
