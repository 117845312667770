import React, { useEffect, useState } from 'react'
import Screen from '../../components/Screen'
import SubmitButton from '../../components/SubmitButton'
import Loader from '../../components/Loader';
import useAuth from '../../hooks/useAuth';
import api from '../../app/api';
import useAlert from '../../hooks/useAlert';
import { keys } from '../../config/keys';
import { Box, Typography } from '@mui/material';
import { Card, CardBody, CardHeader } from 'react-bootstrap';
import { DeleteAlert } from '../../components/Swal Alert';
import EmptyData from '../../components/EmptyData';

export default function Default() {
  const [loading, setloading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [data, setData] = useState({
    pending:[],
    available:[]
  })
  const { user, onUser } = useAuth()
  const { showAlert } = useAlert()
  const handleConnect = () => {
    console.log("here")
    window.location.href = `${keys.BASE_LOCAL_URL}/stripe/connect?adminId=${localStorage.getItem('_id')}`; // Redirect to Stripe OAuth
  };
  const getData = async () => {
    try {
      setloading(true)
      const response = await api.get(`/stripe/getAdminStripeInfo?id=${localStorage.getItem("_id")}`)
      if (response.data.status) {
        // showAlert('Stripe account disconnected.', "success")
        setData(response.data.result.balance)
      } else {
        showAlert(response.data.message, "danger")
      }
      setloading(false)
    } catch (error) {
      setloading(false)
    }
  }
  const handleDisconnect = async () => {
    if (!await DeleteAlert("You want to disconnect stripe account!")) return
    try {

      setButtonLoading(true)
      const response = await api.put(`/companyAdmin/removeStripe?id=${localStorage.getItem("_id")}`)
      console.log(response)
      if (response.data.status) {
        showAlert('Stripe account disconnected.', "success")
        setloading(true)
        onUser()
      } else {
        showAlert(response.data.message.message, "danger")
      }
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(message, "danger");
    } finally {
      setButtonLoading(false)
      setloading(false)
    }
  }
  useEffect(() => {
    if(user.stripeConnectID){
      getData()
    }
  }, [])

  return (
    <Screen loading={loading} flow={[
      { label: 'Stripe Connect', to: '/connect-stripe' },
      { label: 'Home', to: '/' },
      { label: 'Stripe Connect', to: '/connect-stripe' },
    ]}>
      <Typography variant='h5'>Pending Balance</Typography>
      <Card>
        <CardHeader>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>Currency</Typography>
            <Typography>Amount</Typography>
          </Box>
        </CardHeader>
        <CardBody>
          <EmptyData data={data?.pending}>
            {data?.pending?.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                <Typography>{item.currency.toUpperCase()}</Typography>
                <Typography>{item.currency.toUpperCase() + " "}{item.amount / 100}</Typography>
              </Box>
            ))}
          </EmptyData>
        </CardBody>
      </Card>
      <Typography variant='h5'>Available Balance</Typography>
      <Card>
        <CardHeader>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>Currency</Typography>
            <Typography>Amount</Typography>
          </Box>
        </CardHeader>
        <CardBody>
        <EmptyData data={data?.available}>
          {data?.available?.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
              <Typography>{item.currency.toUpperCase()}</Typography>
              <Typography>{item.currency.toUpperCase() + " "}{item.amount / 100}</Typography>
            </Box>
          ))}
          </EmptyData>
        </CardBody>
      </Card>
      <button className='btn btn-primary w-100 mt-3 mb-3' onClick={!user.stripeConnectID ? handleConnect : handleDisconnect}>
        <Loader loading={buttonLoading} color={'white'}>
          {user.stripeConnectID ? 'Disconnect stripe' : 'Connect stripe'}
        </Loader>
      </button>
    </Screen>
  )
}
