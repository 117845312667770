import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useChat from "../../hooks/useChat";
import { keys } from "../../config/keys";
import appContext from "../../app/contextState/context";
import { Box } from "@mui/material";

const SidebarItem = ({ item, setChat }) => {
  const { updateMessages } = useChat();
  const { setShowMessageSidebar } = useContext(appContext);
  return (
    <Box
      sx={{
        borderBottom: "1px solid #00000012",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
      }}
    >
      <Link
        className="d-flex gap-2 align-items-center justify-content-start rounded-2 p-2 mt-3 text-black"
        to={`/inbox?id=${item._id}`}
        style={{
          borderBottom: "1px solid #00000012",
        }}
        onClick={() => {
          setChat(item);
          updateMessages(item._id, "Seen");
          setShowMessageSidebar(false);
        }}
      >
        <div>
          <img
            src={
              item.title?.imageURL
                ? `${keys.BASE_LOCAL_URL}/${item.title?.imageURL}`
                : "assets/img/user.png"
            }
            className="rounded rounded-circle shadow "
            style={{
              objectFit: "cover",
              height: 50,
              width: 50,
            }}
          />
        </div>
        <div className="d-flex w-100 flex-column gap-1 ">
          <div className="d-flex align-items-center justify-content-between">
            <span
              style={{
                maxWidth: "50%",
                textOverflow: "ellipsis",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {item.title?.name}
            </span>
            {!item.lastMessage ? null : (
              <span>
                {new Date(item.lastMessage?.createdAt).toLocaleDateString(
                  "en-GB"
                )}
              </span>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span
              style={{
                maxWidth: "50%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {item.lastMessage?.text}
            </span>
            {!item?.unseen ? null : (
              <span
                className="bg-primary rounded-circle text-white text-center"
                style={{ height: 24, width: 24 }}
              >
                {item?.unseen}
              </span>
            )}
          </div>
        </div>
      </Link>
    </Box>
  );
};

export default SidebarItem;
