import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"

import api from "../app/api";
import useAlert from "./useAlert";
import useAuth from "./useAuth";

import { setSumamry, updateCompany } from '../features/dashboard/slice';
import { UpdateAlert } from "../components/Swal Alert";

export default () => {
    const dispatch = useDispatch();
    const { summary } = useSelector(state => state.dashboard);
    const { user, isAdmin, isEmployee, isManager, permissions } = useAuth();
    const { showAlert } = useAlert()
    const [loading, setLoading] = useState(false);
    const cards = [

        !isAdmin ? null : {
            title: 'Registered Companies',
            subtitle: `${summary?.companies?.filter(item => item.status !== true && item.status !== false).length} Pending Registration`,
            value: summary?.companies?.length,
            className: 'sales',
            icon: <i className="bi bi-building"></i>,
            to: '/companies'
        },
        (isEmployee && !isManager) ? null : (permissions?.routes.includes('/tasks')) && ({
            title: 'Checklists',
            subtitle: 'Add New Checklist',
            value: summary?.checklists,
            className: 'revenue',
            icon: <i className="bi bi-card-checklist"></i>,
            to: '/checklists'
        }),
        (isEmployee && !isManager) ? null : (permissions?.routes.includes('/risk-assesments')) && ({
            title: 'Risk Assessment',
            subtitle: 'Add New Risk Assessment',
            value: summary?.riskAssesments,
            className: 'revenue',
            icon: <i className="bi bi-card-checklist"></i>,
            to: '/risk-assesments'
        }),
        !isAdmin ? ((permissions && permissions.routes.length > 0) && (
            (permissions.routes.includes('/tasks')) && (
                {
                    title: 'Tasks Pending',
                    subtitle: 'Show all Tasks',
                    value: summary?.tasks,
                    className: 'customers',
                    icon: <i className="bi bi-file-check"></i>,
                    to: '/tasks?frequency=Daily'
                }
            )
        )) : ({
            title: 'Tasks Pending',
            subtitle: 'Show all Tasks',
            value: summary?.tasks,
            className: 'customers',
            icon: <i className="bi bi-file-check"></i>,
            to: '/tasks?frequency=Daily'
        }
        ),
        !isAdmin ? ((permissions && permissions.routes.length > 0) && (
            (permissions.routes.includes('/risk-assesments')) && (
                {
                    title: 'Risk Assessment Pending',
                    subtitle: 'Show all Risk Assessment',
                    value: summary?.riskAssesmentTasks,
                    className: 'customers',
                    icon: <i className="bi bi-file-check"></i>,
                    to: '/tasks?frequency=Daily'
                }
            )
        )) : ({
            title: 'Risk Assessment Pending',
            subtitle: 'Show all Risk Assessment',
            value: summary?.riskAssesmentTasks,
            className: 'customers',
            icon: <i className="bi bi-file-check"></i>,
            to: '/tasks?frequency=Daily'
        }
        ),
        !isAdmin ? (permissions && permissions.routes.length > 0) && (
            (permissions.routes.includes('/documents')) && (
                (isEmployee && !isManager) ? null : {
                    title: 'Documents Uploaded',
                    subtitle: 'View Library',
                    value: summary?.documents,
                    className: 'sales',
                    icon: <i className="bi bi-files"></i>,
                    to: '/documents'
                }
            )
        ) : {
            title: 'Documents Uploaded',
            subtitle: 'View Library',
            value: summary?.documents,
            className: 'sales',
            icon: <i className="bi bi-files"></i>,
            to: '/documents'
        }
        ,
        !isAdmin ? (permissions && permissions.routes.length > 0) && (
            (permissions.routes.includes('/records')) && (
                {
                    title: 'Records Submitted',
                    subtitle: 'View all Records',
                    value: summary?.records,
                    className: 'revenue',
                    icon: <i className="bi bi-clipboard-data"></i>,
                    to: '/records'
                }
            )
        ) :
            {
                title: 'Records Submitted',
                subtitle: 'View all Records',
                value: summary?.records,
                className: 'revenue',
                icon: <i className="bi bi-clipboard-data"></i>,
                to: '/records'
            }
        ,
        !isAdmin ? (permissions && permissions.routes.length > 0) && (
            (permissions.routes.includes('/risk-assesments')) && (
                {
                    title: 'Risk Assessment Submitted',
                    subtitle: 'View all Records',
                    value: summary?.riskAssesmentRecords,
                    className: 'revenue',
                    icon: <i className="bi bi-clipboard-data"></i>,
                    to: '/records'
                }
            )
        ) :
            {
                title: 'Risk Assessment Submissions',
                subtitle: 'View all Records',
                value: summary?.riskAssesmentRecords,
                className: 'revenue',
                icon: <i className="bi bi-clipboard-data"></i>,
                to: '/records'
            }
        ,

    ].filter(item => item)

    const fetchSummary = async () => {
        setLoading(true);
        try {
            const response = await api.get('/dashboard/summary');
            dispatch(setSumamry(response.data))
            setLoading(false);
        } catch (err) {
            const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
            setLoading(false);
            showAlert(message, 'danger');
            setLoading(false);
        }
    }

    const changeCompanyStatus = async (item) => {
        if (!(await UpdateAlert())) return;
        try {
            const response = await api.put(`/company/update/${item._id}`, item);
            dispatch(updateCompany(response.data));
            showAlert("Updated company successfully", "success");
        } catch (err) {
            const message = err.response
                ? err.response.data?.message
                    ? err.response.data?.message
                    : err.response.data
                : err;
            //(message);
            showAlert(message, "danger");
        }
    };

    return {
        summary,
        cards,
        loading,
        fetchSummary,
        changeCompanyStatus
    }
}