import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import useTask from "../../hooks/useTask";

import DownloadPopup from "../../components/DownloadPopup";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import TaskAnswer from "../../components/taskAnswer";
const Task = () => {
  const location = useLocation();
  const {
    data,
    loading,
    questions,
    deleteLoading,
    fetchTaskById,
    performTask,
    getAnswer,
    setAnswer,
    onDelete,
    setAttachment,
    setComment,
    getComment,
    removeAtachment
  } = useTask();
  const { isEmployee, isManager } = useAuth();
  const [task, setTask] = useState();
  const frequency = location?.search.split("?frequency=")[1];
  const disabled = false;
  const fresh = location.state?.fresh;
  const isRiskAssesment = location.state?.isRiskAssesment;
  const isAudit = location.state?.isAudit;

  useEffect(() => {
    fetchTaskById(location.state?._id, { fresh, isRiskAssesment, isAudit });
  }, []);

  return (
    <Screen
      flow={
        location.state?.notification || fresh
          ? [{ label: !data ? "" : `${data?.title}` }]
          : [
            { label: `${location?.state?.title}` },
            { label: "Home", to: "/" },
            {
              label: `${frequency}`,
              to: `/tasks${location?.search}`,
              state: location?.state,
            },
            {
              label: "Task",
            },
          ]
      }
    >
      <div className="d-flex flex-column align-items-end" id="parentTask">
        {(isEmployee && !isManager) || fresh ? null : (
          <div className="row d-flex justify-content-end mb-2">
            <SubmitButton
              icon={<i className="bi bi-trash3"></i>}
              loading={deleteLoading}
              onClick={() => onDelete(location.state?._id)}
              color={"danger"}
              loaderColor={"white"}
            />
          </div>
        )}
        <div className="row d-flex justify-content-evenly w-100" id="capture">
          {questions?.map((item, index) => {
            const isBoolean = item.type == "boolean";
            return (
              <TaskAnswer
                key={index}
                item={item}
                isBoolean={isBoolean}
                getAnswer={getAnswer}
                setAnswer={setAnswer}
                disabled={disabled}
                setComment={setComment}
                setAttachment={setAttachment}
                data={data}
                getComment={getComment}
                removeAtachment={removeAtachment}
              />
              // <div
              //   className={`card col-12 ${(item.type == "signature" || item.type == 'statement' || item.type == 'statement/Instruction' || item.questionAttachments != null)
              //     ? "col-lg-12 col-xl-12"
              //     : "col-lg-5 col-xl-5 "
              //     }`}
              // >
              //   <div
              //     className={
              //       "card-body" +
              //       (isBoolean
              //         ? " d-flex flex-column justify-content-center align-items-center h-100"
              //         : "")
              //     }
              //   >
              //     <div
              //       key={index}
              //       className={
              //         isBoolean
              //           ? "d-flex justify-content-between align-items-center w-100"
              //           : ""
              //       }
              //     >
              //       <h5
              //         style={{ textAlign: 'justify' }}
              //         className={`${(item.type != 'statement' && item.type != 'statement/Instruction') ? 'card-title' : 'mt-4'} d-flex`}
              //         htmlFor={isBoolean ? "flexSwitchCheckDefault" : ""}
              //       >
              //         {item.question}
              //         {item.required ? (
              //           <span className="text-danger"> *</span>
              //         ) : null}
              //       </h5>
              //       <Grid container sx={{ marginBottom: "20px" }}>
              //         {item?.questionAttachments?.length > 0 && item.questionAttachments?.map(item1 => (
              //           <Grid item xs={12} sm={12} md={6} lg={4}>
              //             <img src={`${keys.BASE_LOCAL_URL}/${item1}`} alt="..." width={"300px"} height={"300px"} />
              //           </Grid>
              //         ))}
              //       </Grid>
              //       <div className="d-flex align-items-center">
              //         <FormField
              //           item={{
              //             value: [getAnswer(item._id)],
              //             setValue: (value, checkbox) => {
              //               setAnswer(item._id, value, checkbox);
              //             },
              //             type: item.type,
              //             options: item.options,
              //           }}
              //           disabled={disabled}
              //         />
              //       </div>
              //       {item.answerAttachments && <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop:'20px' }}>
              //         <Button onClick={()=>setshowComment(!showComment)} variant="outlined">Add Comment</Button>
              //         {/* <Button variant="outlined">Add Media</Button> */}
              //         <div>
              //           <label className="btn btn-outline-primary" htmlFor="answerAttachments">
              //             Attach files
              //           </label>
              //           <input
              //             type="file"
              //             id="answerAttachments"
              //             multiple
              //             max={5}
              //             className="hidden"
              //             // accept={allowed}
              //             onChange={async (e) => {
              //               const selectedFiles = Array.from(e.target.files);
              //               let urls = []
              //               await Promise.all(
              //                 selectedFiles?.map(async item => {
              //                   const formData = new FormData();
              //                   formData.append("image", item);
              //                   let response = await api.post('/file/upload', formData);
              //                   urls.push(response.data.result)
              //                 }))
              //               setAttachment(item._id, urls);
              //             }}
              //           />
              //         </div>
              //       </Box>}
              //       {showComment && <div className="d-flex align-items-center mt-2">
              //         <FormField
              //           item={{
              //             value: [getAnswer(item._id)],
              //             setValue: (value) => {
              //               setComment(item._id, value);
              //             },
              //             type: 'text',
              //             placeholder: 'Add Comment',
              //           }}
              //           disabled={disabled}
              //         />
              //       </div>}
              //       <Grid container sx={{ marginTop: "20px" }}>
              //         {item?.answerAttachments?.length > 0 && item.answerAttachments?.map(item1 => (
              //           <Grid item xs={12} sm={12} md={6} lg={4}>
              //             <img src={`${keys.BASE_LOCAL_URL}/${item1}`} alt="..." width={"300px"} height={"300px"} />
              //           </Grid>
              //         ))}
              //       </Grid>
              //     </div>
              //   </div>
              // </div>
            );
          })}
        </div>
        {disabled ? (
          <button
            onClick={() => setTask(location?.state)}
            className="btn btn-primary"
          >
            {loading ? "Loading document..." : "Download now!"}
          </button>
        ) : (
          <SubmitButton
            title={
              data?.status == "Approved" ? "Update Answers" : "Submit Answers"
            }
            color={"primary"}
            loading={loading}
            onClick={() => performTask(fresh, isRiskAssesment, isAudit)}
            loaderColor={"white"}
          />
        )}
      </div>
      <DownloadPopup task={task} handleClose={() => setTask(undefined)} isRiskAssesment={isRiskAssesment} />
    </Screen>
  );
};

export default Task;
