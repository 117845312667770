import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import useBroadcast from '../../hooks/useBroadcast'

import Loader from '../../components/Loader'
import Screen from '../../components/Screen'
import Table from '../../components/Table'

const Broadcasts = () => {
    const {
        page,
        limit,
        deleteLoading,
        loading,
        broadcasts,
        setPage,
        fetchBroadcasts,
        removeBroadcast
    } = useBroadcast();
    const ref = useRef();

    const params = {
        page,
        limit,
    }

    const labels = [
        '#',
        'Message',
        'Sent To',
        'Date',
        'Time',
        'Actions'
    ];
    const data = broadcasts
        ?.map((item) => {
            return {
                data: item,
                fields: [
                    item?._id?.substr(item?._id.length - 5),
                    item.message,
                    `${item.all ? 'All' : item.companies?.length} Companies`,
                    new Date(item.createdAt).toLocaleDateString('en-GB'),
                    new Date(item.createdAt).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        hour12: true,
                        minute: '2-digit',
                    }),
                    <div className='d-flex gap-3'>
                        <Link to={`/broadcast-details?id=${item?._id}`}>
                            <i className="bi bi-eye text-primary" style={{ cursor: 'pointer' }}></i>
                        </Link>
                        <Loader loading={deleteLoading?._id == item._id} color='red'>
                            <i onClick={() => removeBroadcast(item)} className="bi bi-trash text-danger" style={{ cursor: 'pointer' }}></i>
                        </Loader>
                    </div>
                ]
            }
        })

    useEffect(() => {
        fetchBroadcasts(params)
    }, [page])

    return (
        <Screen loading={loading} flow={[
            { label: 'Broadcasts', to: '/broadcasts' },
            { label: 'Home', to: '/' },
            { label: 'Broadcasts', to: '/broadcasts' }
        ]}>
            <div ref={ref} className='row'>
                <div className='col-12 '>
                    <Link to={'/broadcast-details'} >
                        <button className='btn btn-primary mb-2'>
                            New Broadcast
                        </button>
                    </Link>
                    <Table
                        title={'All Broadcasts'}
                        data={data}
                        labels={labels}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            </div>
        </Screen>
    )
}

export default Broadcasts