import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSubscription from '../hooks/useSubscription';

const SubscriptionFilter = ({ subscription, selectSubscription ,className}) => {
    const { subscriptions } = useSelector((state) => state.subscription);
  const {  getData } = useSubscription();

  useEffect(() => {
    getData();
  }, [])

  return  (
    <select className={`form-select ${className}`} onChange={selectSubscription} aria-label="Default select example">
      <option value=''>Select Subscription</option>
      {
        subscriptions
          ?.map((item, index) => (
            <option
            key={index}
              value={item._id}
              selected={item._id == subscription}
              index={index}
            >
              {item.name}
            </option>
          ))
      }
    </select>
  )
}

export default SubscriptionFilter