import React, { useState } from 'react';

const Notification = ({ showNotification, setshowNotification, notificationData, onClick }) => {
    const [temp] = useState("Your notification message here");

    const closeNotification = (e) => {
        e.stopPropagation();
        setshowNotification(false);
    };

    return (
        <>
            {(showNotification) && <div style={{
                position: 'fixed',
                right: '10px',
                top: '70px',
                height: '80px',
                width: '250px',
                backgroundColor: 'white',
                boxShadow: '0px 0px 4px',
                borderRadius: '10px',
                zIndex: '111111',
                color: 'black',
                padding: '10px',
                fontSize: '12px',
                cursor: 'pointer',
                animation:'slideRight'
            }} onClick={() => { onClick(notificationData); setshowNotification(false) }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                }} onClick={closeNotification}>
                    X
                </div>
                {notificationData.title?.length < 50 ? notificationData.title : notificationData.title?.substring(0, 70) + '...'}
            </div>}
        </>
    );
};

export default Notification;
