import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// import useChecklist from '../../hooks/useChecklist'

import Pagination from "../../components/Pagination";
import Screen from "../../components/Screen";
import ChecklistCard from "../../components/ChecklistCard";
// import CategoryFilter from '../category/CategoryFilter'
import EmptyData from "../../components/EmptyData";
import useAuth from "../../hooks/useAuth";
import appContext from "../../app/contextState/context";
import { ChecklistContext } from "../../hooks/checklistContext";

const Collection = () => {
  const location = useLocation();
  const { collection } = useSelector((state) => state.checklist);
  const {user, isAdmin} = useAuth()
  const state = useContext(appContext);
  const { loading } = state;
  const { limit, page, fetchChecklists, setPage,setLimit } =
    useContext(ChecklistContext);
  const { isCompany, isManager } = useAuth();
  const [filter, setFilter] = useState({
    company: "",
    category: "",
  });

  const template = location?.search.split("?template=")[1];
  const review = location?.search.split("?review=")[1];

  const title = template ? "Templates" : review ? "Reviews" : "Checklist";
  const path = template
    ? "/checklists?template=true"
    : review
    ? "/checklists?review=true"
    : "/checklists";

  const params = {
    page,
    limit,
    company: filter.company,
    category: filter.category,
    ...(template ? { template: true } : {}),
    ...(review ? { status: "pending" } : {}),
    isCompany,
    isManager,
  };

  useEffect(() => {
    fetchChecklists(params);
  }, [filter, page, limit, review, template]);
  useEffect(() => {
    setPage(1);
  }, [template]);

  const data = collection;
  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setFilter}
      flow={[
        { label: title, to: path },
        { label: "Home", to: "/checklists" },
        { label: title, to: path },
      ]}
      dontShowCompany={template ? true : false}
      allowed={template && (user?.subscriptionID?.allowedCLTemplates != undefined && !user?.subscriptionID?.allowedCLTemplates && !isAdmin)}
    >
      <section className="section" id="checklist">
        <EmptyData data={data} onReload={() => fetchChecklists(params)}>
          <div className="row icon-boxes">
            {data?.map((item, index) => {
              return (
                <ChecklistCard
                  data={item}
                  key={index}
                  type="new"
                  template={template}
                  select={template}
                />
              );
            })}
          </div>
        </EmptyData>
        <Pagination
          data={collection}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </section>
    </Screen>
  );
};

export default Collection;
