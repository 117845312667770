import React from 'react'

import useAuth from '../../hooks/useAuth'
import SubmitButton from '../../components/SubmitButton';

const ReviewButtons = ({ loading, data, sendForReview, rejectReview, approveReview }) => {
    const { isCompany, isAdmin } = useAuth();
    return (
        <div className='d-flex gap-2' style={{ flex: 1 }}>
            {
                !isCompany || !data?._id ? null :
                    <SubmitButton
                        title={'Send for Review'}
                        loading={loading == 'pending'}
                        onClick={sendForReview}
                        color={'outline-primary'}
                        loaderColor={'blue'}
                    />
            }
            {
                !isAdmin ? null :
                    data?.status != 'pending' ? null :
                        <div className='d-flex gap-2' style={{ flex: 1 }}>
                            <SubmitButton
                                title={'Approve'}
                                loading={loading == 'approved'}
                                onClick={approveReview}
                                color={'outline-primary'}
                                loaderColor={'blue'}
                            />
                            <SubmitButton
                                title={'Reject'}
                                loading={loading == 'rejected'}
                                onClick={rejectReview}
                                color={'outline-danger'}
                                loaderColor={'red'}
                            />
                        </div>
            }
        </div>
    )
}

export default ReviewButtons