import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import useNature from "../../hooks/useNature";

import FormField from "../../components/FormField";
import SubmitButton from "./SubmitButton";
import Title from "./Title";
import ReCAPTCHA from "react-google-recaptcha";
const Signup = () => {
  // Hooks
  // const natures = ['Nature 1', 'Nature 2', 'Nature 3', 'Nature 4']
  const { natures, fetchNatures } = useNature();
  const { loading, onSignup } = useAuth();

  // Recaptcha
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaRef = useRef();

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  // States
  const [terms, setTerms] = useState(false);
  const [data, setData] = useState({
    email: "",
    name: "",
    firstname: "",
    lastname: "",
    nature: natures[0],
    position: "",
    adminName: "",
    adminEmail: "",
    employees: 0,
    website: "",
    telephone1: "",
    telephone2: "",
    branches: 0,
    address: "",
  });

  // Functions
  const onSubmit = (e) => {
    e.preventDefault();
    onSignup({ ...data, recaptchaToken },null,recaptchaRef);
  };

  // constants
  const fields = [
    {
      value: data.firstname,
      setValue: (value) => setData({ ...data, firstname: value }),
      label: "First Name",
      required: true,
      type: "text",
      className: "col-md-6",
    },
    {
      value: data.lastname,
      setValue: (value) => setData({ ...data, lastname: value }),
      label: "Last Name",
      required: true,
      type: "text",
      className: "col-md-6",
    },
    {
      value: data.email,
      setValue: (value) => setData({ ...data, email: value }),
      label: "Email Address",
      required: true,
      type: "email",
      className: "col-md-12",
    },
    {
      value: data.password,
      setValue: (value) => setData({ ...data, password: value }),
      label: "Password",
      required: true,
      type: "password",
      className: "col-md-12",
    },
    {
      value: data.telephone1,
      setValue: (value) => setData({ ...data, telephone1: value }),
      label: "Phone Number",
      type: "phone",
      required: true,
      className: "col-md-12",
    },
    {
      value: data.name,
      setValue: (value) => setData({ ...data, name: value }),
      label: "Company Name",
      required: true,
      type: "text",
      className: "col-md-6",
    },
    {
      value: data.nature?.name,
      setValue: (value) => setData({ ...data, nature: value }),
      label: "Industry",
      placeholder: "Select Industry...",
      required: true,
      type: "dropdown",
      options: natures,
      className: "col-md-6",
    },
  ];

  useEffect(() => {
    fetchNatures({ hide: ["Default"] });
  }, []);

  return (
    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex register-container">
        <Title />
        <div
          className="col-lg-6 col-md-12 d-flex flex-column align-items-center justify-content-center"
          style={{ paddingRight: 50 }}
        >
          <div className="card mb-0">
            <div className="card-body pt-2 pb-5 ps-5 pe-5">
              {/* Title */}
              <div className="pt-4 pb-4">
                <h5 className="card-title text-center pb-0 fs-4">
                  Create an Account
                </h5>
                <p className="text-center small">
                  Enter your company details to create account
                </p>
              </div>
              {/* Form */}
              <form className="row g-3" onSubmitCapture={onSubmit}>
                {fields?.map((item, index) => (
                  <FormField item={item} key={index} />
                ))}
                <div className="col-12">
                  <ReCAPTCHA
                    sitekey={process.env.REACt_APP_SITE_KEY}
                    onChange={handleRecaptchaChange}
                    ref={recaptchaRef}
                  />
                </div>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      value={terms}
                      onChange={() => setTerms(!terms)}
                    />
                    <label
                      className="form-check-label small"
                      htmlFor="gridCheck"
                    >
                      I agree to all{" "}
                      <span>
                        <a href="#">Terms & Conditions</a>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="text-center">
                  <SubmitButton
                    title={"Signup"}
                    onClick={onSubmit}
                    loading={loading}
                    disabled={!terms || loading || !recaptchaToken}
                  />
                </div>
                <div className="text-center">
                  <p className="small mb-0">
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
