import React from 'react'
import { Link } from 'react-router-dom'

const NewOptions = () => {
    return (
        <section id="services" className="services section-bg">
            <div className="container aos-init aos-animate" >
                <header className="section-header">
                    <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">Create New Risk Assessment</h5>
                        <p className="text-center small">Start with a blank risk assesment or use template to get started.</p>
                    </div>
                </header>
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 col-lg-4 wow bounceInUp aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                        <Link className="box" to='/new-risk-assesment' state={{ template: false }}>
                            <span className="icon" style={{ background: '#fff0da' }}><i className="bi bi-file-check" style={{ color: '#ff689b' }}></i></span>
                            <p className="description">Start blank Risk Assessment</p>
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
                        <Link to={'/risk-assesments?template=true'} className="box border border-0">
                            <span className="icon" style={{ background: '#fff0da' }}><i className="bi bi-card-checklist" style={{ color: '#e98e06' }}></i></span>
                            <p className="description">Use pre-built Template</p>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewOptions