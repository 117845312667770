import React, { useEffect } from 'react'
import useDocuments from '../../hooks/useDocuments'
import useAuth from '../../hooks/useAuth';
import { formatBytes } from '../../config/functions';
const Stats = ({ isPublic }) => {
    const { isAdmin } = useAuth();
    const { stats, documents, fetchStats } = useDocuments();
    useEffect(() => {
        if (!isPublic && !isAdmin)
            fetchStats();
    }, [isPublic, documents])

    const percentage = (!stats ? 0 : (stats?.used / ((stats?.total) * (1024 * 1024)))) * 100
    const color = (
        percentage < 25 ? 'success' :
            percentage < 50 ? 'info' :
                percentage < 75 ? 'warning' : 'danger'
    )

    return isAdmin || isPublic ? null : (
        <div className='card'>
            <div className="card-body">
                <h5 className="card-title">Size Limit</h5>
                <div className="progress">
                    <div
                        className={`progress-bar bg-${color}`}
                        role="progressbar"
                        style={{ width: `${percentage}%` }}
                        aria-valuenow={`${percentage}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    />
                </div>
                <div className='d-flex justify-content-between mt-2'>
                    <span className={`text-${color}`}>{formatBytes(stats?.used)}</span>
                    <span className={`text-primary`}>{formatBytes(stats?.total * 1024 * 1024)}</span>
                </div>
            </div>
        </div>
    )
}

export default Stats