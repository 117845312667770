import { Checkbox } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../app/api";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { keys } from "../../config/keys";
import { PoliciesContext } from "../../hooks/policiesContext";
import useAuth from "../../hooks/useAuth";
import usePolicy from "../../hooks/usePolicy";
import PDF from "./pdf";
import DownloadButton from "../../components/downloadButton"
const FilesList = ({ template, limit, page, setPage,setLimit }) => {
  const { policies } = useSelector((state) => state.Policy);
  const { deletePolicy, loading, getPolicies } = usePolicy();
  const { isAdmin, isCompany,isManager } = useAuth();
  const { selectedForShare, addForShare, removeFromShare } =
    useContext(PoliciesContext);
  const navigator = useNavigate();
  const onView = (item) => {
    // window.open(`${keys.BASE_LOCAL_URL}/${item.url}`, "_blank", "noreferrer");
  };

  const labels = [
    "#",
    "Title",
    isAdmin ? "Company" : null,
    "Last Reviewed",
    "Reviewed",
    "File",
    (isAdmin || isCompany) && !template && "Actions",
  ].filter((item) => item);

  const data = useMemo(() => {
    return policies.map((item, index) => {
      return {
        data: item,
        fields: [
          ((isAdmin || isCompany || isManager) && !template) ? (
            <Checkbox
              checked={selectedForShare.includes(item)}
              onChange={async (e) => {
                if (e.target.checked) {
                  addForShare(item);
                } else {
                  removeFromShare(item);
                }
              }}
            />
          ) : (
            item._id?.substring(0, 5)
          ),
          item?.title,
          isAdmin ? (
            <td>{item.companyID?._id ? item.companyID?.name : "N/A"}</td>
          ) : null,
          item?.lastReviewed?.substring(0, 10),
          <Checkbox
            checked={item.isReviewed}
            disabled={item.isReviewed || isManager || template}
            onChange={async (e) => {
              try {
                if (e.target.checked) {
                  await api.put(
                    `${keys.BASE_LOCAL_URL}/policies/updateReviewed`,
                    { _id: item._id }
                  );
                  getPolicies();
                }
              } catch (error) {}
            }}
          />,
          template ? (
            <button
              onClick={() => {
                if (template) {
                  navigator("/policyInput", { state: item });
                } else {
                  onView(item);
                }
              }}
              className="btn btn-sm btn-success"
            >
              Select
            </button>
          ) : (
            <DownloadButton
                  PDF={<PDF data={item} />}
                  item={item}
                  fileName={item?.title + `.pdf`}
                />
            // <PDFDownloadLink
            //   document={<PDF data={item} />}
            //   fileName={item?.title + `.pdf`}
            // >
            //   {() => {
            //     return (
            //       <button
            //         onClick={() => {
            //           if (template) {
            //             navigator("/policyInput", { state: item });
            //           } else {
            //             onView(item);
            //           }
            //         }}
            //         className="btn btn-sm btn-success"
            //       >
            //         Open
            //       </button>
            //     );
            //   }}
            // </PDFDownloadLink>
            // <DownloadPDFButton item={item} />
          ),

          (isAdmin || isCompany) && !template && (
            <div className="d-flex gap-3">
              <i
                className="bi bi-pencil-square text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => navigator(`/policyInput?id=${item._id}`)}
              ></i>
              {loading?._id == item?._id ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={loading} color={"red"} />
                </div>
              ) : (
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => deletePolicy(item?._id)}
                ></i>
              )}
            </div>
          ),
        ].filter((it) => it),
      };
    });
  }, [policies, selectedForShare, template, loading]);

  return (
    <>
      <Table
        labels={labels}
        data={data}
        limit={limit}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
      />
      
    </>
  );
};

export default FilesList;
// Component to handle conditional PDF download link
const DownloadPDFButton = ({ item }) => {
  const [showLink, setShowLink] = useState(false);

  return (
    <>
      {!showLink ? (
        <button
          onClick={() => setShowLink(true)}
          className="btn btn-sm btn-primary"
        >
          Download PDF
        </button>
      ) : (
        <DownloadButton 
                  PDF={<PDF data={item} />}
                  item={item}
                  fileName={item?.title + `.pdf`}
                />
        // <PDFDownloadLink
        //   document={<PDF data={item} />}
        //   fileName={item?.title + `.pdf`}
        // >
        //   {({ loading }) =>
        //     loading ? (
        //       <button className="btn btn-sm btn-primary">Loading...</button>
        //     ) : (
        //       <button className="btn btn-sm btn-primary">Download PDF</button>
        //     )
        //   }
        // </PDFDownloadLink>
      )}
    </>
  );
};