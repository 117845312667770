import { Checkbox } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Screen from "../../components/Screen";
import Table from "../../components/Table";
import { convertToLocal } from "../../config/functions";
import { PoliciesContext } from "../../hooks/policiesContext";
import useAuth from "../../hooks/useAuth";
import useDynamicSubscription from "../../hooks/useDynamicSubscription";
import { useNavigate } from "react-router-dom";

const FilesList = () => {
  const {
    getDynamicData,
    loading,
    page,
    setPage,
    limit,
    setLimit,
    filter,
    setfilter,
    deleteLoading,
    deleteSubscription
  } = useDynamicSubscription();
  const { subscriptions } = useSelector((state) => state.subscription);
  const navigator = useNavigate()
  const { isAdmin, isCompanyAdmin, isFellowAdmin } = useAuth();
  const {
    selectedForShare,
    addForShare,
    removeFromShare,
    removeAllFromShare,
    addAllToShare,
  } = useContext(PoliciesContext);

  const labels = [
    isAdmin ? (
      <Checkbox
        checked={selectedForShare.length == subscriptions.length}
        onChange={async (e) => {
          if (e.target.checked) {
            addAllToShare(subscriptions ? subscriptions : []);
          } else {
            removeAllFromShare();
          }
        }}
      />
    ) : (
      "#"
    ),
    "Name",
    "Cost",
    "Date Added",
    "Added for",
    "Added By",
    "Status",
    isAdmin && "Actions",
  ].filter((item) => item);
  const data = useMemo(() => {
    if (!subscriptions || subscriptions.length === 0) return [];
    return subscriptions.map((item) => {
      return {
        data: item,
        fields: [
          isAdmin ? (
            <Checkbox
              checked={selectedForShare.includes(item)}
              onChange={async (e) => {
                if (e.target.checked) {
                  addForShare(item);
                } else {
                  removeFromShare(item);
                }
              }}
            />
          ) : (
            "#"
          ),
          item?.name,
          <td>{item.cost}</td>,
          convertToLocal(item?.createdAt),
          <td>{item?.companyID?.name || item?.adminID?.name}</td>,
          <td>{item?.createdBy?.name}</td>,
          <td>{item?.status}</td>,
          isAdmin && (
            <div className="d-flex gap-3">
              {
                <i
                  className="bi bi-pencil-square text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator(`/create-subscription?for=${item.adminID ? 'admin' : 'company'}&edit=true`,{
                      state:{...item, companyID: item?.companyID?._id, adminID:item?.adminID?._id,createdBy:item?.createdBy?._id}
                    })
                  }}
                ></i>
              }
              {item?._id == deleteLoading ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={deleteLoading != null} color={"red"} />
                </div>
              ) : (
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteSubscription(item._id)}
                ></i>
              )}
            </div>
          ),
        ].filter((it) => it),
      };
    });
  }, [subscriptions, selectedForShare, deleteLoading]);
  useEffect(() => {
    getDynamicData();
  }, [page,limit,filter]);

  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setfilter}
      showAdminFilter={!isCompanyAdmin && !isFellowAdmin}
      flow={[
        { label: `Dynamic Subscriptions`, to: `/subscription-list` },
        { label: "Home", to: "/checklists" },
        { label: `Dynamic Subscriptions`, to: `/subscription-list` },
      ]}
    >
      <Table
        labels={labels}
        data={data}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        // totalData={totalMsDocs}
      />
    </Screen>
  );
};

export default FilesList;
