import React from 'react'
import { Link } from 'react-router-dom'
import Screen from '../../components/Screen'
import useAuth from '../../hooks/useAuth'

const NewOptions = () => {
    const {isAdmin, isCompanyAdmin,isFellowAdmin} = useAuth()
    return (
        <Screen
            flow={[
                { label: "Create Subscription", to: "/create-subscription" },
                { label: "Home", to: "/checklists" },
                { label: "Create Subscription", to: "/create-subscription" },
            ]}
        >
            <section id="services" className="services section-bg">
                <div className="container aos-init aos-animate" >
                    <header className="section-header">
                        <div className="pt-4 pb-2">
                            <h5 className="card-title text-center pb-0 fs-4">Create New Subscription</h5>
                            {/* <p className="text-center small">Start with a blank checklist or use template to get started.</p> */}
                        </div>
                    </header>
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-6 col-lg-4 wow bounceInUp aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                            <Link to="/create-subscription?for=company" className="box">
                                <span className="icon" style={{ background: '#fff0da' }}><i className="bi bi-file-check" style={{ color: '#ff689b' }}></i></span>
                                <p className="description">Create for company</p>
                            </Link>
                        </div>
                        {isAdmin && !isCompanyAdmin && !isFellowAdmin &&  <div className="col-md-6 col-lg-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
                            <Link to="/create-subscription?for=admin" className="box border border-0">
                                <span className="icon" style={{ background: '#fff0da' }}><i className="bi bi-card-checklist" style={{ color: '#e98e06' }}></i></span>
                                <p className="description">Create for admin</p>
                            </Link>
                        </div>}
                    </div>
                </div>
            </section>
        </Screen>
    )
}

export default NewOptions