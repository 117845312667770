import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// import useAudit from '../../hooks/useAudit';
import useAuth from "../../hooks/useAuth";

import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import NewOptions from "./NewOptions";
import ReviewButtons from "./ReviewButtons";
import AuditDetails from "./AuditDetails";
import AuditQuestions from "./AuditQuestions";
import appContext from "../../app/contextState/context";
import { AuditContext } from "../../hooks/auditContext";

const NewAudit = () => {
  //('hi')
  const location = useLocation();
  const { isAdmin } = useAuth();
  const state = useContext(appContext);
  const { loading } = state;
  const {
    auditCollection,
    data,
    question,
    setData,
    setQuestion,
    fetchAudits,
    onAddQuestiuon,
    newAudit,
    changeAudit,
    removeAudit,
    sendForReview,
    approveReview,
    rejectReview,
    onDublicateQuestiuon,
    setAttachments
  } = useContext(AuditContext);

  // states
  const [showQuestions, setShowQuestions] = useState(false);
  const update = location?.search.split("?update=")[1];
  // Constants
  const auditID = location.state?.data?._id;
  const disabled =
    // data?.questions?.length == 0 ||
    !data?.title || !data?.auditFrequency || !data?.categoryID;

  useEffect(() => {
    if (auditID) fetchAudits({ id: auditID });
  }, []);

  useEffect(() => {
    if (loading != "fetch" && location.state?.data) {
      setData(auditCollection?.find((item) => item?._id == auditID));
    }
  }, [update]);

  useEffect(() => {
    if (!location.state?.data) {
      setData({
        title: "",
        auditFrequency: "Daily",
        notificationFrequency: { date: Date.now(), times: [Date.now()] },
        questions: [],
      });
    }
  }, [update]);

  return (
    <Screen
      loading={loading == "fetch"}
      flow={[
        { label: "Audit", to: "/audits" },
        { label: "Home", to: "/audits" },
        { label: "Collection", to: "/audits" },
        {
          label: !!location.state?.data ? "Update Audit" : "New Audit",
          to: "/new-audit",
        },
      ]}
    >
      {location?.state == null ? (
        <NewOptions />
      ) : (
        <section className="section profile">
          <div className="row">
            <form action="" className="row">
              {/* Submit Button */}
              <div className="d-flex justify-content-end mb-3 gap-3">
                {!showQuestions ? null : (
                  <SubmitButton
                    title={"Back"}
                    onClick={() => setShowQuestions(false)}
                    color={"outline-primary"}
                    icon={<i className="bi bi-arrow-left "></i>}
                  />
                )}
                {data?.companyID && (
                  <ReviewButtons
                    loading={loading}
                    data={data}
                    sendForReview={sendForReview}
                    rejectReview={rejectReview}
                    approveReview={approveReview}
                  />
                )}
                {isAdmin && data?.status == "pending" ? null : (
                  <>
                    <SubmitButton
                      title={"Save Audit"}
                      loading={loading == "new" || loading == "update"}
                      onClick={() =>
                        location.state?.update
                          ? changeAudit()
                          : newAudit()
                      }
                      color={"success"}
                      loaderColor={"white"}
                      disabled={disabled}
                    />
                    {!location.state?.data ? null : (
                      <SubmitButton
                        icon={<i className="bi bi-trash3"></i>}
                        loading={loading == "delete"}
                        onClick={() => removeAudit()}
                        color={"danger"}
                        loaderColor={"white"}
                        disabled={disabled}
                      />
                    )}
                  </>
                )}
              </div>
              {showQuestions ? null : (
                <AuditDetails data={data} setData={setData} />
              )}
              {showQuestions ? null : (
                <div
                  className="d-flex justify-content-center align-items-center gap-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowQuestions(true)}
                >
                  <span className="card-title text-center">View Questions</span>
                  <i className="bi bi-arrow-right card-title text-center"></i>
                </div>
              )}
              {!showQuestions ? null : (
                <AuditQuestions
                  data={data}
                  setData={setData}
                  question={question}
                  setQuestion={setQuestion}
                  onAddQuestiuon={onAddQuestiuon}
                  onDublicateQuestiuon={onDublicateQuestiuon}
                  setAttachments={setAttachments}
                />
              )}
            </form>
          </div>
        </section>
      )}
    </Screen>
  );
};

export default NewAudit;
