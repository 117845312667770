import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        summary: {
            companies: [],
            checklists: 0,
            tasks: 0,
            documents: 0,
            records: 0
        }
    },
    reducers: {
        setSumamry: (state, action) => {
            state.summary = action.payload;
        },
        updateCompany: (state, action) => {
            state.summary.companies = (
                state.summary.companies.map(item =>
                    item._id == action.payload._id ? action.payload : item
                )
            )
        }
    },
});

export const { setSumamry, updateCompany } = dashboardSlice.actions;

export default dashboardSlice.reducer;
