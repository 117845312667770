import React, { useState } from "react";

import useCompanies from "../../hooks/useCompanies";

const Contact = ({ company, isAdmin, isCompany, isManager }) => {
  const [data, setData] = useState({
    name: "",
    message: "",
    subject: "",
  });
  const { sendMail } = useCompanies();
  return (
    <div className="tab-pane fade pt-3" id="profile-support">
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="card p-4">
              <form
                action="forms/contact.php"
                method="post"
                className="php-email-form"
              >
                <div className="row gy-4">
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required={true}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                      value={data?.name}
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required={true}
                      onChange={(e) =>
                        setData({ ...data, subject: e.target.value })
                      }
                      value={data.subject}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required={true}
                      onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                      }
                      value={data.message}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        sendMail(
                          data,
                          company._id,
                          isManager
                            ? "manager"
                            : isAdmin
                            ? "admin"
                            : isCompany
                            ? "company"
                            : ""
                        );
                      }}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
