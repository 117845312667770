import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Title from './Title'

const Confirmation = () => {
    return (
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex register-container">
                <Title />
                <div className="col-lg-6 col-md-12 d-flex flex-column align-items-center justify-content-center" style={{ paddingRight: 50 }}>
                    <div className="card mb-0">
                        <div className="card-body pt-2 pb-5 ps-5 pe-5">
                            {/* Title */}
                            <div className="pt-4 pb-4">
                                <h5 className="card-title text-center pb-0 fs-4">Signup Successful</h5>
                                <p className="text-center small">Thank you for completing your registration. Please check your email to confirm <Link to='/login'>Login</Link> credentials and account details.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Confirmation