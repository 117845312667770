// SlideShowModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { keys } from '../../config/keys';

const SlideShowModal = ({ images, showModal, handleClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        if (showModal) setCurrentIndex(showModal - 1)
    }, [showModal])

    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Body>
                <Row className="position-relative">
                    <Col md={12} className="text-center">
                        <img src={`${keys.BASE_LOCAL_URL}/${images[currentIndex]}`} alt={`Image ${currentIndex}`} className="img-fluid" />
                        <Button variant="link" className="prev-button" onClick={handlePrev}>
                            <i className="bi bi-chevron-left"></i>
                        </Button>
                        <Button variant="link" className="next-button" onClick={handleNext}>
                            <i className="bi bi-chevron-right"></i>
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default SlideShowModal;
