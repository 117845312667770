import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import useAdmin from '../../hooks/useAdmin'

import Loader from '../../components/Loader'
import Screen from '../../components/Screen'
import Table from '../../components/Table'
import NewAdmin from './NewAdmin'
import useAuth from '../../hooks/useAuth'

const Admins = () => {
  const { user } = useAuth();
  const {
    deleteLoading,
    addLoading,
    loading,
    admins,
    setData,
    fetchAdmins,
    removeAdmin,
    changeAdmin,
    newAdmin,
    data: fieldData
  } = useAdmin();
  const ref = useRef();

  const labels = [
    '#',
    'Name',
    'Email',
    'Status',
    'Actions'
  ];
  const data = admins
    ?.map((item) => {
      return {
        data: item,
        fields: [
          item?._id?.substr(item?._id.length - 5),
          item.name,
          item.email,
          item.status ? 'Active' : 'InActive',
          <div className='d-flex gap-3'>
            {item.email == 'compcheck@warisconsulting.co.uk' ?null : <i
              onClick={() => {
                ref.current?.scrollIntoView();
                setData(item)
              }}
              className="bi bi-pencil-square text-primary" style={{ cursor: 'pointer' }}
            />}
            {
              user._id == item._id || item.email == 'compcheck@warisconsulting.co.uk' ? null :
                <Loader loading={deleteLoading?._id == item._id} color='red'>
                  <i onClick={() => removeAdmin(item)} className="bi bi-trash text-danger" style={{ cursor: 'pointer' }}></i>
                </Loader>
            }
          </div>
        ]
      }
    })

  useEffect(() => {
    fetchAdmins({ hide: ['Default'] });
  }, []);

  return (
    <Screen loading={loading} flow={[
      { label: 'Admins', to: '/admins' },
      { label: 'Home', to: '/' },
      { label: 'Admins', to: '/admins' }
    ]}>
      <div ref={ref} className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
          <NewAdmin
            data={fieldData}
            setData={setData}
            onSubmit={fieldData._id ? changeAdmin : newAdmin}
            loading={addLoading}
          />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
          <Table
            title={'Business Admins'}
            data={data}
            labels={labels}
          />
        </div>
      </div>
    </Screen>
  )
}

export default Admins