export const keys = {
  // BASE_URL: 'https://server.compcheck.io/api',
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BASE_LOCAL_URL: process.env.REACT_APP_BASE_URL,
  API_KEY: 'AIzaSyCJaqPKXurofU1oOrxM_wcFC6nRCjArchc',
  AUTH_DOMAIN: 'compcheck-1ebd6.firebaseapp.com',
  PROJECT_ID: 'compcheck-1ebd6',
  STORAGE_BUCKET: 'compcheck-1ebd6.firebasestorage.app',
  MESSAGING_SENDER_ID: '606361736571',
  APP_ID: '1:606361736571:web:da0c3e254d153394f419de',
  vapidKey: 'BK7MGLbX3S6A68ENPIOSxjc6dEFd5YSg9HZZXKwMgLXwyY44Rxsuza4VMAaSxtfhX3RwTH5GmwsgbJ6-OqCiK_w',
  serverKey: 'AAAA4lZNcUM:APA91bGthpBS73Ncus266HPHXXNZ7lZyzx2ainkHVTBZkWWuUHNahSjQZtmeoQYjMvmHe1WYuCTGruFWjytu5PqnirHBofHN_CHYcmzg1-1cqSI984II1gNGJ7xgv-TSqoW8_f9eY_fo',
};
