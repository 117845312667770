import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'


import Screen from '../../components/Screen'
import useDashboard from '../../hooks/useDashboard'
import SummaryCard from './SummaryCard'
import Table from '../../components/Table'
import { Link, createSearchParams } from 'react-router-dom'

const Dashboard = () => {
  const { user } = useSelector(state => state.auth);
  const { cards, summary, loading, fetchSummary, changeCompanyStatus } = useDashboard();
  const getStatus = (status) => {
    switch (status) {
      case true: return { color: "success", title: 'Approved' }
      case false: return { color: "danger", title: 'Rejected' }
      default: return { color: "warning", title: 'Pending' }
    }
  }
  const changeStatus = (item) => {
    changeCompanyStatus({ ...item, status: !item.status });
  }

  // constatns
  const labels = ['#', 'Company', 'Industry', 'Subscription', 'Status']

  useEffect(() => {
    fetchSummary()
  }, [])

  const data = summary?.companies
    ?.slice(0, 5)
    .map((item, index) => {
      const status = getStatus(item.status);
      return {
        data: item,
        fields: [
          <Link to={`/profile?companyID=${item._id}`} state={item} >
            #{item?._id?.substr(item?._id.length - 5)}
          </Link>,
          item?.name,
          item?.nature?.name,
          `${item?.subscriptionID?.name}`,
          // <span className={`badge bg-${status?.color}`}>{status?.title}</span>,
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={item.status == undefined ? false : item.status}
              onChange={() => changeStatus(item)}
            />
          </div>,
        ].filter(it => it)
      }
    })

  return (
    <Screen loading={loading} flow={[
      { label: 'Dashboard', to: '/' },
      { label: 'Home', to: '/' },
      { label: 'Dashboard', to: '/' }
    ]}>
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              {
                cards?.map((card, index) => (
                  <div key={index} className="col-xxl-4 col-md-6">
                    <SummaryCard
                      title={card.title}
                      value={card.value}
                      subtitle={card.subtitle}
                      icon={card.icon}
                      className={card.className}
                      to={card.to}
                    />
                  </div>
                ))
              }
              {
                user?.role != 'admin' ? null :
                  <div className="col-12">
                    <Table
                      title={'Recent Registrations'}
                      className={'recent-sales overflow-auto'}
                      labels={labels}
                      data={data}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
      </section>
    </Screen>
  )
}

export default Dashboard