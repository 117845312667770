import React from 'react'
import { Modal } from "react-bootstrap";

const FormModal = ({ show, title, fields, onSubmit, onClose, loading }) => {
    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header>
                <h5 className="modal-title">{title}</h5>
                <button type="button" className="btn-close" onClick={onClose}></button>
            </Modal.Header>
            <Modal.Body>

                <div className="row g-3">
                    {
                        fields?.map((item, index) => (
                            <div className={item.className} key={index}>
                                {
                                    item.type == 'dropdown'
                                        ?
                                        <select className="form-select" onChange={(e) => item.setValue(e.target.value)} value={item.value}>
                                            <option selected value={''}>{item.placeholder}</option>
                                            {item.options?.map((opt, idx) => <option key={idx} value={opt._id} >{opt?.name}</option>)}
                                        </select>
                                        :
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={item.placeholder}
                                            value={item.value}
                                            onChange={(e) => item.setValue(e.target.value)}
                                        />
                                }
                            </div>
                        ))
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                <button type="button" className="btn btn-primary" onClick={onSubmit} disabled={loading}>
                    {loading ? 'Saving...' : 'Save changes'}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default FormModal