import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: []
  },
  reducers: {
    setCategory: (state, action) => {
      state.categories = action.payload;
    },
    updateCategory: (state, action) => {
      state.categories = state.categories.map(item => item._id == action.payload._id ? action.payload : item);
    },
    deleteCategory: (state, action) => {
      state.categories = state.categories.filter(item => item._id != action.payload._id);
      state.categories = state.categories.map(item => item.name == 'Default' ? { ...item, checklists: action.payload.checklists + item.checklists } : item);
    },
    addCategory: (state, action) => {
      state.categories = [...state.categories, { ...action.payload, checklists: 0 }];
    },
  },
});

export const { setCategory, updateCategory, deleteCategory, addCategory } = categorySlice.actions;

export default categorySlice.reducer;
