import React, { useState } from 'react'
import AppContext from './context'
import api from "../api";
import { useNavigate } from 'react-router-dom';
import { setChecklist, addChecklist, updateChecklist, deleteChecklist } from '../../features/checklist/slice';
import { useDispatch } from 'react-redux';
import useAlert from '../../hooks/useAlert';
import { addRiskAssesment } from '../../features/riskAssesment/slice';

export default function Default(props) {
  const [showNotification, setshowNotification] = useState(false)
  const [notificationData, setNotificationData] = useState('')
  const [loading, setLoading] = useState(false);
  const [unseen, setUnseen] = useState(0);
  const [selectedChecklists, setSelectedChecklists] = useState([]);
  const [showMessageSidebar, setShowMessageSidebar] = useState(true);
  // const { setLoading } = useChecklist()
  const { showAlert } = useAlert()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleChecklistMultiple = (e, data) => {
    if (e.target.checked) {
      setSelectedChecklists([...selectedChecklists, data])
    } else {
      setSelectedChecklists(prevState => {
        let newState = prevState.filter(item => item._id != data._id)
        return newState
      })
    }
  }
  const addMultipleChecklistsAdd = async () => {
    setLoading(true);
    try {
      await Promise.all(
        selectedChecklists.map(async (item) => {
          const response = await api.post('/checklist/new', { ...item });
          dispatch(addChecklist(response.data))
        })
      )
      setLoading(false);
        setSelectedChecklists([])
      showAlert('New Checklist added ', 'success');
      navigate('/checklists')
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      showAlert(message, 'danger');
      setLoading(false);
    }
  }

  const handleRiskAssesmentMultiple = (e, data) => {
    if (e.target.checked) {
      setSelectedChecklists([...selectedChecklists, data])
    } else {
      setSelectedChecklists(prevState => {
        let newState = prevState.filter(item => item._id != data._id)
        return newState
      })
    }
  }
  const addMultipleRiskAssesmentssAdd = async () => {
    setLoading(true);
    try {
      await Promise.all(
        selectedChecklists.map(async (item) => {
          const response = await api.post('/risk-assesment/new', { ...item });
          dispatch(addRiskAssesment(response.data))
        })
      )
      setLoading(false);
        setSelectedChecklists([])
      showAlert('New Risk Assesments added ', 'success');
      navigate('/risk-assesments')
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      showAlert(message, 'danger');
      setLoading(false);
    }
  }
  return (
    <AppContext.Provider value={{
      handleRiskAssesmentMultiple,
      addMultipleRiskAssesmentssAdd,
      showNotification, setshowNotification,
      notificationData, setNotificationData, unseen, setUnseen,
      selectedChecklists, setSelectedChecklists,
      handleChecklistMultiple, addMultipleChecklistsAdd,
      loading, setLoading,
      showMessageSidebar, setShowMessageSidebar
    }}>
      {props.children}
    </AppContext.Provider>
  )
}
