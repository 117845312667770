import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../app/api";
import FormField1 from "../../components/FormField";
import Loader from "../../components/Loader";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import useAlert from "../../hooks/useAlert";
import { Box } from "@mui/material";
import ReactQuill from "react-quill";
import { keys } from "../../config/keys";
export default function Default() {
    const navigate = useNavigate()
    const [loading, setloading] = useState(null)
    const [data, setData] = useState({
        validity: "",
        allowedInbox: "",
        companyID: "",
        additionalFeatures: [
            {
                feature: "",
                allocation: "",
            },
        ],
    })
    const location = useLocation()
    const { showAlert } = useAlert()
    const onAddInvoice = async (dontSendMail) => {
        setloading(dontSendMail ? "copy" : "new")
        try {
            const response = await api.post(
                `/proposal/addInvoice`,
                { ...data, additionalFeatures: data.additionalFeatures.length > 0 && data.additionalFeatures[0].feature != '' ? data.additionalFeatures : [], ...(dontSendMail ? { dontSendMail } : {}) }
            );
            if (response.data.status) {
                showAlert(dontSendMail ? "Link copies to clipboard" : response.data.message, "success")
                if (dontSendMail) {
                    await navigator.clipboard.writeText(`${keys.BASE_LOCAL_URL}/${response.data.result.pdfLink}`);
                }
                navigate("/list-Invoice")
            } else {
                showAlert(response.data.message, "danger")
            }
        } catch (error) {
            showAlert(error.message, "danger")
        } finally {
            setloading(null)
        }
    }
    let fields = [
        {
            value: data?.proposalName,
            setValue: (value) => setData({ ...data, proposalName: value }),
            label: "Enter Invoice name",
            placeholder: "Enter Invoice name",
            type: "text",
            id: "proposalName",
            required: true,
        },
        {
            value: data?.cost,
            setValue: (value) => setData({ ...data, cost: value }),
            label: "Enter cost",
            placeholder: "Enter cost",
            type: "number",
            required: true,
            id: "cost",
        },
        {
            value: data?.email,
            setValue: (value) => setData({ ...data, email: value }),
            label: 'Enter email',
            placeholder: 'Enter email',
            type: 'email',
            id: 'email',
            required: true
        },
        {
            value: data?.emailText,
            setValue: (value) => setData({ ...data, emailText: value }),
            label: 'Enter text for email',
            placeholder: 'Enter text for email',
            type: 'longtext',
            id: 'emailText',
            required: true
        },
        {
            value: data?.proposalText,
            setValue: (value) => setData({ ...data, proposalText: value }),
            label: 'Enter text for proposal',
            placeholder: 'Enter text for proposal',
            type: 'styleText',
            onChange: (value) => setData({ ...data, proposalText: value }),
            id: 'proposalText',
            required: true
        },
    ].filter(it => it);
    useEffect(() => {
        if (location.state) {
            setData(location.state)
        }
    }, []);
    return (
        <Screen
            loading={loading == 'initial'}
            flow={[
                { label: `Create Invoice`, to: `/add-invoice` },
                { label: "Home", to: "/checklists" },
                { label: `Create Invoice`, to: `/add-invoice` },
            ]}
        >
            <>
                <div className="card gap-3 py-4">

                    <div className="card-body">
                        {fields.map((item) => (
                            <div className="col-12">
                                <FormField field={item} />
                            </div>
                        ))}
                        {data.additionalFeatures?.map((item, index) => (
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <FormField1
                                        item={{
                                            value: item?.feature,
                                            setValue: (value) => setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures[index].feature = value
                                                return newState
                                            }),
                                            placeholder: 'Enter feature name',
                                            type: 'text',
                                            required: true,
                                            id: `feature-${index}`,
                                            className: ""
                                        }}
                                    />
                                </div>
                                <div className='col-5'>
                                    <FormField1
                                        item={{
                                            value: item?.allocation,
                                            setValue: (value) => setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures[index].allocation = value
                                                return newState
                                            }),
                                            placeholder: 'Enter allocation',
                                            type: 'text',
                                            required: true,
                                            id: `allocation-${index}`,
                                            className: ""
                                        }}
                                    />
                                </div>
                                <div className='col-1'>
                                    <SubmitButton
                                        icon={<i className="bi bi-trash3"></i>}
                                        loading={loading == "delete"}
                                        onClick={() => {
                                            setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures.splice(index, 1)
                                                return newState
                                            })
                                        }}
                                        color={"danger"}
                                        loaderColor={"white"}
                                    // disabled={disabled}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className='col-12'>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-12'>
                                <button
                                    className='btn btn-sm rounded-5 btn-outline-primary w-100 mt-2'
                                    onClick={() => {
                                        setData(prevState => {
                                            let newState = { ...prevState }
                                            newState.additionalFeatures.push({
                                                feature: '',
                                                allocation: ""
                                            })
                                            return newState
                                        })
                                    }}
                                >
                                    <Loader center color={'white'}>
                                        Add More +
                                    </Loader>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4 gap-2">
                        <SubmitButton
                            title={"Add Invoice"}
                            loading={loading == "new" || loading == "update"}
                            onClick={() => {
                                onAddInvoice();
                            }}
                            color={"success"}
                            loaderColor={"white"}
                            disabled={
                                data.cost == "" ||
                                data.email == "" ||
                                data.proposalText == "" ||
                                data.emailText == "" ||
                                data.proposalName == ""
                            }
                        />
                        <SubmitButton
                            title={"Get Invoice Link"}
                            loading={loading == "copy"}
                            onClick={() => {
                                onAddInvoice(true);
                            }}
                            color={"success"}
                            loaderColor={"white"}
                            disabled={
                                data.cost == "" ||
                                data.email == "" ||
                                data.proposalText == "" ||
                                data.emailText == "" ||
                                data.proposalName == "" ||
                                loading == "copy"
                            }
                        />
                    </div>
                </div>


            </>
        </Screen>
    );
}

const FormField = ({ field }) => {
    const onChange = (e) => field.setValue(e.target.value);
    const modules = {
        // toolbar: [[{ header: [1, 2, false] }], ["bold"]],
        toolbar: [
            [{ header: [1, 2, false] }], // Dropdown for header formatting
            ["bold", "italic", "underline", "strike"], // Text styles
            [{ list: "ordered" }, { list: "bullet" }], // Lists
            ["link"], // Media insertion
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
        "link",
        "image",
    ];
    return (
        <div className="row mb-3">
            <label htmlFor={field.id} className="col-md-4 col-lg-3 col-form-label">
                {field.label}
                {field.required ? <span className="text-danger"> *</span> : null}
            </label>
            <div className="col-md-8 col-lg-9">
                {field.type == "longtext" ? (
                    <textarea
                        name={field.id}
                        className="form-control"
                        id={field.id}
                        style={{ height: 100 }}
                        onChange={onChange}
                        value={field.value}
                    />
                )
                    : field.type == "dropdown" ? (
                        <>
                            <select
                                id={field.id}
                                value={field.value}
                                className="form-select"
                                onChange={onChange}
                            >
                                <option
                                    selected={
                                        field?.value == "" ||
                                        field.value == null ||
                                        field.value == undefined
                                    }
                                    value={""}
                                >
                                    {`-- ${field.placeholder} --`}
                                </option>
                                {field?.options?.map((val, index) => {
                                    const value = !val.value ? val._id : val.value;
                                    const display = !!val.name ? val.name : val;
                                    return (
                                        <option
                                            key={index}
                                            selected={field?.value == value}
                                            value={value}
                                        >
                                            {display}
                                        </option>
                                    );
                                })}
                            </select>
                        </>
                    )
                        : field.type == "styleText" ? (
                            <>
                                <Box
                                    sx={{
                                        height: "580px",
                                    }}
                                >
                                    <ReactQuill
                                        style={{
                                            height: "500px",
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                        modules={modules}
                                        formats={formats}
                                        theme="snow"
                                    />
                                </Box>
                            </>
                        )
                            : (
                                <input
                                    name={field.id}
                                    type={field.type}
                                    className="form-control"
                                    id={field.id}
                                    value={field.value}
                                    onChange={onChange}
                                    placeholder={field.placeholder}
                                />
                            )}
            </div>
        </div>
    );
};
