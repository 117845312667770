import { createSlice } from "@reduxjs/toolkit";

const emailSlice = createSlice({
  name: "email",
  initialState: {
    email: [],
    emailGroups: [],
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = state.email?.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteEmail: (state, action) => {
      state.email = state.email.filter(
        (item) => item._id != action.payload._id
      );
    },
    addEmail: (state, action) => {
      state.email = [...state.email, action.payload];
    },
    setEmailGroup: (state, action) => {
      state.emailGroups = action.payload;
    },
    updateEmailGroup: (state, action) => {
      state.emailGroups = state.emailGroups?.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteEmailGroup: (state, action) => {
      state.emailGroups = state.emailGroups.filter(
        (item) => item._id != action.payload._id
      );
    },
    addEmailGroup: (state, action) => {
      state.emailGroups = [...state.emailGroups, action.payload];
    },
  },
});

export const {
  setEmail,
  updateEmail,
  deleteEmail,
  addEmail,
  setEmailGroup,
  updateEmailGroup,
  deleteEmailGroup,
  addEmailGroup,
} = emailSlice.actions;
export default emailSlice.reducer;
