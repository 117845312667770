import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import useAuth from '../../hooks/useAuth';
import FormField from '../../components/FormField';
import SubmitButton from './SubmitButton';
import Title from './Title'

const Login = () => {

  // Hooks
  const { loading, onLogin } = useAuth();

  // States
  const [terms, setTerms] = useState(false);
  const [data, setData] = useState({
    email: '',
    password: ''
  });

  // Functions
  const onSubmit = (e) => {
    e.preventDefault();
    onLogin(data.email, data.password);
  }

  // constants
  const fields = [
    {
      value: data.email,
      setValue: (value) => setData({ ...data, email: value }),
      label: 'Email Address',
      required: true,
      type: 'email',
      className: 'col-md-6'
    },
    {
      value: data.password,
      setValue: (value) => setData({ ...data, password: value }),
      label: 'Password',
      required: true,
      type: 'password',
      className: 'col-md-6'
    },
  ]

  return (
    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex register-container">
        <Title />
        <div className="col-lg-6 col-md-10 d-flex flex-column align-items-center justify-content-center" style={{ paddingRight: 50 }}>
          <div className="card mb-0">
            <div className="card-body pt-2 pb-5 ps-5 pe-5">
              {/* Title */}
              <div className="pt-4 pb-4">
                <h5 className="card-title text-center pb-0 fs-4">Welcome Back</h5>
                <p className="text-center small">Login into your company account</p>
              </div>
              {/* From */}
              <form className="row g-3" onSubmitCapture={onSubmit}>
                {
                  fields?.map((item, index) => (
                    <FormField item={item} key={index} />
                  ))
                }
                {/* <div className="col-12">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="gridCheck" value={terms} onChange={() => setTerms(!terms)} />
                    <label className="form-check-label small" htmlFor="gridCheck">
                      I agree to all <span><a href="#">Terms & Conditions</a></span>
                    </label>
                  </div>
                </div> */}
                <div className="text-center">
                  <SubmitButton
                    title={'Login'}
                    onClick={onSubmit}
                    loading={loading}
                    disabled={loading}
                  />
                </div>
                <div className="text-center">
                  <p className="small mb-0">Don't have account? <Link to='/signup'>Create an account</Link></p>
                  <p className="small mt-2 mb-0"><Link to='/forgot' state={data}>Forgot Password? </Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login