import React from "react";
import { useSelector } from "react-redux";
import Table from "../../components/Table";
export default function Default({ getEmails }) {
  const { emailGroups } = useSelector((state) => state.Email);

  const labels = ["Name"].filter(
    (item) => item
  );
  const data = emailGroups
    ? emailGroups.map((item) => {
        return {
          data: item,
          fields: [
            <p
              style={{ cursor: "pointer" }}
              onClick={() => getEmails(item._id)}
            >
              {item?.name}
            </p>,
          ].filter((it) => it),
        };
      })
    : [];
  return (
    <div className="col-md-12 col-lg-6">
      <Table labels={labels} data={data} heightControl={true} />
    </div>
  );
}
