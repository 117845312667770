import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import useAuth from "../../hooks/useAuth";
import useEmails from "../../hooks/useEmails";
import SubmitButton from "../../components/SubmitButton";
const FilesList = ({
  emailGroups,
  getEmails,
  deleteGroup,
  handleGroupUpdate,
}) => {
  //   const { emailGroups } = useSelector((state) => state.Policy);
  // const { loading } = usePolicy();
  const { loading } = useEmails();
  const { isAdmin, isCompany,isManager } = useAuth();
  const navigator = useNavigate();

  const labels = ["Name", (isAdmin || isCompany || isManager) && "Actions"].filter(
    (item) => item
  );
  const data = emailGroups
    ? emailGroups.map((item) => {
        return {
          data: item,
          fields: [
            item?.name,
            (isAdmin || isCompany || isManager) && (
              <div className="d-flex gap-3">
                <button
                  onClick={() => getEmails(item._id)}
                  className="btn btn-sm btn-success"
                >
                  Open
                </button>
                <i
                  className="bi bi-pencil-square text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGroupUpdate(item)}
                ></i>
                {loading?._id == item?._id ? (
                  <div className="d-flex align-items-center justify-content-center ">
                    <Loader loading={loading} color={"red"} />
                  </div>
                ) : (
                  <i
                    className="bi bi-trash text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteGroup(item?._id)}
                  ></i>
                )}
                
              </div>
            ),
          ].filter((it) => it),
        };
      })
    : [];
  return (
    <>
      <Table labels={labels} data={data} />
    </>
  );
};

export default FilesList;
