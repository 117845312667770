import { Box } from "@mui/material";
import React, { useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import FormField from "../../components/FormField";
import SubmitButton from "../../components/SubmitButton";
import EmailGroupList from "./emailsGroupList";
export default function Default({
  getEmails,
  addGroup,
  addLoading,
  newGroup,
  addGroupField,
  deleteGroup,
  isUpdate,
  setisUpdate,
  handleGroupUpdate,
  updateGroup,
  setNewGroup,
}) {
  const { emailGroups } = useSelector((state) => state.Email);
  useEffect(() => {
    return () => {
      setisUpdate(false);
      setNewGroup("");
    };
  }, []);
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "block",
          md: "flex",
          lg: "flex",
        },
        justifyContent: "space-between",
      }}
    >
      <div className="col-md-12 col-lg-6">
        <EmailGroupList
          emailGroups={emailGroups}
          getEmails={getEmails}
          deleteGroup={deleteGroup}
          handleGroupUpdate={handleGroupUpdate}
        />
      </div>
      <div className="col-md-12 col-lg-5">
        <div className="card">
          <div className="card-body">
            <div className="col-md-12 col-12">
              <h5 className="card-title">Add Group</h5>
              <div className="d-flex">
                <FormField item={addGroupField} />
                <SubmitButton
                  style={{ marginLeft: "15px" }}
                  title={!isUpdate ? "Add" : "Update"}
                  loading={addLoading}
                  onClick={() => {
                    if (isUpdate) {
                      updateGroup();
                    } else {
                      addGroup();
                    }
                  }}
                  color={"success"}
                  loaderColor={"white"}
                  disabled={newGroup == ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
