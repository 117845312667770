import React from 'react'
import imagePlaceholder from '../../assets/placeholder-img.png'
import { keys } from '../../config/keys'
const Header = ({ user , nature}) => {
    //(user)
    const src = user?.image ? URL.createObjectURL(user?.image) : (user?.imageURL ? `${keys.BASE_LOCAL_URL}/${user?.imageURL}` : imagePlaceholder)
    return (
        <div className="col-xl-4">

            <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

                    <img src={src} alt="Profile" />
                    <h2>{user?.name}</h2>
                    <h3>{nature}</h3>
                </div>
            </div>

        </div>
    )
}

export default Header