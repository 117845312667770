import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import DocumentView from "./documentView";
const FolderList = ({
  isPublic,
  setFolder,
  AddFolders,
  addLoading,
  addFolderLoading,
  changeFolderName,
  removeFolder,
  isAdmin,
  filter,
  isCompany,
  selectedFolder,
  changeFolderPrivacy,
}) => {
  const { folders } = useSelector((state) => state.document);

  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [dataTest, setTestData] = useState(null);
  const [TestName, setTestName] = useState(null);
  const [show, setShow] = useState(false);
  const [points, setPoints] = useState({ x: 0, y: 0 });

  const [editingTest, setEditingTest] = useState(false);
  const [editing, setEditing] = useState(false);

  const startEditing = () => setEditing(true);
  const stopEditing = () => {
    setEditingTest(false);
    setEditing(false);
    setTestName(null);
  };

  const onEscape = function (action) {
    window &&
      window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          action();
        }
      });
  };

  onEscape(stopEditing);

  const onChange = (e) => {
    setName(e.target.value);
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      if (!editingTest) {
        await AddFolders(name);
        setName("");
      } else {
        await changeFolderName(dataTest?._id, TestName);
        setTestName(null);
        setEditingTest(false);
        onEscape(stopEditing);
        setTestData(null);
      }
    }
  };

  const EditData = () => {
    setEditingTest(true);
    setTestName(dataTest?.name);
  };

  useEffect(() => {
    const handleClick = () => {
      setShow(false);
    };
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  return (
    <section className="section">
      <div className="iconslist">
        {folders?.map((item, index) =>
          (filter.company || filter.branch) && selectedFolder.length < 1 ? (
            isAdmin && item.name === "Bespoke Documents" ? (
              <div key={index}>
                {addFolderLoading && addFolderLoading == item?._id ? (
                  <div className="d-flex align-items-center justify-content-center ">
                    <Loader loading={addFolderLoading} color={"red"} />
                  </div>
                ) : (
                  <DocumentView
                    adminFolder={true}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      if (
                        !item.companyFolder &&
                        item.name != "Bespoke Documents"
                      ) {
                        setShow(true);
                        setPoints({ x: e.pageX, y: e.pageY });
                        setTestData(item);
                      }
                    }}
                    onClick={async () => {
                      if (TestName == null) {
                        setFolder(item);
                      }
                    }}
                    editingTest={editingTest}
                    dataTest={dataTest}
                    item={item}
                    onChange={(e) => setTestName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    show={show}
                    user={user}
                    points={points}
                    onContextClick={async () => {
                      removeFolder(dataTest, dataTest?._id);
                      setTestData(null);
                    }}
                    onEditData={EditData}
                    TestName={TestName}
                    isPublic={isPublic}
                    changeFolderPrivacy={() => {
                      changeFolderPrivacy(dataTest._id);
                    }}
                  />
                )}
              </div>
            ) : isCompany && !isAdmin ? (
              <div key={index}>
                {addFolderLoading && addFolderLoading == item?._id ? (
                  <div className="d-flex align-items-center justify-content-center ">
                    <Loader loading={addFolderLoading} color={"red"} />
                  </div>
                ) : (
                  <DocumentView
                    onContextMenu={(e) => {
                      e.preventDefault();
                      if (
                        !item.companyFolder &&
                        item.name != "Bespoke Documents"
                      ) {
                        setShow(true);
                        setPoints({ x: e.pageX, y: e.pageY });
                        setTestData(item);
                      }
                    }}
                    onClick={async () => {
                      if (TestName == null) {
                        setFolder(item);
                      }
                    }}
                    editingTest={editingTest}
                    dataTest={dataTest}
                    item={item}
                    onChange={(e) => setTestName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    show={show}
                    user={user}
                    points={points}
                    onContextClick={async () => {
                      removeFolder(dataTest, dataTest?._id);
                      setTestData(null);
                    }}
                    onEditData={EditData}
                    TestName={TestName}
                    isPublic={isPublic}
                    changeFolderPrivacy={() => {
                      changeFolderPrivacy(dataTest._id);
                    }}
                  />
                )}
              </div>
            ) : (
              !isAdmin &&
              item.name != "Bespoke Documents" && (
                <div key={index}>
                  {addFolderLoading && addFolderLoading == item?._id ? (
                    <div className="d-flex align-items-center justify-content-center ">
                      <Loader loading={addFolderLoading} color={"red"} />
                    </div>
                  ) : (
                    <DocumentView
                      adminFolder={true}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        if (
                          !item.companyFolder &&
                          item.name != "Bespoke Documents"
                        ) {
                          setShow(true);
                          setPoints({ x: e.pageX, y: e.pageY });
                          setTestData(item);
                        }
                      }}
                      onClick={async () => {
                        if (TestName == null) {
                          setFolder(item);
                        }
                      }}
                      editingTest={editingTest}
                      dataTest={dataTest}
                      item={item}
                      onChange={(e) => setTestName(e.target.value)}
                      onKeyDown={handleKeyDown}
                      show={show}
                      user={user}
                      points={points}
                      onContextClick={async () => {
                        removeFolder(dataTest, dataTest?._id);
                        setTestData(null);
                      }}
                      onEditData={EditData}
                      TestName={TestName}
                      isPublic={isPublic}
                      changeFolderPrivacy={() => {
                        changeFolderPrivacy(dataTest._id);
                      }}
                    />
                  )}
                </div>
              )
            )
          ) : (
            <div key={index}>
              {addFolderLoading && addFolderLoading == item?._id ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={addFolderLoading} color={"red"} />
                </div>
              ) : (
                <DocumentView
                  onContextMenu={(e) => {
                    e.preventDefault();
                    if (
                      !item.companyFolder &&
                      item.name != "Bespoke Documents"
                    ) {
                      setShow(true);
                      setPoints({ x: e.pageX, y: e.pageY });
                      setTestData(item);
                    }
                  }}
                  onClick={async () => {
                    if (TestName == null) {
                      setFolder(item);
                    }
                  }}
                  editingTest={editingTest}
                  dataTest={dataTest}
                  item={item}
                  onChange={(e) => setTestName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  show={show}
                  user={user}
                  points={points}
                  onContextClick={async () => {
                    removeFolder(dataTest, dataTest?._id);
                    setTestData(null);
                  }}
                  onEditData={EditData}
                  TestName={TestName}
                  isPublic={isPublic}
                  changeFolderPrivacy={() => {
                    changeFolderPrivacy(dataTest._id);
                  }}
                />
              )}
            </div>
          )
        )}
        {!isPublic ? (
          user?.role != "admin" &&
          user?.role != "company" &&
          user?.position != "Manager" ? null : (user.role == "admin" &&
              (filter.company || filter.branch) &&
              selectedFolder.length < 1) ||
            (selectedFolder[0]?.name == "Bespoke Documents" && !isAdmin) ||
            (!isAdmin &&
              !isCompany &&
              selectedFolder[0]?.companyFolder) ? null : (
            <>
              {
                addLoading ? (
                  <div className="d-flex align-items-center justify-content-center ">
                    <Loader loading={addLoading} color={"red"} />
                  </div>
                ) : (
                  /** START -- ADD DOCUMENT MODEL -- START */
                  <>
                    <div
                      className="icon d-flex flex-column align-items-center border border-1 border-success"
                      onClick={startEditing}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-folder"></i>
                      {!editing ? (
                        <div className="label">New Folder</div>
                      ) : (
                        <input
                          className="label form-control w-50 text-center"
                          value={name}
                          placeholder="Name"
                          onChange={onChange}
                          // ref={elementref}
                          onKeyDown={handleKeyDown}
                        />
                      )}
                    </div>
                  </>
                )
                /** END -- ADD DOCUMENT MODEL -- END */
              }
            </>
          )
        ) : user?.role != "admin" ? null : (
          <>
            {
              addLoading ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={addLoading} color={"red"} />
                </div>
              ) : (
                /** START -- ADD DOCUMENT MODEL -- START */
                <>
                  <div
                    className="icon d-flex flex-column align-items-center border border-1 border-success"
                    onClick={startEditing}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-folder"></i>
                    {!editing ? (
                      <div className="label">New Folder</div>
                    ) : (
                      <input
                        className="label form-control w-50 text-center"
                        value={name}
                        placeholder="Name"
                        onChange={onChange}
                        // ref={elementref}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </div>
                  {/* <MenuContext /> */}
                </>
              )
              /** END -- ADD DOCUMENT MODEL -- END */
            }
          </>
        )}
      </div>
    </section>
  );
};

export default FolderList;
