import React from "react";
import { ContextMenu } from "./TestStyle";
import "./index.css";
import { Chip } from "@mui/material";
export default function Default(props) {
  const {
    onContextMenu,
    onClick,
    editingTest,
    dataTest,
    item,
    onChange,
    onKeyDown,
    show,
    user,
    points,
    onContextClick,
    onEditData,
    TestName,
    isPublic,
    changeFolderPrivacy,
  } = props;
  return (
    <>
      <div
        className="icon"
        onContextMenu={onContextMenu}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        {editingTest && dataTest?._id == item?._id ? (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <i className="bi bi-folder"></i>
            <input
              className="label form-control w-50 text-center"
              value={TestName}
              placeholder="Name"
              onChange={onChange}
              // ref={elementref}
              onKeyDown={onKeyDown}
            />
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            {item.branchVisibility == "public" && (
              <Chip
                label={`${
                  item.branchVisibility === "public" ? "Public" : "Private"
                }`}
                className="admin-tag"
              />
            )}
            {item.name == "Bespoke Documents" && (
              <Chip label="Admin" className="admin-tag" />
            )}
            <i className="bi bi-folder"></i>
            <div
              className="label"
              style={{
                color: item.name === "Bespoke Documents" ? "red" : "black",
              }}
            >
              {item.name}
            </div>
          </div>
        )}
      </div>
      {show &&
        ((user?.role == "admin" && isPublic) ||
          ((user.role == "company" ||
            user.position == "Manager" ||
            user.role == "admin") &&
            !isPublic)) && (
          <ContextMenu top={points.y} left={points.x}>
            <ul>
              <li onClick={onContextClick}>Delete</li>
              <li onClick={onEditData}>Edit Name</li>
              {user.role == "company" && (
                <li onClick={changeFolderPrivacy}>
                  Make{" "}
                  {dataTest?.branchVisibility == "public"
                    ? "Private"
                    : "Public"}
                </li>
              )}
            </ul>
          </ContextMenu>
        )}
    </>
  );
}
