import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";

import useAuth from '../hooks/useAuth';
import FormField from './FormField';
import Loader from './Loader';

const DownloadPopup = ({ handleClose, show }) => {
    const { loading, onReset } = useAuth();

    const [data, setData] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    })

    const fields = [
        {
            label: 'Old Password',
            value: data?.old_password,
            setValue: (value) => setData({ ...data, old_password: value }),
            type: 'text',
            required: true,
            className: 'col-12'
        },
        {
            label: 'New Password',
            value: data?.new_password,
            setValue: (value) => setData({ ...data, new_password: value }),
            type: 'password',
            required: true,
            className: 'col-6 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2'
        },
        {
            label: 'Confirm Password',
            value: data?.confirm_password,
            setValue: (value) => setData({ ...data, confirm_password: value }),
            type: 'password',
            required: true,
            className: 'col-6 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2'
        },
    ]

    const onHide = () => {
        handleClose();
        setData({
            old_password: '',
            new_password: '',
            confirm_password: ''
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        onReset(data, onHide);
    }

    return (
        <Modal show={show} centered onHide={onHide}>
            <Modal.Header><Modal.Title>Change Password</Modal.Title></Modal.Header>
            <Modal.Body>
                <form className='row' onSubmit={onSubmit}>
                    {
                        fields?.map((item, index) => (
                            <FormField item={item} key={index} />
                        ))
                    }
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button type='submit' onClick={onSubmit} className='btn btn-primary'>
                    <Loader loading={loading} color={'white'}>
                        Submit
                    </Loader>
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default DownloadPopup