import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "../app/api";
import useAlert from "./useAlert";
import useAuth from "./useAuth";
import Alert, { DeleteAlert } from "../components/Swal Alert/index";

import {
  setChat,
  updateChat,
  deleteChat,
  addChat,
  setMessage,
  updateMessagesStatus,
  deleteMessage,
  addMessage,
} from "../features/chat/slice";
import { getFormData } from "../config/functions";
import { useNavigate } from "react-router-dom";
import appContext from "../app/contextState/context";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chats, messages } = useSelector((state) => state.chat);
  const { showAlert } = useAlert();
  const { user } = useAuth();

  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const appState = useContext(appContext);
  const { unseen, setUnseen } = appState;
  // New Chat State
  const [data, setData] = useState({
    users: [],
  });

  const [messageData, setMessageData] = useState({
    text: "",
    images: [],
  });

  const fetchChats = async (params) => {
    setLoading(true);
    //(params)
    try {
      const response = await api.get(`/chat`, { params });
      dispatch(setChat(response.data.items));
      let unSeen = 0;
      await Promise.all(
        response.data?.items.map((item) => {
          unSeen += item.unseen;
        })
      );
      setUnseen(unSeen);
      // if (params.id) setData(response.data.items[0])
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      showAlert(message, "danger");
      setLoading(false);
    }
  };
  const newChat = async (
    reciever,
    chat,
    setpageState,
    setShowMessageSidebar
  ) => {
    setAddLoading(reciever);
    //(reciever, chat)
    try {
      const response = await api.post("/chat/new", chat);
      //(response)
      if (response.data?.existingChat)
        dispatch(
          addChat(
            response.data?.existingChat
              ? response.data?.existingChat
              : response.data
          )
        );
      if (setShowMessageSidebar) {
        setShowMessageSidebar(false);
      }
      fetchChats();
      setAddLoading(false);
      if (!response.data?.existingChat) {
        showAlert("New chat initiated successfully", "success");
      }
      navigate(
        `/inbox?id=${
          response.data.existingChat
            ? response.data?.existingChat?._id
            : response.data._id
        }`
      );
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      Alert("error", message);
      // showAlert(message, 'danger');
      setAddLoading(false);
    }
  };
  const removeChat = async (item) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(item);
    try {
      const response = await api.delete(
        `/chat/delete/${item ? item._id : data._id}`
      );
      dispatch(deleteChat(item));
      setDeleteLoading(false);
      fetchChats();
      showAlert("Chat removed successfully", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      showAlert(message, "danger");
      setDeleteLoading(false);
    }
  };
  const changeChat = async (chat) => {
    dispatch(updateChat(chat));
    // fetchChats();
  };

  const fetchMessages = async (chatID, params) => {
    setLoading(true);
    dispatch(setMessage([]));
    try {
      const response = await api.get(`/chat/messages/${chatID}`, { params });
      dispatch(setMessage(response.data.items));
      //   fetchChats();
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      showAlert(message, "danger");
      setLoading(false);
    }
  };
  const newMessage = async (chatID) => {
    if(messageData.images?.length < 1){
      createMessage({
        sender: {
          _id:localStorage.getItem('_id')
        },
        images:messageData.images,
        status:'Sent',
        text:messageData.text,
        chat:chatID,
        unseen:true,
        forceUpdate:true,
        createdAt:Date.now()
      })
      setMessageData({ text: "", images: [] });
    }else{
      setAddLoading(true);
    }
    try {
      const formdata = getFormData(messageData);
      const response = await api.post(`/chat/messages/new/${chatID}`, formdata);
      setAddLoading(false);
      if(messageData.images?.length > 0){
        setMessageData({ text: "", images: [] });
      }
      updateMessages(chatID, "Seen");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      // Alert('error', message);
      showAlert(message, "danger");
      setAddLoading(false);
    }
  };
  const updateMessages = async (chatID, status) => {
    try {
      const response = await api.put(`/chat/messages/update/${chatID}`, {
        status,
      });
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      //   fetchChats();
      showAlert(message, "danger");
    }
  };
  const searchChat = async (text) => {
    try {
      const response = await api.get(
        `/chat/search/?text=${text}&user=${localStorage.getItem("_id")}`
      );
      dispatch(setChat(response.data.result));
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      fetchChats();
      showAlert(message, "danger");
    }
  };

  const createMessage = (data) => {
    if (data.sender?._id != localStorage.getItem("_id")) {
      setUnseen((prevState) => prevState + 1);
      dispatch(addMessage(data));
    }else if(data.forceUpdate || data.images?.length > 0){
      dispatch(addMessage(data));
    }

    updateMessages(data.chat, "Recieved");
  };
  const changeMessagesStatus = (status) => {
    dispatch(updateMessagesStatus({ status, user }));
    // fetchChats();
  };

  return {
    page,
    limit,
    chats,
    messages,
    loading,
    addLoading,
    deleteLoading,
    data,
    messageData,
    setMessageData,
    setData,
    setPage,
    setLimit,
    fetchChats,
    newChat,
    removeChat,
    changeChat,
    unseen,
    fetchMessages,
    newMessage,
    updateMessages,
    createMessage,
    changeMessagesStatus,
    searchChat,
  };
};
