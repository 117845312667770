import { createSlice } from "@reduxjs/toolkit";

const documentSlice = createSlice({
  name: "document",
  initialState: {
    documents: [],
    folders: [],
    stats: {}
  },
  reducers: {
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setDocument: (state, action) => {
      state.documents = action.payload;
    },
    updateDocument: (state, action) => {
      state.documents = state.documents.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteDocument: (state, action) => {
      state.documents = state.documents.filter(
        (item) => item._id != action.payload._id
      );
    },
    addDocument: (state, action) => {
      state.documents = [...state.documents, action.payload];
    },
    setFolder: (state, action) => {
      state.folders = action.payload;
    },
    updateFolder: (state, action) => {
      state.folders = state.folders.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteFolder: (state, action) => {
      state.folders = state.folders.filter(
        (item) => item._id != action.payload._id
      );
    },
    addFolder: (state, action) => {
      state.folders = [...state.folders, action.payload];
    },
  },
});

export const {
  setStats,
  setDocument,
  updateDocument,
  deleteDocument,
  addDocument,
  setFolder,
  updateFolder,
  deleteFolder,
  addFolder,
} = documentSlice.actions;

export default documentSlice.reducer;
