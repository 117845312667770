import React from 'react'

const InputImages = ({ images, setImages }) => {
    return images?.length > 0
        ?
        <div
            className={`
    bg-light 
    text-white 
    p-3 
    ${images?.length >= 3 ? '' : 'd-flex'}
`}
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(10, 1fr)',
                gap: 20,
                borderStyle: 'solid',
                borderRadius: 20,
            }}
        >
            {
                images?.map((img, index) => (
                    <div className='d-flex flex-column'>
                        <img
                            key={index}
                            src={URL.createObjectURL(img)}
                            style={{
                                objectFit: 'cover',
                                height: 50,
                                width: 50,
                                cursor: 'pointer',
                                borderRadius: 10
                            }}
                        />
                        <div
                            className='align-self-end'
                            style={{
                                position: 'absolute',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setImages(images?.filter((i, idx) => idx != index))
                            }}
                        >
                            <i className="bi bi-trash text-secondary"></i>
                        </div>
                    </div>
                ))
            }
        </div>
        : null
}

export default InputImages