import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadButton from "./downloadButton"
import useTask from "../hooks/useTask";
import useAuth from "../hooks/useAuth";

import Table from "./Table";
import Loader from "./Loader";
import FormField from "./FormField";
import PDF from "../features/tasks/PDF";
import RiskPDF from "../features/tasks/riskAssesmentPdf";
import AuditPDF from "../features/tasks/auditPdf";
import { ddmmyyyy, findRiskRating, getTimePeriod } from "../config/functions";
import api from "../app/api";
import Alert from "../components/Swal Alert/index";
const DownloadPopup = ({
  handleClose,
  task,
  checklist,
  isSubmit,
  branch,
  isRiskAssesment,
}) => {
  console.log(isRiskAssesment)
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdmin, isCompany, isManager, user } = useAuth();
  const [show, setshow] = useState(true);
  const {
    tasks,
    loading,
    setLoading,
    page,
    limit,
    fetchTasks,
    setPage,
    fetchTaskstoDownload,
  } = useTask();
  const [filter, setFilter] = useState({
    range: "",
    from: undefined,
    to: undefined,
  });
  const params = {
    page,
    limit,
    checklist: checklist?._id,
    status: isSubmit ? undefined : "Approved",
    ...filter,
    isRiskAssesment,
  };

  // Records
  const downloadExcel = () => {
    const type =
      "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    fetchTaskstoDownload({ ...params, branch: branch }).then((data) => {
      const send = data.map((item, index) => {
        const questions = {};
        item.questions?.forEach((itemm) => {
          if (itemm.type != 'likelihood') {
            questions[itemm.type != 'severity' ? itemm.question : 'Risk Rating'] = (itemm.type != 'statement' || itemm.type != 'statement/Instruction') ? (itemm.type != 'severity' ? itemm.answer : findRiskRating(item)) : 'Statement';
          }
        });
        return {
          "Sr. No": index + 1,
          Date: new Date(item.timestamp).toLocaleDateString("en-GB"),
          ...(isRiskAssesment == 'riskAssesment'
            ? { "Risk Assesment Title": item.title }
            : { "Checklist Title": item.title }),
          Company: item.companyID?.name,
          Branch: item.userID?.branchID?.name,
          User: item.userID?.name,
          Questions: " ",
          ...questions,
        };
      });

      const ws = XLSX.utils.json_to_sheet(send);
      const wb = {
        Sheets: { "Task Submissions": ws },
        SheetNames: ["Task Submissions"],
      };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const fileData = new Blob([excelBuffer], { type });
      FileSaver.saveAs(fileData, checklist?.title + fileExtension);
    });
  };

  const options = [
    {
      placeholder: "Select Range",
      value: filter.range,
      setValue: (value) => {
        switch (value) {
          case "Custom Range": {
            setFilter({ ...filter, range: value });
            break;
          }
          case "Today":
          case "Yesterday":
          case "This Week":
          case "This Month":
          case "Last Month": {
            setFilter({ range: value, ...getTimePeriod(value) });
            break;
          }
          default: {
            setFilter({ range: "", from: undefined, to: undefined });
          }
        }
      },
      options: [
        "Today",
        "Yesterday",
        "This Week",
        "This Month",
        "Last Month",
        "Custom Range",
      ],
      type: "dropdown",
    },
  ];
  const rangeFields = [
    {
      label: "From",
      value: ddmmyyyy(filter.from),
      setValue: (value) =>
        setFilter({
          ...filter,
          from: new Date(value).getTime(),
        }),
      type: "date",
    },
    {
      label: "To",
      value: ddmmyyyy(filter.to),
      setValue: (value) =>
        setFilter({
          ...filter,
          to: new Date(value).getTime(),
        }),
      type: "date",
    },
  ];

  useEffect(() => {
    setFilter({
      range: isSubmit ? "Today" : "",
      ...(!isSubmit
        ? { from: undefined, to: undefined }
        : getTimePeriod("today")),
    });
  }, [isSubmit]);
  const handleSubmitOrUnAssign = async (item) => {
    try {
      if (
        item.status == "Pending" &&
        item.userID?._id != localStorage.getItem("_id") &&
        (isManager || isCompany)
      ) {
        setLoading(true);
        const response = await api.delete(`/task/delete/${item._id}`);
        handleClose();
        Alert("success", "Task Un-Assigned  Sucessfully.");
        setLoading(false);
      } else {
        navigate(`/task${location.search}`, {
          state: {
            ...item,
            ...(user?.role == "employee" ? {} : { disabled: true }),
          },
        });
      }
    } catch (error) {
      Alert("error", "Error while unassigning the task.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (checklist && filter.range != "Custom Range")
      fetchTasks({ ...params, branch: branch });
    else if (checklist) fetchTasks({ ...params, branch: branch });
  }, [checklist, filter, page]);

  const title = task ? task?.title : checklist?.title;
  const data = tasks?.map((item, index) => {
    const company = item?.companyID?.name;
    const branch = item?.userID?.branchID?.name;
    const status = item.status ? item.status : "Pending";
    const questions = item.questions?.length ? item.questions?.length : "0";
    return {
      data: item,
      fields: [
        isSubmit ? null : `#${item?._id.substr(item?._id.length - 5)}`,
        new Date(item.createdAt).toLocaleDateString("en-GB"),
        questions,
        <span
          className={`${item.status == "Approved" ? "text-success" : "text-warning"
            }`}
        >
          {status}
        </span>,
        ...(checklist
          ? []
          : [
            isAdmin ? <td>{user?.name == company ? "" : company}</td> : null,
            isAdmin || isCompany ? <td>{branch}</td> : null,
          ]),
        isSubmit ? (
          <button
            className={
              item.status == "Approved" ? "btn btn-primary" : "btn btn-success"
            }
            onClick={() => {
              handleSubmitOrUnAssign(item);
            }}
          >
            {item.status == "Approved"
              ? "Update"
              : item.userID?._id != localStorage.getItem("_id") &&
                (isManager || isCompany)
                ? "Un-Assign"
                : "Submit"}
          </button>
        ) : (
          <td className="d-flex justify-content-between w-100">
            <DownloadButton
              PDF={isRiskAssesment == 'riskAssesment' ? <RiskPDF data={item} /> : isRiskAssesment == 'audit' ? <AuditPDF data={item}/> : <PDF data={item} />}
              item={item}
              fileName={item?.title + `.pdf`}
              pdfButton={<div className="text-danger" style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-file-pdf-fill"
                  style={{ fontSize: 20 }}
                ></i>
              </div>}
            />
            {/* <PDFDownloadLink
              document={isRiskAssesment == 'riskAssesment' ? <RiskPDF data={item} /> : <PDF data={item} />}
              fileName={item?.title + `.pdf`}
            >
              {({ blob, url, loading, error }) => {
                return (
                  <div className="text-danger" style={{ cursor: "pointer" }}>
                    <i
                      className="bi bi-file-pdf-fill"
                      style={{ fontSize: 20 }}
                    ></i>
                  </div>
                );
              }}
            </PDFDownloadLink> */}
          </td>
        ),
      ].filter((it) => it),
    };
  });

  const labels = [
    isSubmit ? null : "#",
    "Date",
    "Questions",
    "Status",
    isSubmit ? "Action" : "File",
  ].filter((item) => item);

  return (
    <Modal show={!!task || !!checklist} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {task ? (
          <div className="d-flex w-100 gap-2">
            <DownloadButton
              PDF={isRiskAssesment == 'riskAssesment' ? <RiskPDF data={task} /> : <PDF data={task} />}
              item={task}
              fileName={task?.title + `.pdf`}
              pdfButton={<div className="text-danger" style={{ cursor: "pointer" }}>
                <i
                  className="bi bi-file-pdf-fill"
                  style={{ fontSize: 20 }}
                ></i>
              </div>}
            />
            {/* <PDFDownloadLink
              className="w-100"
              document={isRiskAssesment == 'riskAssesment' ? <RiskPDF data={task} /> : <PDF data={task} />}
              fileName={task?.title + `.pdf`}
            >
              {({ blob, url, loading, error }) => {
                return <button className="btn btn-warning w-100">PDF</button>;
              }}
            </PDFDownloadLink> */}
            <button className="btn btn-success w-100" onClick={downloadExcel}>
              Excel
            </button>
          </div>
        ) : (
          <div className=" d-flex flex-column w-100 gap-3">
            {options.map((item, index) => (
              <FormField key={index} item={item} />
            ))}
            {filter.range != "Custom Range" ? null : (
              <div className="d-flex w-100 gap-2">
                {rangeFields.map((item, index) => (
                  <FormField key={index} item={item} />
                ))}
              </div>
            )}
            {filter.range != "Custom Range" ? null : (
              <button
                className="btn btn-primary"
                onClick={() => fetchTasks(params)}
              >
                Search
              </button>
            )}
            <Loader loading={loading} center>
              <button
                className="btn btn-success"
                onClick={() => downloadExcel()}
              >
                <i className="bi bi-download me-2"></i> Download Excel
              </button>
              <Table
                labels={labels}
                data={data}
                limit={limit}
                page={page}
                setPage={setPage}
              />
              {!isSubmit ? null : (
                <button
                  className="btn btn-primary gap-2"
                  onClick={() => {
                    navigate(`/task${location.search}`, {
                      state: {
                        ...checklist,
                        fresh: true,
                      },
                    });
                  }}
                >
                  <i className="bi bi-clipboard2-plus"></i>
                  {"\t"}
                  Submit Task
                </button>
              )}
            </Loader>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DownloadPopup;
