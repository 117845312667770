import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import appContext from "../app/contextState/context";

const Route = ({ route }) => {
  const location = useLocation();
  const fullPath = location.pathname + location.search;
  const className = `nav-link ${
    (route?.subRoutes ? fullPath.includes(route?.id) : fullPath == route?.to)
      ? ""
      : "collapsed"
  }`;
  const contentName = `nav-content ${
    fullPath.includes(route?.id) ? "" : "collapse"
  }`;
  const appState = useContext(appContext);
  const { unseen, setShowMessageSidebar } = appState;
  return (
    <li className="nav-item">
      {!route?.subRoutes ? (
        <Link
          className={className}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // Add this line to vertically center the content
          }}
          onClick={() => {
            if (route.id == "inbox") {
              setShowMessageSidebar(true);
            }
          }}
          to={route?.to}
        >
          <span>
            {route?.icon}
            <span>{route?.title}</span>
          </span>
          {route.id == "inbox" && (
            <p
              style={{
                padding: "0px 7px",
                borderRadius: "50%",
                backgroundColor: unseen === 0 ? "green" : "red",
                color: "white",
                textAlign: "center",
                margin: "6px 0px",
              }}
            >
              {unseen}
            </p>
          )}
        </Link>
      ) : (
        <>
          <a
            className={className}
            data-bs-target={`#${route.id}`}
            data-bs-toggle="collapse"
            href="#"
          >
            {route?.icon}
            <span>{route?.title}</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id={route.id}
            className={contentName}
            data-bs-parent="#sidebar-nav"
          >
            {route?.subRoutes?.map((sub, index) => {
              const selected = fullPath == sub?.to;
              return (
                <li key={index}>
                  <Link to={sub.to} state={sub.state}>
                    <i
                      className={"bi bi-circle" + (selected ? "-fill" : "")}
                    ></i>
                    <span style={selected ? { color: "#4154f1" } : {}}>
                      {sub?.title}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </li>
  );
};

export default Route;
