import React, { useEffect } from "react";
import useCompanies from "../hooks/useCompanies";
import useAuth from "../hooks/useAuth";
import CategoryFilter from "../features/category/CategoryFilter";

const CompanyFilter = ({ filter, setFilter, showBranch, dontShowCompany }) => {
  const { branches, companies, fetchCompanies, fetchBranches } = useCompanies();
  const { isAdmin, isCompany, isManager, isSupervisor, user, permissions, isGuest } =
    useAuth();
  useEffect(() => {
    if (isAdmin) fetchCompanies();
    if (isCompany) fetchBranches(localStorage.getItem("_id"));
  }, []);
  useEffect(() => {
    if ((isAdmin || isCompany || isManager) && filter?.company)
      fetchBranches(isManager ? user?.companyID?._id : filter?.company);
  }, [filter?.company]);

  const selectCompany = (e) => {
    setFilter({ ...filter, branch: "", company: e.target.value });
  };
  const selectRiskAssesment = (e) => {
    setFilter({ ...filter, isRiskAssesment: e.target.value });
  };
  const selectBranch = (e) => {
    setFilter({ ...filter, branch: e.target.value });
  };
  const selectCategory = (e) => {
    setFilter({ ...filter, category: e.target.value });
  };
  return (
    <div className="d-flex gap-3" style={{ maxWidth: "100%" }}>
      {filter.isRiskAssesment &&
        (isAdmin || isCompany || isManager || isSupervisor) && (
          <select
            className="form-select"
            onChange={selectRiskAssesment}
            value={filter.isRiskAssesment}
            aria-label="Default select example"
          >
            <option value={"checklist"}>Checklists</option>
            {(!isManager &&
              !isCompany &&
              !isAdmin) ||
            isGuest ? null : (
              <option value={"riskAssesment"}>Risk Assessments</option>
            )}
            {(!isManager &&
              !isCompany &&
              !isAdmin) ||
            isGuest ? null : (
              <option value={"audit"}>Audits</option>
            )}
          </select>
        )}
      {!isAdmin ? null : dontShowCompany ? null : (
        <select
          className="form-select"
          onChange={selectCompany}
          aria-label="Default select example"
        >
          <option value="">Select Company</option>
          {companies?.map((item, index) => (
            <option
              key={index}
              value={item._id}
              selected={item._id == filter?.company}
              index={index}
            >
              {item.name}
            </option>
          ))}
        </select>
      )}
      {(filter.company || isCompany) &&
        ((!isAdmin && !isCompany && !isManager) || !showBranch ? null : (
          <select
            className="form-select"
            onChange={selectBranch}
            value={filter.branch}
            aria-label="Default select example"
          >
            <option value="" selected={filter.branch == "" || !filter.branch}>
              Select Site
            </option>
            {branches?.map((item, index) => (
              <option
                key={index}
                value={item._id}
                selected={item._id == filter?.branch}
                index={index}
              >
                {item.name}
              </option>
            ))}
          </select>
        ))}
      <CategoryFilter
        selectCategory={selectCategory}
        category={filter?.category}
      />
    </div>
  );
};

export default CompanyFilter;
