import React from "react";
import Loader from "../../components/Loader";

const Table = ({ labels, data, onAdd, onEdit, onDelete, deleteLoading }) => {
  return (
    <table className="table table-hover mb-0">
      <thead>
        <tr> {labels?.map((item, index) => <th scope='col' key={index}>{item}</th>)} </tr>
      </thead>
      <tbody>
        {
          data?.map((item, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              {item.cells?.map((cell, idx) => <td key={idx}>{cell}</td>)}
              <td className='d-flex'>
                <i className="bi bi-pencil-square text-primary" style={{ cursor: 'pointer' }} onClick={() => onEdit(item.data)} />
                <Loader loading={deleteLoading && deleteLoading?._id == item.data?._id} color='red'>
                  <i onClick={() => onDelete(item.data)} className="bi bi-trash text-danger" style={{ cursor: 'pointer' }}></i>
                </Loader>
              </td>
            </tr>
          ))
        }
      </tbody>
      <tfoot style={{ borderColor: 'transparent' }}>
        <tr>
          <td colSpan="5" className="text-center">
            <button className="btn btn-success mt-3" onClick={onAdd}>Add New</button>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default Table