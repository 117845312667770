import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
    name: "task",
    initialState: {
        tasks: []
    },
    reducers: {
        setTask: (state, action) => {
            state.tasks = action.payload;
        },
        updateTask: (state, action) => {
            state.tasks = state.tasks.map(item => item._id == action.payload._id ? action.payload : item);
        },
        deleteTask: (state, action) => {
            state.tasks = state.tasks.filter(item => item._id != action.payload._id);
        },
        addTask: (state, action) => {
            state.tasks = [...state.tasks, action.payload];
        },
    },
});

export const { setTask, updateTask, deleteTask, addTask } = taskSlice.actions;

export default taskSlice.reducer;
