import React, { useState } from 'react'

import SubmitButton from './SubmitButton';

const EmptyData = ({ data, children, onReload, message }) => {
    return data?.length != 0 ? children : (
        <div className="container">
            <section className="section error-404 d-flex flex-column align-items-center justify-content-center">
                <h2>{message ? message : "No Items Found"}</h2>
                {
                    !onReload ? null :
                        <SubmitButton title={'Refresh'} onClick={onReload} />
                }
            </section>
        </div>
    )
}

export default EmptyData
