import React, { useEffect } from 'react'

import useNotification from '../../hooks/useNotification'
import { getTimeDifferenceString } from '../../config/functions'

import Loader from '../../components/Loader'

const NotificationList = () => {
  const { loading, notifications, fetchNotifications, seenAllNotifications, clearAllNotifications, onClick } = useNotification();

  const unseen = notifications?.filter(item => !item.seen);

  useEffect(() => {
    fetchNotifications()
  }, [])

  return (
    <li className="nav-item dropdown" onClick={loading || unseen?.length == 0 ? undefined : seenAllNotifications} >

      <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
        <i className="bi bi-bell"></i>
        {unseen?.length == 0 ? null : <span className="badge bg-primary badge-number">{unseen?.length}</span>}
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
        <Loader loading={loading} center>
          <li className="dropdown-header">
            <span>
              You have {unseen?.length} new notifications
            </span>
          </li>
          <div style={{ overflow: 'auto', maxHeight: '50vh' }}>
            {
              notifications?.map((item, index) => {
                const icon = (
                  item.type == 'warning' ? "bi bi-exclamation-circle text-warning" :
                    item.type == 'danger' ? "bi bi-x-circle text-danger" :
                      item.type == 'success' ? "bi bi-check-circle text-success" : "bi bi-info-circle text-primary"
                )
                return (
                  <div style={{cursor:'pointer'}} key={index} onClick={() => onClick(item.data ? item.data : item)}>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="notification-item">
                      <i className={icon} />
                      <div>
                        <h4>{item.title}</h4>
                        <p>{item.body}</p>
                        <p>{getTimeDifferenceString(item.createdAt)} ago</p>
                      </div>
                    </li>
                  </div>
                )
              })
            }
          </div>
          {
            notifications?.length == 0 ? null :

              <li className="dropdown-footer" onClick={loading ? null : clearAllNotifications}>
                <a href="#">Clear all notifications</a>
              </li>
          }
        </Loader>
      </ul>

    </li>
  )
}

export default NotificationList