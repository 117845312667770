import React from 'react'

import FormField from '../../components/FormField';
import Loader from '../../components/Loader';

const NewCategory = ({ data, setData, onSubmit, loading }) => {
  const isUpdate = !!data._id;
  const fields = [
    {
      value: data.name,
      setValue: (value) => setData({ ...data, name: value }),
      placeholder: 'Name',
      type: 'text',
    }
  ]
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{isUpdate ? 'Update' : 'New'} Category</h5>
        {
          fields.map((item, index) => (
            <FormField item={item} key={index} />
          ))
        }
        <button className='btn btn-primary w-100 mt-3' onClick={onSubmit}>
          <Loader loading={loading} color={'white'}>
            {isUpdate ? 'Update' : 'Add'}
          </Loader>
        </button>
      </div>
    </div>
  )
}

export default NewCategory