import React from 'react'
import { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { RiskAssesmentContext } from '../../hooks/riskAssesmentContext';
import { keys } from '../../config/keys';


const DraggableEducationBox = ({ data, index, lastIndexData, editIndex, question }) => {
    const { setData } = useContext(RiskAssesmentContext)
    const [{ isDragging }, drag] = useDrag({
        type: 'Questions',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'Questions',
        hover: (item) => {
            if (item.index !== index) {
                moveQuestion(item.index, index);
                item.index = index;
            }
        },
    });
    const grabStyle = {
        cursor: 'grab',
        // Add any other grabbing styles you want
    };

    const grabbingStyle = {
        cursor: 'grab',
        // Add any other grabbing styles you want
    };
    const moveQuestion = (fromIndex, toIndex) => {
        setData(prevState => {
            let newState = { ...prevState };
            const newQuestions = [...newState.questions]
            // newQuestions.splice(fromIndex, 0)
            const [movedItem] = newQuestions.splice(fromIndex, 1);
            if (movedItem.type == 'severity') {
                let findLikelihoodIndex = newQuestions.findIndex(item => item.type == 'likelihood')
                let findLikelihood = newQuestions[findLikelihoodIndex]
                newQuestions.splice(findLikelihoodIndex, 1);
                newQuestions.splice(toIndex, 0, movedItem);
                newQuestions.splice(toIndex + 1, 0, findLikelihood);
            } else {
                newQuestions.splice(toIndex, 0, movedItem);
            }
            newState.questions = newQuestions;
            return newState
        })
    };
    const boxStyle = isDragging ? grabbingStyle : grabStyle;
    return (
        <>
            {
                (editIndex != index) ? (
                    data.data?.type != 'likelihood' && <tr ref={(node) => drag(drop(node))} style={{ marginBottom: '10px', ...boxStyle }}>
                        {
                            data.fields?.map((field, fdx) => {
                                return <td key={fdx}>{field == 'Severity' ? 'Risk Rating' : field}</td>
                            }
                            )
                        }
                    </tr>
                ) : (<tr key={index}>
                    {lastIndexData.fields.map((field, fdx) => {
                        return <td key={fdx}>{field}</td>;
                    })}
                </tr>)
            }
            {/* <tr ref={(node) => drag(drop(node))} style={{ marginBottom: '10px', ...boxStyle }}>
                {
                    data.fields?.map((field, fdx) => {
                        return <td key={fdx}>{field}</td>
                    }
                    )
                }
            </tr> */}
        </>
    );
};

export default DraggableEducationBox;