import { createSlice } from "@reduxjs/toolkit";

const documentSlice = createSlice({
  name: "policy",
  initialState: {
    policies: [],
  },
  reducers: {
    setPolicy: (state, action) => {
      state.policies = action.payload;
    },
    updatePolicy: (state, action) => {
      state.policies = state.policies.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deletePolicy: (state, action) => {
      state.policies = state.policies.filter(
        (item) => item._id != action.payload._id
      );
    },
    addPolicy: (state, action) => {
      state.policies = [...state.policies, action.payload];
    }
  },
});

export const {
  setPolicy,
  updatePolicy,
  deletePolicy,
  addPolicy,
} = documentSlice.actions;

export default documentSlice.reducer;
