import React, { memo, useContext } from "react";
import Pagination from "./Pagination";
import EmptyData from "./EmptyData";
import ChecklistQuestionDrag from "./dragAndDrop";
import RiskAssesmentQuestionDrag from "./riskAssesmentDnD";
import FormField from "./FormField";
import { Box, Button } from "@mui/material";
import { PoliciesContext } from "../hooks/policiesContext";
import SubmitButton from "./SubmitButton";
import AuditDnd from "./auditDnd/index"
const Table = ({
  title,
  labels,
  data,
  limit,
  page,
  setPage,
  className,
  options,
  setOption,
  option,
  heightControl,
  editIndex,
  setLimit,
  filter,
  setfilter,
  totalData,
  removeAllDocument,
  question
}) => {
  const { selectedForShare } = useContext(PoliciesContext)
  return (
    <div
      className={`card ${className}`}
      style={{
        zIndex: "0",
        height: heightControl && "300px",
        overflow: heightControl && "auto",
      }}
    >

      {/* Filter */}
      {!options ? null : (
        <div className="filter">
          <a className="icon" href="#" data-bs-toggle="dropdown">
            <i className="bi bi-three-dots"></i>
          </a>
          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <li className="dropdown-header text-start">
              {" "}
              <h6>Filter</h6>{" "}
            </li>
            {options.map((item, index) => (
              <li key={index}>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => setOption(item)}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Body */}
      <div className="card-body">
        <h5 className="card-title">
          {title} {options ? <span>| {option}</span> : ""}
        </h5>
        <div className="table-container">
          {removeAllDocument && <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}>
            <SubmitButton
              title={"Delete"}
              onClick={() => removeAllDocument()}
              color={"danger"}
              loaderColor={"white"}
              disabled={selectedForShare.length < 1}
            />
          </Box>}
          {(filter && (filter.search || filter.search == '')) && <FormField item={{
            // label: "Location",
            value: filter.search,
            setValue: (value) => setfilter({ ...filter, "search": value }),
            type: "text",
            id: "search",
            placeholder: "Search ..."
          }} />}
          <table className="table table-hover">
            <EmptyData data={data}>
              <thead>
                <tr>
                  {labels.map((item, index) => (
                    <th key={index} scope="col">
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {title == "Checklist Questions"
                  ? data?.map((item, index) => (
                    <ChecklistQuestionDrag
                      data={item}
                      index={index}
                      question={data}
                      editIndex={editIndex}
                      lastIndexData={data[data.length - 1]}
                    />
                  ))
                  : title == "Risk Assessment Questions" ? data?.map((item, index) => {
                    if (item.noDrag) {
                      return <tr key={index}>
                        {item.fields.map((field, fdx) => {
                          return <td key={fdx}>{field}</td>;
                        })}
                      </tr>
                    } else {
                      return <RiskAssesmentQuestionDrag
                        data={item}
                        index={index}
                        question={data}
                        editIndex={editIndex}
                        lastIndexData={data[data.length - 1]}
                      />
                    }
                  }) : title == "Audit Questions" ? data?.map((item, index) => {
                    if (item.noDrag) {
                      return <tr key={index}>
                          {item.fields.map((field, fdx) => {
                            return <td key={fdx}>{field}</td>;
                          })}
                        </tr>
                     
                    } else {
                      return <AuditDnd
                        data={item}
                        index={index}
                      />
                    }
                  }) : data?.map((item, index) => (
                    <tr key={index}>
                      {item.fields.map((field, fdx) => {
                        return <td key={fdx}>{field}</td>;
                      })}
                    </tr>
                  ))}
              </tbody>
            </EmptyData>
          </table>
        </div>
        {page == undefined || data?.length == 0 ? null : (
          <Pagination page={page} data={data} limit={limit} setPage={setPage} setLimit={setLimit} totalData={totalData} />
        )}
      </div>
    </div>
  );
};

export default memo(Table);
