import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"

import api from "../app/api";
import useAlert from "./useAlert";
import Alert, { DeleteAlert, UpdateAlert } from '../components/Swal Alert/index';

import { setCategory, addCategory, updateCategory, deleteCategory } from '../features/category/slice';

export default () => {
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.category)

  const { showAlert } = useAlert()

  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  // New Category State
  const [data, setData] = useState({
    name: '',
  })
  const fetchCategories = async (params) => {
    setLoading(true);
    try {
      const response = await api.get(`/category`, { params });
      dispatch(setCategory(response.data))
      setLoading(false);
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setLoading(false);
    }
  }
  const newCategory = async () => {
    setAddLoading(true);
    try {
      const response = await api.post('/category/new', data);
      dispatch(addCategory(response.data))
      setAddLoading(false);
      setData({ name: '' })
      showAlert('New Category added ', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      Alert('error', message);
      // showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const changeCategory = async () => {
    if (!await UpdateAlert()) return
    setAddLoading(true);
    try {
      const response = await api.put(`/category/update/${data._id}`, data);
      dispatch(updateCategory(data))
      setData({ name: '' })
      setAddLoading(false);
      showAlert('Category updated successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const removeCategory = async (item) => {
    if (!await DeleteAlert()) return
    setDeleteLoading(item);
    try {
      const response = await api.delete(`/category/delete/${item ? item._id : data._id}`);
      dispatch(deleteCategory(item))
      setDeleteLoading(false);
      showAlert('Category removed successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setDeleteLoading(false);
    }
  }

  return {
    categories,
    loading,
    addLoading,
    deleteLoading,
    data,
    setData,
    fetchCategories,
    newCategory,
    changeCategory,
    removeCategory,
  }
}