import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    user: {},
    permissions: []
  },
  reducers: {
    setPermission: (state, action) => {
      state.permissions = action.payload;
    },
    login: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.permissions = action.payload.permissions;
      localStorage.setItem("token", action.payload.token);
    },
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.clear()
      state.token = undefined;
      state.user = undefined;
      state.permissions = undefined;
    },
    user: (state, action) => {
      state.user = action.payload
    }
  },
});

export const { login, logout, user, setPermission } = authSlice.actions;

export default authSlice.reducer;
