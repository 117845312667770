import { createSlice } from "@reduxjs/toolkit";

const auditSlice = createSlice({
    name: "audit",
    initialState: {
        auditCollection: []
    },
    reducers: {
        setAudit: (state, action) => {
            state.auditCollection = action.payload;
        },
        updateAudit: (state, action) => {
            state.auditCollection = state.auditCollection.map(item => item._id == action.payload._id ? action.payload : item);
        },
        deleteAudit: (state, action) => {
            state.auditCollection = state.auditCollection.filter(item => item._id != action.payload._id);
        },
        addAudit: (state, action) => {
            state.auditCollection = [...state.auditCollection, action.payload];
        },
    },
});

export const { setAudit, updateAudit, deleteAudit, addAudit } = auditSlice.actions;

export default auditSlice.reducer;

