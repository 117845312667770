import { useState } from "react";
import { setSubscriptions } from "../features/subscription/slice";
import { useDispatch, useSelector } from "react-redux";
import api from "../app/api";
import useAuth from "./useAuth";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import useAlert from "./useAlert";
export default () => {
  const { isCompany, isCompanyAdmin, isAdmin, onUser, } = useAuth()
  const { paymentMethods } = useSelector(state => state.paymentMethods)
  const { showAlert } = useAlert();
  const [filter, setfilter] = useState({ company: "" });
  const [loading, setloading] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showPaymentModel, setShowPaymentModel] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const getData = async (isPublic, _id) => {
    setloading(true);
    try {
      console.log(_id && isCompanyAdmin)
      let response;
      if ((_id && !isAdmin) || (_id && isCompanyAdmin)) {

        response = await api.post(
          `/dynamic-subscription/getByAdminOrCompany`,
          {
            companyID: isCompany ? localStorage.getItem('_id') : '',
            adminID: isCompanyAdmin ? localStorage.getItem('_id') : '',
            _id: _id
          }
        );
      } else {
        response = await api.get(
          `/subscription/get${isPublic ? "?isPublic=true" : ""}`
        );

      }
      console.log(response)
      if (response.data?.status) {
        dispatch(setSubscriptions(response.data?.result));
      }
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  const updateSubscription = async (user, subscription, isPaidByUser) => {
    setloading(true);
    try {
      const response = await api.post(`/subscription/changeSubscription`, {
        user,
        subscription: subscription ? subscription : selectedSubscription._id,
        isPaidByUser: isPaidByUser
      });
      onUser().then(() => setloading(false));
      if (response.data?.status) {
      }
    } catch (error) {
    } finally {
    }
  };

  const handleSubmit = async (event, user, _id, item) => {
    event.preventDefault();
    try {
      let customer = user
      let exsistingPaymentMethod = paymentMethods.filter(item => item.isDefault)
      setStripeLoading(true);
      if (paymentMethods.length < 1) {
        console.log("in if")
        if (!stripe || !elements) return;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

        if (error) {
          setError(error.message);
          return
        }
        // Send paymentMethod.id to your server

        const response = await api.post("/stripe/create-customer", {
          email: user.email,
          paymentMethodId: paymentMethod.id ? paymentMethod.id : exsistingPaymentMethod[0].id,
          userID: user._id,
        });
        if (!response.data?.status) {
          setError(response.data?.message);
          return;
        }
        customer = response.data?.result;
      }
      // Now create a subscription
      const subscriptionResponse = await api.post(
        "/stripe/create-subscription",
        {
          stripeCustomerId: customer.stripeCustomerId,
          priceId: item ? item.priceID : selectedSubscription.priceID, // Replace with your price ID
          _id: _id,
          adminID: user.adminID
        }
      );
      if (!subscriptionResponse.data?.status) {
        setError(subscriptionResponse.data?.message);
        showAlert(subscriptionResponse.data?.message,"danger")
        return;
      }
      const subscription = subscriptionResponse.data?.subscription;
      if (subscriptionResponse.data?.status) {
        const paymentIntent = subscription?.latest_invoice?.payment_intent;
        if (paymentIntent && paymentIntent.status === "requires_action") {
          // Handle 3D Secure authentication
          const {
            error: confirmError,
            paymentIntent: confirmedPaymentIntent,
          } = await stripe.confirmCardPayment(paymentIntent.client_secret);

          if (confirmError) {
            setError(confirmError.message);
            showAlert(confirmError.message,"danger")
            console.error("3D Secure Error:", confirmError);
            return;
          }

          if (confirmedPaymentIntent.status === "succeeded") {
            showAlert("Payment succeeded!", "success");
            setError(null);
            setShowPaymentModel(false);
            updateSubscription(user, item._id ? item._id : selectedSubscription._id, true);
          } else {
            setError("Payment failed.");
            showAlert("Payment failed.","danger")
          }
        } else if (subscription.status == "past_due") {
          const subscriptionResponse = await api.post(
            "/stripe/cancel-subscription",
            {
              subscriptionId: subscription.id,
            }
          );
          setError("Insufficient funds.");
          showAlert("Insufficient funds.","danger")
        } else if (
          subscription?.status !== "incomplete" &&
          subscription?.status !== "incomplete_expired"
        ) {
          // No further action required
          showAlert("Payment succeeded!", "success");
          setError(null);
          setShowPaymentModel(false);
          updateSubscription(user, item._id ? item._id : selectedSubscription._id, true);
        } else {
          setError("Insufficient funds.");
          showAlert("Insufficient funds.","danger")
        }
      } else {
        setError(subscriptionResponse.data?.message);
        showAlert(subscriptionResponse.data?.message,"danger")
      }

    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setStripeLoading(false);
    }
  };

  return {
    getData,
    updateSubscription,
    filter,
    setfilter,
    loading,
    setloading,
    error,
    setError,
    stripeLoading,
    showPaymentModel,
    setShowPaymentModel,
    selectedSubscription,
    setSelectedSubscription,
    handleSubmit,
  };
};
