import React, { useContext } from "react";
import { useSelector } from "react-redux";

import useAuth from "../hooks/useAuth";

import { useEffect } from "react";
import appContext from "../app/contextState/context";
import { frequency } from "../config/constants";
import useChat from "../hooks/useChat";
import Route from "./Route";

const Sidebar = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    permissions,
    onLogout,
    isAdmin,
    isCompany,
    isGuest,
    isManager,
    isSupervisor,
    isCompanyAdmin,
    isFellowAdmin
  } = useAuth();
  const { fetchChats } = useChat();
  const appState = useContext(appContext);
  const { unseen } = appState;
  // ----------------------NAVIGATION----------------------
  const baseRoutes = [
    {
      title: "Dashboard",
      to: "/",
      icon: <i className="bi bi-grid"></i>,
    },
    isGuest
      ? null
      : {
        title: "Training",
        to: "/training",
        icon: <i className="bi bi-person-workspace"></i>,
      },
      ((!isManager) &&
      (!isCompany) &&
      !isAdmin) ||
      isGuest
      ? null
      : {
        title: "Checklists",
        icon: <i className="bi bi-card-checklist"></i>,
        id: "checklist",
        subRoutes: [
          {
            title: "Checklist Collection",
            to: "/checklists",
          },
          isAdmin && {
            title: "Reviews",
            to: "/checklists?review=true",
          },
          (isCompany || isAdmin || isManager) && {
            title: "Add New Checklist",
            to: "/new-checklist",
          },
        ].filter((item) => item),
      },
    ((!isManager) &&
      (!isCompany) &&
      !isAdmin) ||
      isGuest
      ? null
      : {
        title: "Risk Assessments",
        icon: <i className="bi bi-card-checklist"></i>,
        id: "riskAssesment",
        subRoutes: [
          {
            title: "RA Collection",
            to: "/risk-assesments",
          },
          isAdmin && {
            title: "Reviews",
            to: "/risk-assesments?review=true",
          },
          (isCompany || isAdmin || isManager) && {
            title: "Add New RA",
            to: "/new-risk-assesment",
          },
        ].filter((item) => item),
      },
    ((!isManager) &&
      (!isCompany) &&
      !isAdmin) ||
      isGuest
      ? null
      : {
        title: "Audits",
        icon: <i className="bi bi-card-checklist"></i>,
        id: "audit",
        subRoutes: [
          {
            title: "Audit Collection",
            to: "/audits",
          },
          isAdmin && {
            title: "Reviews",
            to: "/audits?review=true",
          },
          (isCompany || isAdmin || isManager) && {
            title: "Add New Audit",
            to: "/new-audit",
          },
        ].filter((item) => item),
      },
    (!isManager) &&
      (!isCompany) &&
      !isAdmin
      ? null
      : (isCompany || isAdmin || isManager) && {
        title: "Policies",
        icon: <i className="bi bi-card-checklist"></i>,
        id: "policy",
        subRoutes: [
          {
            title: "All Policies",
            to: "/policy",
          },
          (isCompany || isAdmin) && {
            title: "Add New Policy",
            to: "/policies-options",
          },
        ].filter((item) => item),
      },
  ].filter((item) => item);

  const companyRoutes = [
    {
      title: "Records",
      to: "/records",
      id: "record",
      icon: <i className="bi bi-clipboard-data"></i>,
    },
    ...(isGuest
      ? []
      : [
        {
          title: "Tasks",
          icon: <i className="bi bi-card-checklist"></i>,
          id: "task",
          subRoutes: ["All", ...frequency, "None"].map((item) => ({
            title: item,
            to: `/tasks?frequency=${item}`,
          })),
        },
      ]),
    ...(isSupervisor ||
      isGuest
      ? []
      : [
        {
          title: "Document Library",
          to: "/documents",
          id: "document",
          icon: <i className="bi bi-files"></i>,
        },
      ]),

    ...(isSupervisor || isGuest || isManager
      ? []
      : [
        {
          title: "Public Library",
          to: "/documents?public=true",
          id: "public",
          icon: <i className="bi bi-globe"></i>,
        },
      ]),
    ...(isSupervisor || isGuest || isManager
      ? [] :
      [

        {
          title: "Management System",
          to: "/management-system",
          id: "public",
          icon: <i className="bi bi-file-earmark"></i>,
        },
      ]),
  ].filter((item) =>
    isAdmin
      ? true
      : item.id == "record" && isGuest
        ? true
        : true
  );

  const adminRoutes = [
    {
      title: "Admin",
      icon: <i className="bi bi-person"></i>,
      id: "admin",
      subRoutes: isAdmin
        ? [
          {
            title: "Registered Companies",
            to: "/companies?admin=true",
          },
          {
            title: "Categories",
            to: "/categories?admin=true",
          },
          {
            title: "Industries",
            to: "/natures?admin=true",
          },
          (!isCompanyAdmin && !isFellowAdmin) && {
            title: "Super Admins",
            to: "/admins?admin=true",
          },
          (!isCompanyAdmin && !isFellowAdmin)&&{
            title: "Admins",
            to: "/companyAdmins?admin=true",
          },
          (!isFellowAdmin)&&{
            title: "Fellow admins",
            to: "/fellowAdmins?admin=true",
          },
          {
            title: "Emails",
            to: "/email",
          },
          {
            title: "Email Confirmations",
            to: "/sentMails",
          },
          {
            title: "Sent Emails",
            to: "/sentMails?status=pending",
          },
        ].filter(it=>it)
        : isCompany
          ? [
            {
              title: "Sites",
              to: "/branches?admin=true",
            },
            {
              title: "Emails",
              to: "/email",
            },
            {
              title: "Email Confirmations",
              to: "/sentMails",
            },
            {
              title: "Sent Emails",
              to: "/sentMails?status=pending",
            },
            {
              title: "Subscription",
              to: "/subscription",
            },
          ]
          : isManager
            ? [
              {
                title: "Emails",
                to: "/email",
              },
              {
                title: "Email Confirmations",
                to: "/sentMails",
              },
              {
                title: "Sent Emails",
                to: "/sentMails?status=pending",
              },
            ]
            : null,
    },
  ];

  const communicationRoutes = [
    !isAdmin
      ? null
      : {
        title: "Broadcast",
        to: "/broadcast",
        id: "broadcast",
        icon: <i className="bi bi-broadcast-pin"></i>,
      },
    {
      title: `Inbox`,
      to: "/inbox",
      id: "inbox",
      unseen: unseen,
      icon: <i className="bi bi-inbox-fill"></i>,
    },
  ].filter((item) => item);
  useEffect(() => {
    if (isAdmin || isCompany) {
      fetchChats();
    }
  }, []);

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {baseRoutes?.map((route, index) => (
          <Route route={route} key={index} />
        ))}

        <li className="nav-heading">Data</li>

        {companyRoutes?.map((route, index) => (
          <Route route={route} key={index} />
        ))}
        {!isAdmin && !isCompany && !isManager
          ? null
          : adminRoutes?.map((route, index) => (
            <Route route={route} key={index} />
          ))}

        {!isAdmin && !isCompany && !isManager ? null : (
          <>
            <li className="nav-heading">Communication</li>

            {communicationRoutes?.map((route, index) => (
              <Route route={route} key={index} />
            ))}
          </>
        )}

        {/* Logout Button */}
        <li className="nav-heading">
          {user?.role == "admin" ? "Login as Company" : "Login as Admin"}
        </li>
        <li className="nav-item">
          <button
            className="nav-link collapsed border-0 w-100"
            onClick={onLogout}
          >
            <i className="bi bi-box-arrow-in-left"></i>
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
