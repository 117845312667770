import { createSlice } from "@reduxjs/toolkit";

const broadcastSlice = createSlice({
  name: "broadcast",
  initialState: {
    broadcasts: []
  },
  reducers: {
    setBroadcast: (state, action) => {
      state.broadcasts = action.payload;
    },
    updateBroadcast: (state, action) => {
      state.broadcasts = state.broadcasts.map(item => item._id == action.payload._id ? action.payload : item);
    },
    deleteBroadcast: (state, action) => {
      state.broadcasts = state.broadcasts.filter(item => item._id != action.payload._id);
      state.broadcasts = state.broadcasts.map(item => item.name == 'Default' ? { ...item, checklists: action.payload.checklists + item.checklists } : item);
    },
    addBroadcast: (state, action) => {
      state.broadcasts = [...state.broadcasts, { ...action.payload, checklists: 0 }];
    },
  },
});

export const { setBroadcast, updateBroadcast, deleteBroadcast, addBroadcast } = broadcastSlice.actions;

export default broadcastSlice.reducer;
