import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"

import api from "../app/api";
import useAlert from "./useAlert";
import Alert, { DeleteAlert, UpdateAlert } from '../components/Swal Alert/index';

import { setNature, addNature, updateNature, deleteNature } from '../features/nature/slice';

export default () => {
  const dispatch = useDispatch();
  const { natures } = useSelector(state => state.nature)

  const { showAlert } = useAlert()

  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  // New Nature State
  const [data, setData] = useState({
    name: '',
  })
  const fetchNatures = async (params) => {
    setLoading(true);
    try {
      const response = await api.get(`/nature`, { params });
      dispatch(setNature(response.data))
      setLoading(false);
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      showAlert(message, 'danger');
      setLoading(false);
    }
  }
  const newNature = async () => {
    setAddLoading(true);

    try {
      const response = await api.post('/nature/new', data);
      dispatch(addNature(response.data))
      setAddLoading(false);
      setData({ name: '' })
      showAlert('New Industry added ', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      Alert('error', message);
      // showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const changeNature = async () => {
    if (!await UpdateAlert()) return
    setAddLoading(true);
    try {
      const response = await api.put(`/nature/update/${data._id}`, data);
      dispatch(updateNature(data))
      setData({ name: '' })
      setAddLoading(false);
      showAlert('Industry updated successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const removeNature = async (item) => {
    if (!await DeleteAlert()) return
    setDeleteLoading(item);
    try {
      const response = await api.delete(`/nature/delete/${item ? item._id : data._id}`);
      dispatch(deleteNature(item))
      setDeleteLoading(false);
      showAlert('Industry removed successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setDeleteLoading(false);
    }
  }

  return {
    natures,
    loading,
    addLoading,
    deleteLoading,
    data,
    setData,
    fetchNatures,
    newNature,
    changeNature,
    removeNature,
  }
}