import React from "react";
import FormField1 from "../../components/FormField";
import Loader from "../../components/Loader";
import SubmitButton from "../../components/SubmitButton";
export default function Default({ data, setData, onAddSubscription, companyAdmins, FormField, loading, selection,state ,edit,onUpdateAdminSubscription}) {
    let fields = [
        {
            value: data?.name,
            setValue: (value) => setData({ ...data, name: value }),
            label: "Enter subscription name",
            placeholder: "Enter subscription name",
            type: "text",
            id: "name",
            required: true,
        },
        {
            value: data?.cost,
            setValue: (value) => setData({ ...data, cost: value }),
            label: "Enter cost",
            placeholder: "Enter cost",
            type: "number",
            required: true,
            disabled: edit ? true:false,
            id: "cost",
        },
        {
            value: data?.validity,
            setValue: (value) => setData({ ...data, validity: value }),
            label: "Select validity",
            placeholder: "Select validity",
            required: true,
            disabled: edit ? true:false,
            options: [
                {
                    name: "Monthly",
                    value: "monthly",
                },
                {
                    name: "Yearly",
                    value: "yearly",
                },
            ],
            type: "dropdown",
            id: "validity",
        },
        {
            value: data?.adminID,
            setValue: (value) => setData({ ...data, adminID: value, companyID: '' }),
            label: 'Select admin',
            options: companyAdmins,
            disabled: edit ? true:false,
            placeholder: 'Select admin',
            type: 'dropdown',
            id: 'adminID',
            required: true,
        },
    ];
    return (
        <>
            {selection == "admin" && <>
                <div className="row gap-3 card py-4">
                    <div className="card-body">
                        {fields.map((item) => (
                            <div className="col-12">
                                <FormField field={item} />
                            </div>
                        ))}
                        {data.additionalFeatures?.map((item, index) => (
                            <div className='row mt-2'>
                                <div className='col-6'>
                                    <FormField1
                                        item={{
                                            value: item?.feature,
                                            setValue: (value) => setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures[index].feature = value
                                                return newState
                                            }),
                                            placeholder: 'Enter feature name',
                                            type: 'text',
                                            required: true,
                                            id: `feature-${index}`,
                                            className: ""
                                        }}
                                    />
                                </div>
                                <div className='col-5'>
                                    <FormField1
                                        item={{
                                            value: item?.allocation,
                                            setValue: (value) => setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures[index].allocation = value
                                                return newState
                                            }),
                                            placeholder: 'Enter allocation',
                                            type: 'text',
                                            required: true,
                                            id: `allocation-${index}`,
                                            className: ""
                                        }}
                                    />
                                </div>
                                <div className='col-1'>
                                    <SubmitButton
                                        icon={<i className="bi bi-trash3"></i>}
                                        loading={loading == "delete"}
                                        onClick={() => {
                                            setData(prevState => {
                                                let newState = { ...prevState }
                                                newState.additionalFeatures.splice(index, 1)
                                                return newState
                                            })
                                        }}
                                        color={"danger"}
                                        loaderColor={"white"}
                                    // disabled={disabled}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className='col-12'>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-12'>
                                <button
                                    className='btn btn-sm rounded-5 btn-outline-primary w-100 mt-2'
                                    onClick={() => {
                                        setData(prevState => {
                                            let newState = { ...prevState }
                                            newState.additionalFeatures.push({
                                                feature: '',
                                                allocation: ""
                                            })
                                            return newState
                                        })
                                    }}
                                >
                                    <Loader loading={loading} center color={'white'}>
                                        Add More +
                                    </Loader>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <SubmitButton
                            title={state?._id ? "Update subscription" : "Save subscription"}
                            loading={loading == "new" || loading == "update"}
                            onClick={() => {
                                if(state?._id){
                                    onUpdateAdminSubscription()
                                }else{

                                    onAddSubscription();
                                }
                            }}
                            color={"success"}
                            loaderColor={"white"}
                            disabled={
                                data.name == "" ||
                                data.cost == "" ||
                                data.validity == "" ||
                                data.adminID == "" ||
                                data.additionalFeatures?.length < 1
                            }
                        />
                    </div>
                </div>
            </>}
        </>
    )
}
